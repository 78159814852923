export const QUERY_FLAG = {
  ACADEMY: {
    CAN_RESERVATION: {
      key: 'canReservation',
      value: true,
    },
    IS_FROM_TEAM_EVENT: {
      key: 'isFromTeamEvent',
      value: false,
    },
    FROM_HANMI_EVENT: {
      key: 'fromHanmiEvent',
      value: false,
    },
    FROM_CACAO_EVENT: {
      key: 'fromCacaoEvent',
      value: false,
    },
    FROM_PRIVATE_EVENT: {
      key: 'fromPrivateEvent',
      value: false,
    },
    FROM_ONSITE_EVENT: {
      key: 'fromOnsiteEvent',
      value: false,
    },
  },
  TOGETHER_EVENT: {
    IS_HOME_BACK: {
      key: 'isHomeBack',
      value: false,
    },
  },

  HANMI_EVENT: {
    KIND_OF_SCHOOL: {
      key: 'kindOfSchool',
      value: '',
    },
  },
  ONSITE_EVENT: {
    ACADEMY_ID: {
      key: 'academyId',
      value: '',
    },
  },
};
