/**
 * 수원 - 원천
 * 거성 - 서울신진과 같이
 * 너무 가까워서 클레임이 들어올 가능성이 있는 곳들에 대해 정리해두었습니다.
 */

export const claimableAcademies = [
  // 수원 - 원천 대응
  // {
  //   academies: ['ndbdRgTrbGsZ9Anphtbp', 'FPM9ycTzLmFTSDJg5ull'],
  //   lat: 37.2679824,
  //   lng: 127.0646481,
  //   zoom: 18,
  // },
  {
    academies: ['P4ZOTgk0Uc00Z7Drx1iw', 'DHHozmbXoWCxdNlQhMva'],
    lat: 37.51764985,
    lng: 126.9067785,
    zoom: 16,
  },
  {
    academies: ['Z5aSN1AQ0j8HKCR5MXK7', 'Ft5v2mjiGUSc0JOBuJL6'],
    lat: 37.55590125,
    lng: 126.924974,
    zoom: 16,
  },
  {
    academies: ['yozT7fTIZU05c5v32c0C', 'y4nrgyszqIIusCwZ7tK4'],
    lat: 35.2308103,
    lng: 129.0855635,
    zoom: 16,
  },
];
