import { useCallback, useState } from 'react';

import { isScriptReadyAtom, isWebviewReadyAtom } from '@jotaiStore';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { DEFAULT_LOCATION } from '@constants';
import { isAppApproaching } from '@utils/Common';

import { WEB_QUERY_KEY } from './../../constants/queryKey';
import { getBrowserLocation, getBrowserLocationWhenHasPermission } from './utils';

type WithoutRequesting = {
  myLocation: LocationType | null;
  locationPermission: LocationPermission;
};

type WhileRequesting = {
  myLocation: LocationType;
  locationPermission: Exclude<LocationPermission, 'prompt'>;
};

type GetLocationResult<IsSilent extends boolean> = IsSilent extends true
  ? WithoutRequesting
  : WhileRequesting;

export const useGetLocation = <IsSilent extends boolean = false>({
  isSilent = false as IsSilent,
} = {}) => {
  const isWebviewReady = useAtomValue(isWebviewReadyAtom);
  const isScriptReady = useAtomValue(isScriptReadyAtom);

  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [WEB_QUERY_KEY.MY_LOCATION, isSilent],
    queryFn: async ({ queryKey }) => {
      const [, isSilent] = queryKey;

      if (isSilent) {
        return getLocationWithoutRequestingPermission();
      }

      return getLocationWhileRequestingPermission();
    },
    enabled: isScriptReady && isWebviewReady,
  });
  const [isFunctionLoading, setIsFunctionLoading] = useState(false);

  const isAppApproach = isAppApproaching();

  /**
   * isSilent가 true라면 권한을 요청하지 않음.
   * location은 null일 수 있고, locationPermission이 prompted 일 수 있다. (이전에 권한을 허용한 경우에만 가져옴.)
   */
  const getLocationWithoutRequestingPermission =
    useCallback(async (): Promise<WithoutRequesting> => {
      let resultMyLocation: LocationType | null = null;
      let resultLocationPermission: LocationPermission = '';

      if (isAppApproach) {
        const { latitude, longitude, isLocationDenied, isNotDetermined } =
          (await window.flutter_inappwebview.callHandler('SendLocationSilently')) as {
            latitude?: number;
            longitude?: number;
            isLocationDenied?: boolean;
            isNotDetermined?: boolean;
          };

        resultMyLocation = {
          latitude: latitude ?? DEFAULT_LOCATION.latitude,
          longitude: longitude ?? DEFAULT_LOCATION.longitude,
        };
        resultLocationPermission = isLocationDenied
          ? 'denied'
          : isNotDetermined
          ? 'prompt'
          : 'granted';
      } else {
        const { location, locationPermission } = await getBrowserLocationWhenHasPermission();
        (resultMyLocation = location), (resultLocationPermission = locationPermission);
      }

      queryClient
        .getQueryCache()
        .findAll({ queryKey: [WEB_QUERY_KEY.MY_LOCATION] })
        .forEach(({ queryKey }) => {
          queryClient.setQueryData(queryKey, {
            myLocation: resultMyLocation,
            locationPermission: resultLocationPermission,
          });
        });

      return {
        myLocation: resultMyLocation,
        locationPermission: resultLocationPermission,
      };
    }, [isAppApproach]);

  /**
   * isSilent가 false라면 권한을 요청하게 되며, locationPermission이 prompted 일 수 없다.
   * location은 null일 수 없고 (기본 위치라도 무조건 가져옴), locationPermission은 granted나 denied이다.
   */
  const getLocationWhileRequestingPermission = useCallback(async (): Promise<WhileRequesting> => {
    let resultMyLocation: LocationType | null = null;
    let resultLocationPermission: LocationPermission = '';
    if (isAppApproach) {
      const { latitude, longitude, isLocationDenied } =
        (await window.flutter_inappwebview.callHandler('SendLocation')) as {
          latitude?: number;
          longitude?: number;
          isLocationDenied?: boolean;
        };

      resultMyLocation = {
        latitude: latitude ?? DEFAULT_LOCATION.latitude,
        longitude: longitude ?? DEFAULT_LOCATION.longitude,
      };
      resultLocationPermission = isLocationDenied ? 'denied' : 'granted';
    } else {
      const { location, locationPermission } = await getBrowserLocation();

      resultMyLocation = location;
      resultLocationPermission = locationPermission;
    }

    queryClient
      .getQueryCache()
      .findAll({ queryKey: [WEB_QUERY_KEY.MY_LOCATION] })
      .forEach(({ queryKey }) => {
        queryClient.setQueryData(queryKey, {
          myLocation: resultMyLocation,
          locationPermission: resultLocationPermission,
        });
      });

    return {
      myLocation: resultMyLocation,
      locationPermission: resultLocationPermission,
    };
  }, [isAppApproach]);

  return {
    ...({
      myLocation: data ? data.myLocation : isSilent ? null : undefined,
      locationPermission: data ? data.locationPermission : '',
    } as GetLocationResult<typeof isSilent>),
    getLocationWhileRequestingPermission: async () => {
      setIsFunctionLoading(true);
      const result = await getLocationWhileRequestingPermission();
      setIsFunctionLoading(false);
      return result;
    },
    isLoading: isFetching,
    isFunctionLoading,
  };
};
