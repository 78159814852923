import { Exercise, ExerciseList } from '@db/collections/Exercise';
import { QueryModel } from '@db/query-models';

export class UserTest extends QueryModel {
  exerciseList: ExerciseList;
  license: string;
  score: number;
  isPassed: boolean;

  constructor({
    exercises,
    license,
    score,
    isPassed,
    queryClient,
    queryKey,
  }: DataModel<UserTestData>) {
    super({ queryClient, queryKey, instanceConstructor: UserTest, className: 'UserTest' });
    this.exerciseList = new ExerciseList({
      items: exercises.map((exercise) => new Exercise({ ...exercise, queryClient })),
      queryClient,
    });
    this.license = license;
    this.score = score;
    this.isPassed = isPassed;
  }
}
