import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';
import { db } from 'firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';

import { AcademyGeoJson } from '../models';

export const useGetAllAcademyGeoJson = () => {
  const result = useQueryWithClient({
    queryFn: async () => {
      const q = query(collection(db, 'AcademyGeoJson'), where('geoJson', '!=', []));

      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map(
        (doc) =>
          new AcademyGeoJson({
            ...(doc.data() as AcademyGeoJson),
            id: doc.id,
          }),
      );

      return data;
    },
    queryKey: [QUERY_KEY.ALL_ACADEMY_GEOJSON],
  });

  return result;
};
