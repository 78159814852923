import { BaseModel } from '@db/base-models';

export class AcademyLocation extends BaseModel {
  latitude: number;
  longitude: number;
  hash?: string;
  exact?: string;
  bubAddress: string;
  jibunAddress: string;
  roadAddress: string;

  constructor({
    latitude,
    longitude,
    hash,
    exact,
    bubAddress,
    jibunAddress,
    roadAddress,
  }: AcademyLocationData) {
    super('AcademyLocation');
    this.latitude = latitude;
    this.longitude = longitude;
    this.hash = hash;
    this.exact = exact;
    this.bubAddress = bubAddress;
    this.jibunAddress = jibunAddress;
    this.roadAddress = roadAddress;
  }

  get jibunAddress동까지() {
    const jibunArray = this.jibunAddress.split(' ');
    return jibunArray.slice(0, 3).join(' ');
  }

  get jibunAddress시군구까지() {
    const jibunArray = this.jibunAddress.split(' ');
    return jibunArray.slice(0, 2).join(' ');
  }

  get roadAddress동까지() {
    const roadArray = this.roadAddress.split(' ');
    return roadArray.slice(0, 3).join(' ');
  }

  get 시() {
    return this.jibunAddress.split(' ')[1];
  }
}
