import { db } from 'firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const checkDuplicateEmail = async (email: string) => {
  const docRef = collection(db, 'User');
  const q = query(docRef, where('email', '==', email));
  const querySnapshot = await getDocs(q);

  return querySnapshot.size > 0;
};
