import { QUERY_KEY } from '@db/constants';
import { db } from 'firebase-config';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { CouponCode } from '../models';

export const getCouponCodeData = async (code: string) => {
  const docRef = doc(db, 'CouponCode', code.toUpperCase());

  const getDocData = await getDoc(docRef);
  if (!getDocData.exists())
    throw new Error('해당 코드에 일치하는 쿠폰이 없습니다.', { cause: 'invalid-code' });

  const docData = getDocData.data() as CouponCodeData;
  findMethodAndReplace(docData, 'toDate');

  return { ...docData, id: '', code: getDocData.id };
};

export const getCouponCode = async ({ queryKey, queryClient }: QueryFn) => {
  const [, couponCode] = queryKey as [string, string];

  const docData = await getCouponCodeData(couponCode);

  return new CouponCode({ ...docData, queryClient, queryKey });
};

export const getCouponCodeWithSchoolName = async ({
  schoolName,
  queryClient,
}: {
  schoolName: string;
  queryClient: QueryClient;
}) => {
  const docRef = query(
    collection(db, 'CouponCode'),

    where('schoolName', '==', schoolName),
  );

  const getDocData = await getDocs(docRef);

  if (getDocData.empty)
    throw new Error('해당 학교 이름과 일치하는 쿠폰이 없습니다.', { cause: 'invalid-school-name' });

  // 학교 이름이 중복되는 경우는 없다고 가정하고,
  // 항상 1개의 쿠폰 코드만 반환되도록 함
  const findDoc = getDocData.docs[0];
  const docData = findDoc.data() as CouponCodeData;
  findMethodAndReplace(docData, 'toDate');

  const queryKey = [QUERY_KEY.COUPON_CODE, findDoc.id];

  return new CouponCode({ ...docData, id: '', code: findDoc.id, queryClient, queryKey });
};
// // 로그용 기록들은 유저 coupon에서는 제외
// CouponMethods.map((method) => {
//   if (method in docData) {
//     delete docData[method];
//   }
// });
// const updateUnivCode = async (
//   docRef: DocumentReference<DocumentData>,
//   userId: string,
//   method: string,
// ) => {
//   await updateDoc(docRef, {
//     [method]: arrayUnion({
//       id: userId,
//       date: new Date(),
//     }),
//   });
// };
