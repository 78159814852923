import { formatDate, subtractHHMM } from 'shared-values';

import { BaseModel } from './base-model';

function combineDateAndTime(date: Date, timeString: string) {
  // 기존 Date 객체의 날짜를 복사합니다.
  const newDate = new Date(date);

  // "HH:MM" 형식의 문자열에서 시간을 추출합니다.
  const [hours, minutes] = timeString.split(':').map(Number);

  // 새로운 Date 객체에 시간을 설정합니다.
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
}

export class FirstVisit extends BaseModel {
  date: Date;
  start: string;
  end?: string;

  constructor({ date, start, end }: FirstVisitData) {
    super('FirstVisit');
    this.date = date;
    this.start = start;
    this.end = end;
  }

  get dateString() {
    return formatDate(this.date, 'YY.MM.DD');
  }

  get startString() {
    return subtractHHMM(this.start, 10);
  }

  get fullString() {
    return this.dateString + '   ' + this.startString;
  }

  get startTime() {
    return combineDateAndTime(this.date, this.start);
  }

  public get() {
    if (!this.end) return super.get(['end']);
    return super.get();
  }
}
