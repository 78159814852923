import { BaseList } from './base-list';
import { SelectedTime } from './selected-time';
import { SelectedTimeWithoutDate } from './selected-time-without-date';

export class SelectedTimeList extends BaseList {
  constructor({ items }: { items: SelectedTime[] | SelectedTimeWithoutDate[] }) {
    super({ items, className: 'SelectedTimeList' });
    this.items = items;
  }

  get firstTime() {
    return this.firstItem;
  }

  get lastTime() {
    return this.lastItem;
  }
}
