import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

import text from '../components/text';
import { Colors } from '../styles/themes/colors';

function extractKeysFromText(config: Record<string, Record<string, string>>) {
  // 결과를 저장할 빈 배열
  const result: string[] = [];

  // 첫 번째 레벨의 각 key에 대해 반복
  Object.values(config).forEach((section) => {
    // 두 번째 레벨의 key를 추출하여 결과 배열에 추가
    Object.keys(section).forEach((key) => {
      // 맨 앞의 '.'을 제거하고 결과 배열에 추가
      result.push(key.substring(1));
    });
  });

  return result;
}
function extractKeysFromColor(config: Record<string, Record<string, string>>) {
  // 결과를 저장할 빈 배열
  const result: string[] = [];

  Object.keys(config).forEach((key) => {
    result.push('text-' + key);
  });

  return result;
}

// text- 로 시작하는 폰트 스타일과 text- 로 시작하는 색상 클래스이 겹치는 문제가 있어
// 이를 해결하기 위해 tailwind-merge를 사용하여 classGroups를 확장합니다.
// https://github.com/dcastil/tailwind-merge/issues/297
const twMerge = extendTailwindMerge({
  override: {
    theme: {
      colors: Object.keys(Colors as any),
    },
    classGroups: {
      'font-size': [...extractKeysFromText(text as any)],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
