export const compareCoupons = (a: CouponCode, b: CouponCode) => {
  // 할인끼리 비교
  if (a.discount.discountPercent && b.discount.discountPercent) {
    return a.discount.discountPercent - b.discount.discountPercent;
  }

  // 할인 금액이 있을 때 비교
  if (a.discount.discountPrice && b.discount.discountPrice) {
    return a.discount.discountPrice - b.discount.discountPrice;
  }

  // 맞지 않는 조건
  return 0;
};

// 금액이 없을 때 가장 높은 금액의 쿠폰을 찾는 함수
export const getHighestCouponWithoutPrice = (coupons: CouponCode[]) => {
  if (coupons.length === 0) return null;

  const result = coupons.reduce((acc, coupon) => {
    if (compareCoupons(coupon, acc) > 0) {
      return coupon;
    }
    return acc;
  }, coupons[0]);

  return result;
};
