import { BaseModel } from '../../../base-models';
import { claimableAcademies } from '../constants';

export const TestAcademyList = [
  'AaHcGYIzPHZ0j6x6cpuT',
  'e6MpEXtcF2R4U1p1SE7x',
  'lg1r3LDGLSnwwT5pJtIQ',
  'jXXYecllvJTui2lJeMCc',
];

/**
 * id 기반의 base academy model
 * 메소드가 추가될 때마다 academy에도 추가해야 합니다.
 */
type ClaimableValueType =
  | {
      isClaimable: true;
      value: { [key in 'lat' | 'lng' | 'zoom']: number };
    }
  | {
      isClaimable: false;
    };

export class BaseAcademy extends BaseModel {
  id: string;

  constructor({ id }: { id: string }) {
    super('BaseAcademy');
    this.id = id;
  }

  get isTestAcademy() {
    if (TestAcademyList.includes(this.id)) return true;
    return false;
  }

  get claimableInfo() {
    let value: ClaimableValueType = { isClaimable: false };
    claimableAcademies.forEach(({ academies, ...rest }) => {
      if (academies.includes(this.id)) {
        value = { isClaimable: true, value: rest };
      }
    });
    return value as ClaimableValueType;
  }
}
