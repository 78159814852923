import { BaseLogEvent, BaseParams } from '../BaseLogEvent';

export class InsightfulData extends BaseLogEvent {
  constructor(params: BaseParams) {
    super(params);
  }

  public couponReceive(coupon_source: string, coupon_position: string) {
    this.GAEvent('coupon_receive', {
      coupon_source: coupon_source,
      coupon_position: coupon_position,
    });
  }

  public couponShare(action: 'share-modal' | 'share-button' | 'close') {
    this.GAEvent('coupon_share', {
      coupon_share: action,
    });
  }
}
