import { QueryModel } from '@db/query-models';

import { TaxInvoice } from './tax-invoice';

export class MonthlyTaxInvoice extends QueryModel {
  monthlyData: TaxInvoice[];
  barobillId?: string;

  constructor({ monthlyData, queryClient, queryKey }: DataModel<MonthlyTaxInvoiceData>) {
    super({
      queryClient,
      queryKey,
      instanceConstructor: MonthlyTaxInvoice,
      className: 'MonthlyTaxInvoice',
    });
    this.monthlyData = monthlyData.map((v) => new TaxInvoice(v));
  }
}
