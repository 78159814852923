import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';

export const getEventParticipantCount = async () => {
  const docRef = doc(db, 'Config', 'eventParticipantCount');

  const docSnap = await getDoc(docRef);

  return docSnap.data() as {
    eventRoom: number;
    univCoupon: number;
    // newbieEvent: number;
  };
};
