import { db } from 'firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';

import { User } from '../models';

export const getRecommenders = async ({ queryKey }: QueryFn) => {
  const [, code] = queryKey as [string, string];
  const result = [];

  const q = query(collection(db, 'User'), where('code', '==', code));

  const querySnapshot = await getDocs(q);

  for (const doc of querySnapshot.docs) {
    const docData = { ...doc.data(), uid: doc.id } as UserData;

    result.push(new User(docData));
  }

  return result;
};
