import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useGetUser } from 'database';

import { WEB_QUERY_KEY } from '@constants';
import { DT_KAKAO_CHANNEL_ID } from '@constants/data';
import { isAppApproaching } from '@utils/Common';

import { Condition } from '../MarketingAgreementBottomSheet';
import { useQueryFocusEffect } from './use-focus-effect';

const removeKakaoPrefix = (uid: string) => uid.replace('kakao:', '');

const getKakakoChannelRelation = async (userId?: string): Promise<KakaoChannelRelation> => {
  if (!userId) return 'NONE';
  try {
    const result = await axios.post('/api/check-kakao-channels-connected', {
      target_id: removeKakaoPrefix(userId),
    });

    const channels = result.data.channels as KakaoChannel[];

    const findChannel = channels.find(
      (channel) => channel.channel_public_id === DT_KAKAO_CHANNEL_ID,
    );

    if (!findChannel) return 'NONE';

    return findChannel.relation as KakaoChannelRelation;
  } catch (error) {
    return 'ADDED';
  }
};

const isAppApproach = isAppApproaching();

/**
 * 앱에서 외부 화면으로 나갔다가 돌아왔을 때 작동하는 callback (Ex : 알림 권한 변경을 위해 설정으로 이동했다가 돌아옴)
 * 웹 동작 : visibilitychange 이벤트를 사용
 * 앱 동작 : receiveResumeCallback 이벤트를 사용 (앱에서 전송해줌)
 */
export const callbackWhenResume = (callback: (arg?: any) => void) => {
  window.addEventListener('visibilitychange', callback as any);
  // 앱일 때는 receiveResumeCallback라는 customEvent를 사용해서 focus를 감지한다.
  if (isAppApproach) {
    window.addEventListener('receiveResumeCallback', callback as any);
  }

  return () => {
    window.removeEventListener('visibilitychange', callback as any);
    if (isAppApproach) {
      window.removeEventListener('receiveResumeCallback', callback as any);
    }
  };
};

const queryKey = [WEB_QUERY_KEY.KAKAO_CHANNEL];

const onAddKakaoChannel = async () => {
  if (isAppApproach) {
    window.flutter_inappwebview.callHandler('AddKakaoChannel');
  } else {
    //인증이 안되어있는 경우 인증한다.
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_LOGIN_JAVASCRIPT_API_KEY);
    }

    window.Kakao.Channel.addChannel({
      channelPublicId: '_nxewixj',
    });
  }
};

export const useKakaoChannelAgree = (): Condition => {
  const { user, isLoading: isUserLoading } = useGetUser();
  const { data: kakaoChannelRelation, isLoading: isKakaoChannelRelationLoading } = useQuery({
    queryKey,
    queryFn: () => getKakakoChannelRelation(user?.uid),
    enabled: !!user,
    // always로 설정시 캐시와 관계 없이 window focus시에 캐시를 가져온다.
    refetchOnWindowFocus: 'always',
  });
  const initialState = useMemo(
    () => kakaoChannelRelation === 'ADDED',
    [isKakaoChannelRelationLoading],
  );
  useQueryFocusEffect();

  return {
    initialState,
    state: kakaoChannelRelation === 'ADDED',
    onClick: async () => {
      await onAddKakaoChannel();
    },
    isLoading: isKakaoChannelRelationLoading || isUserLoading,
  };
};
