import { MotionValue, motion } from 'framer-motion';

export const ProgressBar = ({
  progressPercentage,
}: {
  progressPercentage: MotionValue<number> | number | string;
}) => {
  return (
    <motion.div
      className='bg-DTYellow-400 absolute bottom-[-2px] left-0 z-10 h-2 w-full max-w-[500px] origin-[0%_0%]'
      initial={{ scaleX: 0 }}
      {...(progressPercentage instanceof MotionValue
        ? {
            style: { scaleX: progressPercentage },
          }
        : { animate: { scaleX: progressPercentage } })}
      transition={{
        type: 'spring',
        bounce: 0,
        mass: 0.1,
      }}
    />
  );
};
