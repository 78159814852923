import { db } from 'firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const checkDuplicatePhoneNum = async (phoneNum: string) => {
  const docRef = collection(db, 'User');
  const q = query(docRef, where('phoneNum', '==', phoneNum));
  const querySnapshot = await getDocs(q);

  return querySnapshot.size > 0;
};
