import { db } from 'firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { CouponCode } from '../models';

const getCouponCodesData = async () => {
  const docRef = collection(db, 'CouponCode');

  const getDocData = await getDocs(docRef);

  return getDocData.docs.map((doc) => {
    const docData = doc.data() as CouponCodeData;
    findMethodAndReplace(docData, 'toDate');
    return new CouponCode({ ...docData, id: '', code: doc.id });
  });
};

export const getCouponCodes = async () => {
  return await getCouponCodesData();
};
