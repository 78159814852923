import Script from 'next/script';

export const Maze = () => {
  return (
    <Script>
      {process.env.NEXT_PUBLIC_PLATFORM_ENV === 'production'
        ? `
            (function (m, a, z, e) {
              var s, t;
              try {
                t = m.sessionStorage.getItem('maze-us');
              } catch (err) {}
              if (!t) {
                t = new Date().getTime();
                try {
                  m.sessionStorage.setItem('maze-us', t);
                } catch (err) {}
              }
              s = a.createElement('script');
              s.src = z + '?t=' + t + '&apiKey=' + e;
              s.async = true;
              a.getElementsByTagName('head')[0].appendChild(s);
              m.mazeUniversalSnippetApiKey = e;
            })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '2f1b0913-23df-4f4a-a712-3c1485e29627');
          `
        : ''}
    </Script>
  );
};
