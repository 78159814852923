'use client';

import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { cn } from 'tailwind-config';

interface PortalProps {
  children: ReactNode;
  isBackgroundBlack?: boolean;
  onClickBackground?: () => void;
  initialColor?: string;
  animateColor?: string;
  isStopPrapagation?: boolean;
  position?: 'initial' | 'fixed';
  className?: string;
}

export const Portal = ({
  children,
  isBackgroundBlack = true,
  onClickBackground,
  initialColor,
  animateColor,
  isStopPrapagation = true,
  position = 'fixed',
  className,
}: PortalProps) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal');
    document.body.style.overflow = 'hidden';
    setMounted(true);
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return mounted && ref.current
    ? createPortal(
        <div
          onClick={onClickBackground}
          className={cn(
            'fixed left-0 top-0 z-[1000] block h-full w-full overflow-hidden',
            isBackgroundBlack ? 'bg-[rgba(0,0,0,0.5)]' : 'bg-transparent',
            position === 'initial' ? 'static' : 'fixed',
            className,
          )}
        >
          {isStopPrapagation ? (
            <div onClick={(e) => e.stopPropagation()}>{children}</div>
          ) : (
            children
          )}
        </div>,
        ref.current,
      )
    : null;
};
