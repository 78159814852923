import { db } from 'firebase-config';
import { collection, getDocs, query } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { Timetable } from '../models';

export const getTimetables = async ({}: QueryFn) => {
  const q = query(collection(db, 'Timetable'));

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => {
    const docData = { ...doc.data(), id: doc.id } as TimetableData;

    findMethodAndReplace(docData, 'toDate');
    return new Timetable({ ...docData });
  });
};
