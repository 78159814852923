import { Academy, AcademyList } from '../models';
import { AcademyLocation } from './../models/academy-location';
import { getAcademiesData } from './get-academies';

export const getRecommendationAcademies = async ({ queryKey, queryClient }: QueryFn) => {
  const [, academyLocation, isSimulation, items] = queryKey as [
    string,
    AcademyLocation,
    boolean,
    number,
  ];

  const academiesData = await getAcademiesData();

  const getAcademies = () => {
    if (isSimulation) {
      // 조건이 참일 때, simulation 데이터만 필터링하여 반환
      const academies = academiesData
        .map((data) => new Academy({ ...data }))
        .filter((academy) => academy.isSimulation);
      return academies;
    } else {
      // 조건이 거짓일 때, 운전학원 데이터만 변환하여 반환
      const academies = academiesData
        .map((data) => new Academy({ ...data }))
        .filter((academy) => !academy.isSimulation && !academy.isTestAcademy)
        .filter((academy) => academy.id !== 'ciaUcB5L4ziqHFcHfJbE');
      return academies;
    }
  };

  const result = getAcademies();

  const academyList = new AcademyList({ items: result, queryClient, queryKey });

  const recommendationAcademies = academyList
    .setNearbyAcademies({
      targetLocation: academyLocation,
      myLocation: academyLocation,
    })
    .sortOnlyBookable()
    .sortByDistance()
    .slice(items ? items : 3);

  return recommendationAcademies.items;
};
