import { useMemo, useState } from 'react';

import { useGetUser } from '@db/collections';
import { useToast } from 'design-system';
import { db } from 'firebase-config';
import { doc, updateDoc } from 'firebase/firestore';
import { formatDate } from 'shared-values';

import { Condition } from '../MarketingAgreementBottomSheet';

export const useMarketingAgree = (): Condition => {
  const [isMarketingAgree, setIsMarketingAgree] = useState(true);
  const { user, isLoading, refetch } = useGetUser();
  const initialState = useMemo(() => Boolean(user?.notificationAgreed), [isLoading]);
  const { openToast } = useToast();

  return {
    initialState,
    state: isMarketingAgree,
    onClick: () => setIsMarketingAgree((prev) => !prev),
    isLoading: false,
    onSubmit: async () => {
      if (!user) return;
      await updateDoc(doc(db, 'User', user.uid), {
        notificationAgreed: true,
      });
      openToast({
        title: `${formatDate(new Date(), 'YYYY년 MM월 DD일')} 마케팅 수신에 동의하셨습니다.`,
      });
      await refetch();
    },
  };
};
