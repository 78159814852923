import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getExercisesByIndexes } from '../calls';

export const useGetExercisesByIndexes = ({
  indexes,
  isNoCache = false,
}: {
  indexes: number[];
  isNoCache?: boolean;
}) => {
  const result = useQueryWithClient({
    queryFn: getExercisesByIndexes,
    queryKey: [QUERY_KEY.INDEXES_EXERCISE, indexes],
    ...(isNoCache && { staleTime: 0, gcTime: 0 }),
  });

  return result;
};
