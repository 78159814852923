import { isEmptyString } from 'shared-values';

import { BaseModel } from '../base-model';
import { LessonCondition } from '../lesson-condition';

export class Discount extends BaseModel {
  discountPrice: number;
  discountPercent?: number;
  discountType: DiscountType;
  discountSource: DiscountSource;
  promotionName: string;
  academyId: string;
  lessonConditions: LessonCondition[];

  constructor({
    discountPrice,
    discountPercent,
    discountType,
    discountSource,
    promotionName,
    academyId,
    lessonConditions,
  }: DiscountData) {
    super('Discount');

    this.discountPrice = discountPrice ?? 0;
    this.discountPercent = discountPercent;
    this.discountType = discountType;
    this.discountSource = discountSource;
    this.promotionName = promotionName;
    this.academyId = academyId ?? '';
    this.lessonConditions = lessonConditions
      ? lessonConditions.map((lessonCondition) => new LessonCondition(lessonCondition))
      : [];
  }

  get discountAmount() {
    if (this.discountPercent && !this.discountPrice) {
      return `${this.discountPercent * 100}%`;
    }

    return `${this.discountPrice.toLocaleString()}원`;
  }

  public convertToPrice(LessonPrice: number) {
    if (this.discountPercent && !this.discountPrice) {
      return LessonPrice * this.discountPercent;
    }

    return this.discountPrice;
  }

  public canApply(condition: DiscountCondition) {
    return this.canApplyAcademy(condition.academyId) && this.canApplyLessonConditions(condition);
  }

  private canApplyAcademy(academyId?: string) {
    return (
      isEmptyString(academyId) || isEmptyString(this.academyId) || this.academyId === academyId
    );
  }

  private canApplyLessonConditions(condition: DiscountCondition) {
    if (this.lessonConditions.length === 0) {
      return true;
    }

    // lessonConditions 순회하며 조건 검사
    return this.lessonConditions.some((lessonCondition) => {
      return lessonCondition.canApply(condition);
    });
  }
}
