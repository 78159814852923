import { Lesson } from '@db/collections/Academy';
import { QueryModel } from '@db/query-models/query-model';

export class Review extends QueryModel {
  id: string;
  academyId: string;
  orderId: string;
  lesson: Lesson | SimulationLesson;
  createdAt: Date;
  userName: string;
  userId: string;
  images: string[];
  likes: string[];
  likeCount: number;
  reviewText: string;
  rate: {
    facility: number;
    service: number;
    lecture: number;
    average: number;
  };
  lectureProgress: ReviewData['lectureProgress'];
  state: ReviewData['state'];
  rewarded = false;

  constructor({
    id,
    queryClient,
    queryKey,
    academyId,
    orderId,
    lesson,
    createdAt,
    userName,
    userId,
    images,
    likes,
    likeCount,
    reviewText,
    rate,
    lectureProgress,
    state,
    rewarded,
  }: DataModel<ReviewData> & Partial<QueryModelInnerProps>) {
    super({
      queryClient,
      queryKey,
      instanceConstructor: Review,
      className: 'Review',
    });
    this.id = id;
    this.academyId = academyId;
    this.lesson = new Lesson(lesson);
    this.createdAt = createdAt;
    this.orderId = orderId;
    this.userName = userName;
    this.userId = userId;
    this.images = images;
    this.likes = likes;
    this.likeCount = likeCount;
    this.reviewText = reviewText;
    this.rate = rate;
    this.lectureProgress = lectureProgress;
    this.state = state;
    this.rewarded = rewarded ?? false;
  }
}
