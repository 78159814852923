import { BaseAcademy } from 'database';

export class SearchAcademy extends BaseAcademy {
  type = 'academy';
  academyType: AcademyType;
  latitude: number;
  longitude: number;
  name: string;
  displayName: string;
  uid: string;
  bubAddress: string;
  isGeneral: boolean;

  constructor({
    id,
    latitude,
    longitude,
    name,
    uid,
    bubAddress,
    isGeneral,
    displayName,
    type,
    academyType,
  }: { id: string } & SearchAcademyData) {
    super({ id });
    this.latitude = latitude;
    this.longitude = longitude;
    this.name = name;
    this.displayName = displayName ?? this.combinateDisplayName;
    this.uid = uid;
    this.bubAddress = bubAddress;
    this.isGeneral = isGeneral;
    this.academyType = academyType;
  }

  get isSimulation() {
    return this.academyType === 'simulation';
  }

  get combinateDisplayName() {
    if (this.academyType === 'simulation') {
      return this.name;
    }
    return `${this.name} ${this.isGeneral ? '자동차운전학원' : '자동차운전전문학원'}`;
  }
}
