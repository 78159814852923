import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getAcademyGeoJson } from '../calls';

export const useGetAcademyGeoJson = (academyId?: string) => {
  const result = useQueryWithClient({
    queryFn: getAcademyGeoJson,
    queryKey: [QUERY_KEY.ACADEMY_GEOJSON, academyId as string],
    enabled: !!academyId && academyId !== '',
  });

  return result;
};
