import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { MonthlyTaxInvoice } from '../models';

export const getTaxInvoiceById = async ({ queryKey, queryClient }: QueryFn) => {
  const [, id] = queryKey as [string, string];
  const docRef = doc(db, 'TaxInvoice', id);
  const invoiceData = (await getDoc(docRef)).data() as MonthlyTaxInvoiceData;

  findMethodAndReplace(invoiceData, 'toDate');

  return new MonthlyTaxInvoice({ ...invoiceData, queryKey, queryClient });
};
