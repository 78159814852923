// 만원 단위의 숫자를 금액으로 변환
// ex : 25000 -> 2.5만원
export const convertTo만원단위 = (amount: number): string => {
  const manWonUnit = amount / 10000;

  if (Math.floor(manWonUnit) === manWonUnit) {
    // If the number is an integer, return without decimal points
    return `${manWonUnit}만원`;
  } else {
    // Otherwise, return with decimal points
    return `${manWonUnit.toFixed(1)}만원`;
  }
};
