import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';

import { signInByIdToken } from '@utils/Auth';

export const loginExistUser = async (firebaseIdToken: string) => {
  const userCredentialData = await signInByIdToken(firebaseIdToken);

  const userId = userCredentialData.user.uid;
  const userData = (await getDoc(doc(db, 'User', userId))).data() as UserData;

  return { ...userData, uid: userId };
};
