'use client';

import React, { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

interface OverlayProps {
  mount(id: string, element: ReactNode): void;
  unmount(id: string): void;
}

const initOverlayContext: OverlayProps = {
  mount: () => {},
  unmount: () => {},
};

const OverlayContext = createContext(initOverlayContext);

if (process.env.NEXT_PUBLIC_PLATFORM_ENV === 'development') {
  OverlayContext.displayName = 'overlayContext';
}

export const useOverlayContext = () => {
  const context = useContext(OverlayContext);

  if (!context) {
    throw new Error('useOverlayContext must be used within OverlayProvider');
  }

  return context;
};

export const OverlayProvider = ({ children }: { children: React.ReactNode }) => {
  const [overlayById, setOverlayById] = useState<Map<string, React.ReactNode>>(new Map());

  const mount = useCallback((id: string, element: React.ReactNode) => {
    setOverlayById((prev) => {
      const cloned = new Map(prev);
      cloned.set(id, element);
      return cloned;
    });
  }, []);

  const unmount = useCallback((id: string) => {
    setOverlayById((prev) => {
      const cloned = new Map(prev);
      cloned.delete(id);
      return cloned;
    });
  }, []);

  const contextValue = useMemo(() => ({ mount, unmount }), [mount, unmount]);

  return (
    <OverlayContext.Provider value={contextValue}>
      {children}
      {[...overlayById.entries()].map(([id, element]) => {
        return <React.Fragment key={id}>{element}</React.Fragment>;
      })}
    </OverlayContext.Provider>
  );
};
