import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { Order } from '../models';

export const getOrder = async ({ queryKey, queryClient }: QueryFn) => {
  const [, id] = queryKey as [string, string];
  const docRef = doc(db, 'Order', id);
  const docSnap = (await getDoc(docRef)).data() as OrderData;

  findMethodAndReplace(docSnap, 'toDate');
  return new Order({ ...docSnap, id, queryKey, queryClient });
};
