import { QUERY_KEY } from '@db/constants';

import { useQueryWithClient } from '../../../hooks';
import { getAcademy } from '../calls';

export const useGetAcademy = (id?: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.ACADEMY, id ?? ''],
    queryFn: getAcademy,
    enabled: !!id && id !== '',
  });

  return result;
};
