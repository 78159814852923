import { useWindowSize } from '@hooks';

export const RefundTerm = () => {
  const { height } = useWindowSize();

  return (
    <div className='text-Body2 bg-gray-100 px-20 py-16' style={{ minHeight: height }}>
      <div className='text-B4 mb-16'>
        운전선생은 예약 취소 시 환불 기준을 아래와 같이 운영하고 있사오니 확인 후 예약을
        취소해주시길 바랍니다.
      </div>
      {/* <RefundBox is전액결제={true} /> */}
    </div>
  );
};
