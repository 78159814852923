import { QueryFunctionContext } from '@tanstack/react-query';
import { db } from 'firebase-config';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

export const getBlogs = async ({
  queryKey,
}: QueryFunctionContext<[string, number?]>): Promise<Blog[]> => {
  const [, limitNum = 0] = queryKey;
  const result = [];
  let q;
  if (limitNum > 0) {
    q = query(collection(db, 'Blog'), orderBy('createdAt', 'desc'), limit(limitNum as number));
  } else {
    q = query(collection(db, 'Blog'), orderBy('createdAt', 'desc'));
  }

  const querySnapshot = await getDocs(q);

  for await (const doc of querySnapshot.docs) {
    const docData = doc.data();
    result.push({ ...docData, id: doc.id });
  }

  findMethodAndReplace(result, 'toDate');

  return result as Blog[];
};
