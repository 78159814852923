import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';

import { UserTest } from '../models';

export const getUserTest = async ({ queryKey, queryClient }: QueryFn) => {
  const [, id] = queryKey as [string, string | undefined];

  if (!id) return null;

  const docRef = doc(db, 'UserTest', id);
  const docSnap = (await getDoc(docRef)).data() as UserTestData;

  return new UserTest({ ...docSnap, queryClient, queryKey });
};
