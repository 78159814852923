export const TEST_BANK_BY_CATEGORIES = [
  {
    type: 'sentence',
    choicesNum: 4,
    answerNum: 1,
    text: '문장형',
    score: 2,
    problemsNum: 583,
  },
  {
    type: 'sentence',
    choicesNum: 4,
    answerNum: 2,
    text: '문장형',
    score: 3,
    problemsNum: 97,
  },
  {
    type: 'picture',
    choicesNum: 5,
    answerNum: 2,
    text: '사진형',
    score: 3,
    problemsNum: 100,
  },
  {
    type: 'sign',
    choicesNum: 4,
    answerNum: 1,
    text: '안전표지형',
    score: 2,
    problemsNum: 100,
  },
  {
    type: 'illust',
    choicesNum: 5,
    answerNum: 2,
    text: '일러스트형',
    score: 3,
    problemsNum: 85,
  },
  {
    type: 'video',
    choicesNum: 4,
    answerNum: 1,
    text: '동영상형',
    score: 5,
    problemsNum: 35,
  },
];
