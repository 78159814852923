import { useEffect, useState } from 'react';

import { Router, useRouter } from 'next/router';

export const useUrlState = (pathname: Router['pathname']) => {
  const [state, setState] = useState<string>('');
  const router = useRouter();

  useEffect(() => {
    const prevQuery = router.query;

    const newQuery = {
      ...prevQuery,
      code: state,
    };

    router.replace(
      {
        pathname,
        query: newQuery,
      },
      undefined,
      {
        shallow: true,
      },
    );
  }, [state]);

  return [state, setState] as const;
};
