import { Academy } from './Academy';
import { AcademyGeoJson } from './AcademyGeoJson';
import { AcademyGeoJsons } from './AcademyGeoJsons';
import { Blog } from './Blog';
import { CouponCode } from './CouponCode';
import { EventRoom } from './EventRoom';
import { Exercise } from './Exercise';
import { Order } from './Order';
import { Review } from './Review/models';
import { Timetable } from './Timetable';
import { UnivEventCertification } from './UnivEventCertification';
import { User } from './User';
import { UserTest } from './UserTest';

// model을 key 값만 저장할 때 때문에 model을 찾기 위한 map
// prettier-ignore
export const COLLECTION_MAP = {
  'Academy': Academy,
  'Review': Review,
  'AcademyGeoJson' : AcademyGeoJson,
  'AcademyGeoJsons' : AcademyGeoJsons,
  'Blog' : Blog,
  'CouponCode' : CouponCode,
  'EventRoom' : EventRoom,
  'Exercise' : Exercise,
  'Order' : Order,
  'Timetable' : Timetable,
  'User' : User,
  'UserTest' : UserTest,
  'UnivEventCertification': UnivEventCertification
}
