'use client';

import { ReactNode } from 'react';

import { NewIcon } from '@ds/components/new';

import { BOXBUTTON_COLOR, BoxButton } from '../Button';
import { BottomSheetWrapper, Footer, Header } from './components';

export interface BottomSheetProps {
  isWebToApp?: false;
  isOpen: boolean;
  onClose: () => void;
  header: {
    title: string;
    isTitle?: boolean;
    subTitle?: string | ReactNode;
    isCloseButtonExist?: boolean;
  };
  main: React.ReactNode;
  footer?: {
    defaultButton: {
      text: ReactNode;
      color: BOXBUTTON_COLOR.BLACK | BOXBUTTON_COLOR.DTYellow | BOXBUTTON_COLOR.WHITE;
      onClick: any;
    };
    extraButton?: {
      text: ReactNode;
      color: BOXBUTTON_COLOR.BLACK | BOXBUTTON_COLOR.DTYellow | BOXBUTTON_COLOR.WHITE;
      onClick: any;
    };
    isColumn?: boolean;
  };
}

interface WebToAppBottomSheetProps {
  isWebToApp: true;
  isOpen: boolean;
  onClose: () => void;
  image: () => React.ReactElement;
  onWebToAppButtonClick: () => void;
}

export type PickFromBottomSheetProps<T extends keyof BottomSheetProps> = Pick<
  BottomSheetProps,
  T
>[T];

/**
 *
 * @param isOpen 바텀시트를 띄우는 flag
 * @param onClose 닫힐 때 실행할 동작
 * @param header 헤더에 들어가는 내용
 * @param main 메인 컨텐츠
 * @param footer 푸터에 들어가는 버튼(0~2개)
 * @returns
 */
export const BottomSheet = (props: BottomSheetProps | WebToAppBottomSheetProps) => {
  if (props.isWebToApp)
    return (
      <BottomSheetWrapper isOpen={props.isOpen} onClose={props.onClose}>
        {props.image()}
        <div className="fixed bottom-0 h-[86px] w-full px-16 pt-16">
          <BoxButton
            color={BOXBUTTON_COLOR.DTYellow}
            text="앱 다운받기"
            onClick={props.onWebToAppButtonClick}
          />
        </div>
      </BottomSheetWrapper>
    );

  const {
    isOpen,
    onClose,
    main,
    header: { title, isTitle = true, subTitle, isCloseButtonExist = true },
    footer,
  } = props;

  return (
    <BottomSheetWrapper isOpen={isOpen} onClose={onClose}>
      <div className="rounded-t-[24px] bg-white px-16 pt-28">
        {isTitle && <Header title={title} subTitle={subTitle} />}
        <div className="mt-19">{main}</div>
        {footer && (
          <Footer
            defaultButton={footer.defaultButton}
            extraButton={footer.extraButton && footer.extraButton}
            isColumn={footer.isColumn}
          />
        )}
        {isCloseButtonExist && (
          <div className="absolute right-16 top-28 cursor-pointer" onClick={() => onClose()}>
            <NewIcon
              icon="xincircle-filled"
              size={24}
              className="fill-new-gray-400"
              color="shadow"
            />
          </div>
        )}
      </div>
    </BottomSheetWrapper>
  );
};
