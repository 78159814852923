'use client';

import * as React from 'react';

import { Pressed } from '@ds/components/reusable';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import { cn } from 'tailwind-config';

import { NewIcon, NewIconType } from '../NewIcon/NewIcon';

export type NewBoxButtonType = keyof typeof buttonVariants;

const buttonVariants = cva('inline-flex gap-2 items-center justify-center whitespace-nowrap', {
  variants: {
    styles: {
      'filled-yellow':
        'inactive:text-new-gray-500 text-new-gray-900 inactive:bg-new-gray-200 bg-new-DTYellow-400',
      'filled-red':
        'bg-new-DTRed-400 text-new-white inactive:text-new-gray-500 inactive:bg-new-gray-200',
      'filled-black':
        'bg-new-gray-900 text-new-white inactive:text-new-gray-500 inactive:bg-new-gray-200',
      'filled-gray': 'bg-new-gray-200 text-new-gray-500 inactive:text-new-gray-500',
      outlined:
        'border-new-gray-200 text-new-gray-900 border-[1.8px] inactive:text-new-gray-500 selected:bg-[#FFF6131A] selected:border-new-DTYellow-400',
    },
    size: {
      small: 'min-w-[44px] text-new-Body2-bold py-4 gap-2 px-8 rounded-[8px]',
      medium: 'min-w-[100px] text-new-Body2-bold py-10 gap-4 px-16 rounded-[10px]',
      large: 'min-w-[140px] text-new-Body1-bold py-14 gap-6 px-24 rounded-[14px]',
    },
    fill: {
      true: 'w-full',
      false: 'w-fit',
    },
  },
  defaultVariants: {
    styles: 'filled-yellow',
    size: 'large',
    fill: false,
  },
});

interface NewBoxButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  // pressed의 경우는 유저의 액션으로 결정되는 상태이기에 대부분의 상황에서 외부에서 주입하지 않지만, 강제로 pressed 상태를 만들고 싶을 때 사용합니다.
  state?: 'active' | 'inactive' | 'selected' | 'pressed';
  icon?: NewIconType;
  iconPosition?: 'left' | 'right';
  label?: React.ReactNode;
}

const NewBoxButton = React.forwardRef<HTMLButtonElement, NewBoxButtonProps>(
  (
    {
      className,
      label,
      icon,
      iconPosition = 'right',
      styles = 'filled-yellow',
      size = 'large',
      state = 'active',
      asChild = false,
      fill = false,
      children,
      disabled,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        ref={ref}
        className={cn(
          buttonVariants({ styles, size, className, fill }),
          state,
          disabled && 'inactive',
          iconPosition === 'left' && 'flex-row-reverse',
          'relative',
        )}
        disabled={disabled}
        {...props}
      >
        <Slottable>{children ?? label}</Slottable>
        {icon && <NewIcon icon={icon} size={20} className={className} />}
        <Pressed isForced={state === 'pressed'} />
      </Comp>
    );
  },
);

NewBoxButton.displayName = 'NewBoxButton';

export { NewBoxButton, buttonVariants };
