import { db } from 'firebase-config';
import { doc, getDocFromServer, updateDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

export const getUserById = async (id: string, deviceToken?: string) => {
  const docRef = doc(db, 'User', id);
  const userData = (await getDocFromServer(docRef)).data();

  findMethodAndReplace(userData, 'toDate');

  // deviceToken update (모바일 앱 용)
  if (deviceToken && (!userData?.token || userData?.token !== deviceToken)) {
    updateDoc(docRef, {
      token: deviceToken,
      isNotiGranted: window.isNotiGranted ?? 'undefined',
    });
  }
  return { ...userData, uid: id } as UserData;
};
