import * as React from 'react';

import { type VariantProps, cva } from 'class-variance-authority';
import Image from 'next/image';
import { cn } from 'tailwind-config';

import { NewIcon, NewIconType } from '../NewIcon';

const bannerVariants = cva('w-full flex items-center', {
  variants: {
    size: {
      small: 'p-12 bg-new-gray-50 rounded-[8px] text-new-Body2-medium',
      medium: 'bg-new-white rounded-[16px] text-new-Caption1-medium justify-between',
    },
    type: {
      'content-left': 'justify-start text-new-gray-600',
      'content-center': 'justify-center text-new-gray-900',
      'title+description': '',
    },
    backgroundColor: {
      white: 'bg-new-white',
      gray: 'bg-new-gray-50',
    },
  },
  defaultVariants: {
    size: 'small',
    type: 'content-left',
  },
});

interface BannerProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof bannerVariants> {
  title?: string;
  description: React.ReactNode;
  icon?: NewIconType;
  image?: string;
  // 개발에서만 사용되는 값입니다.
  alt?: string;
  onClick?: () => void;
}

const Banner = React.forwardRef<HTMLElement, BannerProps>(
  (
    { size, type, icon, image, alt, title, description, onClick, backgroundColor, className },
    ref,
  ) => {
    return (
      <div
        ref={ref as React.Ref<HTMLDivElement>}
        className={cn(bannerVariants({ size, type, backgroundColor }), className)}
        onClick={onClick}
      >
        {size === 'small' ? (
          <div className="flex items-center gap-[6px]">
            {image ? (
              <Image
                src={image}
                alt={alt ?? '운전선생 배너 이미지'}
                width={20}
                height={20}
                quality={10}
              />
            ) : (
              icon && <NewIcon icon={icon} size={16} className="fill-new-gray-400" />
            )}
            <p className="text-new-Caption1-medium text-new-gray-900 whitespace-pre-line text-opacity-50">
              {description}
            </p>
          </div>
        ) : (
          <div className="flex w-full justify-between rounded-[20px] px-24 py-16">
            <div className="flex flex-col justify-center gap-2">
              <p className="text-new-Section-Title text-new-gray-900">{title}</p>
              <p className="text-new-Caption1-medium text-new-gray-900 whitespace-pre-line text-opacity-50">
                {description}
              </p>
            </div>
            <div className="flex-nowrap">
              {image ? (
                <Image
                  src={image}
                  alt={alt ?? '운전선생 배너 이미지'}
                  width={200}
                  height={200}
                  className="h-[56px] w-[56px]"
                />
              ) : (
                <div className="bg-new-gray-50 h-56 w-56 rounded-[8px]" />
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export { Banner, bannerVariants };
