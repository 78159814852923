export const EXPLORE_HEADER_HEIGHT = 113;
export const BOTTOM_NAV_BAR_HEIGHT = 82;
export const TOGETHER_EVENT_BOTTOM_SELECT_BAR_HEIGHT = 88;
export const TOGETHER_EVENT_BOTTOM_ACADEMY_DESCRIPTION_HEIGHT = 38;
export const TOP_NAV_BAR_HEIGHT = 52;
export const CHIP_BAR_HEIGHT = 50;
export const TABS_HEIGHT = 52;

export const BOTTOM_SHEET_DEFUALT_HEIGHT = 350 - BOTTOM_NAV_BAR_HEIGHT;
export const BOTTOM_SHEET_MIN_HEIGHT = 37;
export const BOTTOM_SHEET_ONE_HEIGHT = 270 - BOTTOM_NAV_BAR_HEIGHT;

export const Z_INDEXES = {
  MY_LOCATION_ICON: 1,
  BOOKABLE_MARKER_ICON: 2,
  SELECTED_MARKER_ICON: 100,
  MAP: 0,
};

export const MARKER_WIDTH = 82.01;
export const ACTIVATED_MARKER_WIDTH = 82.01;
export const MARKER_HEIGHT = 37.07;
export const ACTIVATED_MARKER_HEIGHT = 46.07;
