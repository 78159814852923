export * from './Academy';
export * from './AcademyDetail';
export * from './AcademyGeoJson';
export * from './AcademyGeoJsons';
export * from './Blog';
export * from './Config';
export * from './CouponCode';
export * from './EventRoom';
export * from './Exercise';
export * from './Order';
export * from './PageView';
export * from './Review';
export * from './SalesAcademy';
export * from './TaxInvoice';
export * from './Timetable';
export * from './UnivEventCertification';
export * from './User';
export * from './UserTest';
export * from './Verification';
export * from './collection-map';
