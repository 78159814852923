import { Lesson } from './lesson';

export class SimulationLesson extends Lesson {
  registType: RegistType;
  period: Period;
  notice: string;
  priceNotice: string[];
  lessonNotice: string[];

  constructor({
    code,
    description,
    name,
    price,
    isPopular,
    arrivalMethod,
    isPriceInquiry,
    registType,
    period,
    notice,
    priceNotice,
    lessonNotice,
    additionalPrice,
    discountPrice,
  }: SimulationLessonData) {
    super({
      code,
      price,
      isPopular,
      arrivalMethod,
      isPriceInquiry,
      description,
      name,
      additionalPrice,
      discountPrice,
    });
    this.registType = registType;
    this.period = period;
    this.notice = notice;
    this.priceNotice = priceNotice;
    this.lessonNotice = lessonNotice;
  }

  get displayRegistType() {
    if (this.registType === 'license') return '면허 취득';
    if (this.registType === 'training') return '운전 연수';
    return '';
  }

  get label() {
    return `${this.displayRegistType}/${this.periodText}/${this.license}`;
  }

  get lessonInfo(): LessonInfo {
    return {
      ...super.lessonInfo,
      lessonPeriodText: this.period.text,
      lessonRegistType: this.registType,
    };
  }

  get periodText() {
    return this.period.text;
  }
}
