import { compareStartAndEndTime } from './compare-start-and-end-time';

export const checkIsLocked = (time: StartAndEndTime | TimeWithDate, lockedDatas: LockedData[]) => {
  let isTimeLocked = false;

  const timeArray = lockedDatas.filter(
    (lockedData) =>
      compareStartAndEndTime(time, { start: lockedData.start, end: lockedData.end }) &&
      lockedData.value,
  );

  if (timeArray.length) {
    isTimeLocked = timeArray[0].value;
  }

  return isTimeLocked;
};

export const checkIsListLocked = (selectedTime: SelectedTime, lockedDatas: LockedData[]) => {
  const { times } = selectedTime;

  let isLocked = false;

  times.map((time: StartAndEndTime | TimeWithDate) => {
    if (isLocked) return;
    isLocked = checkIsLocked(time, lockedDatas);
  });

  return isLocked;
};
