import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getBlogsTrending } from '../calls';

export const useGetTrendingBlogs = () => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.TRENDY_BLOGS],
    queryFn: getBlogsTrending,
  });

  return result;
};
