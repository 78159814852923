import { QUERY_KEY } from '@db/constants';
import { useInfiniteQueryWithClient } from '@db/hooks';
import { QueryDocumentSnapshot } from 'firebase/firestore';

import { getMainExercises } from '../calls';

export const useGetMainExercises = () => {
  const result = useInfiniteQueryWithClient({
    queryKey: [QUERY_KEY.MAIN_EXERCISE] as [string],
    queryFn: getMainExercises,
    getNextPageParam: (querySnapshot) =>
      querySnapshot.nextPageParam as QueryDocumentSnapshot<Exercise>,
    initialPageParam: null,
  });

  return result;
};
