import { ComponentPropsWithoutRef, createRef, useEffect } from 'react';

import { useGetUser } from '@db/collections/User';
import { isUserManager } from '@db/collections/User/utils';
import { OverlayProvider, Spacer, Toaster } from 'design-system';
import { atom, useAtomValue } from 'jotai';
import { debounce } from 'lodash-es';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { HOME_URI } from 'shared-values';
import { cn } from 'tailwind-config';

import { Lottie } from '@components/Lottie';

import { PAGE_MAPPING } from '@constants/page-mapping';
import { isAppApproaching } from '@utils/Common';

import { GlobalAppBar } from './Floating';
import { useGetAppBarProps } from './Floating/GlobalAppBar/hooks';
import { PC제휴문의 } from './Floating/PC제휴문의';

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

const BottomNavbar = dynamic(() =>
  import('./Floating/BottomNavbar').then((v) => ({ default: v.BottomNavbar })),
);
const PCWebToApp = dynamic(() =>
  import('./Floating/PCWebToApp').then((v) => ({ default: v.PCWebToApp })),
);
const GlobalModal = dynamic(
  () => import('./GlobalModal').then((v) => ({ default: v.GlobalModal })),
  {
    ssr: false,
  },
);
const GlobalReview = dynamic(
  () => import('./GlobalModal').then((v) => ({ default: v.GlobalReview })),
  {
    ssr: false,
  },
);
const GlobalBottomFloatingBar = dynamic(
  () =>
    import('./Floating/GlobalBottomFloatingBar').then((v) => ({
      default: v.GlobalBottomFloatingBar,
    })),
  {
    ssr: false,
  },
);
const GlobalLoading = dynamic(
  () => import('./Instant/GlobalLoading').then((v) => ({ default: v.GlobalLoading })),
  {
    ssr: false,
  },
);

const isAppApproach = isAppApproaching();
const GoogleAds = () => {
  // useEffect(() => {
  // `window`가 정의된 후에 adsbygoogle 초기화
  //   if (typeof window !== 'undefined' && window.adsbygoogle) {
  //     if (document.querySelectorAll('.adsbygoogle').length === 0) {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     }
  //   }
  // }, []);

  const router = useRouter();
  const { user } = useGetUser();
  const isManager = user && isUserManager(user?.uid);

  const adAllowedPathNames = ['/test', '/blog'];

  return (
    <>
      {adAllowedPathNames.includes(router.pathname) && isManager && (
        <div
          className={cn(
            'fixed right-[50px] top-[calc(50%-100px)] h-[200px] w-[200px] -translate-y-[50%] max-[1024px]:hidden',
          )}
        >
          <ins
            className="adsbygoogle"
            style={{ display: 'inline-block', width: '200px', height: '200px' }}
            data-ad-client="ca-pub-9018600439614344"
            data-ad-slot="9004432130"
          ></ins>
        </div>
      )}
    </>
  );
};

const Background = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  const router = useRouter();

  /**
   * pathname이 바뀔 때마다 theme-color을 변경합니다.
   * https://stackoverflow.com/questions/32330305/update-theme-color-meta-without-page-refresh
   */
  useEffect(() => {
    if (!document) return;

    const changeColor = debounce(async (url: string) => {
      const pathname = new URL(url, HOME_URI).pathname;

      const color =
        (
          PAGE_MAPPING[pathname as keyof typeof PAGE_MAPPING] as {
            bgColor?: string;
          }
        )?.bgColor || '#ffffff';

      document.querySelector("meta[name='theme-color']")?.setAttribute('content', color);
      document
        .getElementById('background-container')
        ?.setAttribute('style', `background-color: ${color}`);

      if (isAppApproach) {
        // hex로 전달해줘야 함
        window.flutter_inappwebview.callHandler('ChangeBgColor', color);
      }
    }, 100);

    changeColor(router.pathname);
    router.events.on('routeChangeComplete', changeColor);

    return () => {
      router.events.off('routeChangeComplete', changeColor);
    };
  }, []);

  return (
    <div
      id="background-container"
      className={cn('relative', className)}
      style={{
        // 하위 fixed된 tag들을 relative하게 배치하기.
        // https://stackoverflow.com/questions/5209814/can-i-position-an-element-fixed-relative-to-parent
        transform: 'translateZ(0)',
      }}
    >
      {children}
    </div>
  );
};

const CenteredContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'mx-auto w-full max-w-[500px] overflow-hidden break-keep shadow-[0px_10px_30px_0px_#18181829]',
        className,
      )}
      style={{
        // 하위 fixed된 tag들을 relative하게 배치하기.
        // https://stackoverflow.com/questions/5209814/can-i-position-an-element-fixed-relative-to-parent
        transform: 'translateZ(0)',
      }}
    >
      {children}
    </div>
  );
};

export const scrollContainerRef = createRef<HTMLDivElement>();

/**
 * header 아래에 content를 담을 때 사용
 */
export const ScrollContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const router = useRouter();

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0 });
    }
  }, [router.pathname]);

  return (
    <main
      ref={scrollContainerRef}
      className={cn(
        'scrollbar-all-hide h-[var(--app-content-height)] overflow-y-auto overflow-x-hidden',
        className,
      )}
    >
      {children}
    </main>
  );
};

const GlobalScrollContainer = ({ children }: ComponentPropsWithoutRef<typeof ScrollContainer>) => {
  const { offNavbar } = useGetAppBarProps();

  return !offNavbar ? <ScrollContainer>{children}</ScrollContainer> : <>{children}</>;
};

export const globalBlockingScreenAtom = atom<boolean>(false);

const BlockingScreen = () => {
  return (
    <div className="fixed z-[10000] h-[100dvh] w-full bg-white">
      <div className="absolute-center flex flex-col items-center">
        <p className="text-new-Title3 whitespace-nowrap text-center">
          예약 가능한 학원을 찾고있어요!
          <br />
          잠시만 기다려주세요.
        </p>
        <Spacer className="h-32" />
        <Lottie
          path="/location-lottie.json"
          size={{
            width: 128,
            height: 128,
          }}
        />
      </div>
    </div>
  );
};

export const Layout = ({ children }: { children: React.ReactElement }) => {
  const globalBlockingScreen = useAtomValue(globalBlockingScreenAtom);

  return (
    <>
      <div id="game-container" />
      <div className="flex justify-center gap-[164px]">
        <PCWebToApp className="shrink-0" />
        <Background className="w-full shrink-0">
          <CenteredContainer className="flex h-[var(--app-height)] flex-col">
            <Toaster>
              <OverlayProvider>
                {/* Modal */}
                <GlobalModal />
                <GlobalReview />
                {/* Instant */}
                <GlobalLoading />

                {/* Floating */}
                {globalBlockingScreen && <BlockingScreen />}
                <div
                  id="portal"
                  // popover의 경우 z-index가 auto로 설정되어 있어 z-10을 추가해줌
                  className="[&>[data-radix-popper-content-wrapper]]:!z-[1]"
                />
                <GlobalAppBar className="shrink-0" />
                <GlobalScrollContainer>
                  {children}
                  {/* Scroll 영역에 포함되어야 함. */}
                  <GlobalBottomFloatingBar />
                </GlobalScrollContainer>
                <BottomNavbar className="h-[82px] shrink-0" />
              </OverlayProvider>
            </Toaster>
          </CenteredContainer>
          <PC제휴문의 className="absolute -right-[140px] bottom-[40px]" />
        </Background>
      </div>
    </>
  );
};
