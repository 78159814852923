import { QUERY_KEY } from '@db/constants';
import { useInfiniteQueryWithClient } from '@db/hooks';
import { QueryDocumentSnapshot } from 'firebase/firestore';

import { getBlogsInfinite } from '../calls';

export const useGetAllAboutDrivingBlogs = (selectedHashTag: string) => {
  const result = useInfiniteQueryWithClient({
    queryKey: [QUERY_KEY.INFINITE_BLOGS, selectedHashTag] as [string, string],
    queryFn: getBlogsInfinite,
    getNextPageParam: (querySnapshot) => querySnapshot.nextPageParam as QueryDocumentSnapshot<Blog>,
    initialPageParam: null,
  });

  return result;
};
