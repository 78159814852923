import { db } from 'firebase-config';
import { doc, setDoc } from 'firebase/firestore';
import { generateRandomString, randomNicknames } from 'shared-values';

import { getDeviceByUserAgent } from '@utils/Common';

interface UseCreateUserProps {
  id: string;
  phoneNum: string;
  email: string;
  notificationAgreed: boolean;
  realName?: string;
  birthYear?: string;
  provider: Provider;
  birthday?: string;
  name?: string;
}

export const createUser = async ({
  id,
  phoneNum,
  email,
  notificationAgreed,
  birthYear = '',
  realName,
  provider,
  birthday = '',
}: UseCreateUserProps) => {
  const myCode = generateRandomString(10);

  const device = getDeviceByUserAgent();

  const currentUserData: Omit<UserData, 'uid'> = {
    name: randomNicknames[Math.floor(Math.random() * randomNicknames.length)],
    phoneNum,
    email,
    provider,
    orders: [],
    device,
    code: myCode,
    friendsCode: [],
    isVerifiedByAcademy: false,
    couponChances: 0,
    coupons: [],
    bookmarks: [],
    togetherEvent: null,
    createdAt: new Date(),
    notificationAgreed: notificationAgreed,
    rouletteTickets: [],
    birthYear,
    birthday: birthday ?? '',
    realName: realName ?? '',
  };
  // 유저 만들기
  await setDoc(doc(db, 'User', id), currentUserData, { merge: true });

  return currentUserData;
};
