import { Icon } from 'design-system';
import Image from 'next/image';
import { 제휴문의URL } from 'shared-values';

export const companyInfo = {
  name: '(주)티지소사이어티',
  email: 'info@drivingteacher.co.kr',
  phoneNumber: '070-5088-0430',
  location: '서울시 마포구 백범로31길 21, 411호 (서울창업허브 별관)',
  registrationNumber: '624-87-02464',
} as const;

export const COMPANY_INFO_TEXT = `대표이사 : 오석준 사업자 등록번호 : ${companyInfo.registrationNumber}\n주소 : ${companyInfo.location}\n통신판매업 신고번호 : 2023-서울마포-0080\n이메일 : ${companyInfo.email}\n고객센터 : ${companyInfo.phoneNumber}`;

export const termsLinks = {
  service: {
    href: '/terms/service',
    label: '서비스 이용약관',
  },
  location: {
    href: '/terms/location',
    label: '위치기반 서비스 이용약관',
  },
  privacy: {
    href: '/terms/private',
    label: '개인정보 처리방침',
  },

  partnership: {
    href: 제휴문의URL,
    label: '입점 및 제휴 문의',
  },
} as const;

export const TGS_DISCLAIMER_TEXT =
  '(주)티지소사이어티는 통신판매중개자이며, 통신판매의 당사자가 아닙니다. 따라서 (주)티지소사이어티는 상품, 거래정보 및 거래에 대하여 책임을 지지 않습니다.';

export const iconLinks = [
  {
    href: 'https://info.drivingteacher.co.kr',
    icon: <Image src="/icon/web.png" alt="웹 아이콘" width={24} height={24} />,
    name: '운전선생 공식 소개 사이트',
    openNewWindow: true,
  },
  {
    href: 'https://blog.naver.com/drivingteacher',
    icon: <Icon icon="naver-blog-icon" />,
    name: '운전선생 공식 블로그',
    openNewWindow: true,
  },
  {
    href: 'https://www.instagram.com/drivingteacher_official?igsh=MXcxY3IwbXl0aXN6Mg==',
    icon: <Image src="/icon/instagram-icon.png" alt="인스타그램 아이콘" width={24} height={24} />,
    name: '인스타그램',
    openNewWindow: false,
  },
] as const;
