import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getRecommenders } from '../calls';

export const useGetRecommenders = (code: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.RECOMMENDER, code],
    queryFn: getRecommenders,
  });

  return result;
};
