export const getRandomRouletteReward = (rewards: RouletteReward[]): RouletteReward => {
  const totalProbability = rewards.reduce((sum, reward) => sum + reward.probability, 0);
  let randomNum = Math.random() * totalProbability;
  for (const reward of rewards) {
    randomNum -= reward.probability;
    if (randomNum <= 0) {
      return reward;
    }
  }
  // 모든 보상의 확률이 1 미만일 경우, 마지막 보상을 기본값으로 반환
  return rewards[rewards.length - 1];
};
