import { StartAndEndTime } from '../../../base-models';

export class LockedData extends StartAndEndTime {
  value: boolean;

  constructor({ start, end, value }: { start: string; end: string; value: boolean }) {
    super({ start, end });
    this.value = value;
  }

  get isLocked() {
    return this.value;
  }
}
