import { useEffect, useMemo, useState } from 'react';

import { atom, useAtom } from 'jotai';

import { isAppApproaching } from '@utils/Common';

import { Condition } from '../MarketingAgreementBottomSheet';
import { callbackWhenResume } from './use-focus-effect';

type NotificationState =
  | 'PermissionStatus.denied'
  | 'PermissionStatus.granted'
  | 'PermissionStatus.permanentlyDenied'
  | '';

const notificationStateAtom = atom<NotificationState>('');
const isAppApproach = isAppApproaching();

export const getNotificationState = async () => {
  if (!isAppApproach) return 'PermissionStatus.granted';

  /**
   * 앱에서 리스너가 설치되어 있지 않을 경우.
   */
  const result = (await window.flutter_inappwebview.callHandler(
    'GetNotificationState',
  )) as NotificationState;

  if (!result) return 'PermissionStatus.granted';

  return result;
};

/**
 * 모바일 디바이스의 알림 권한 상태를 가져오는 hook
 */
export const useGetNotificationState = () => {
  const [notificationState, setNotificationState] = useAtom(notificationStateAtom);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchNotificationState = async () => {
      setIsLoading(true);
      const state = await getNotificationState();
      setIsLoading(false);
      setNotificationState(state);
    };

    fetchNotificationState();
  }, []);

  const requestNotification = async () => {
    const request = (await window.flutter_inappwebview.callHandler(
      'RequestNotification',
    )) as typeof notificationState;

    setNotificationState(request);

    if (request.includes('permanentlyDenied')) {
      await window.flutter_inappwebview.callHandler('GoNotificationSetting');
    }
  };

  useEffect(
    () =>
      callbackWhenResume(async () => {
        const state = await getNotificationState();
        setNotificationState(state);
      }),
    [],
  );

  return { notificationState, isLoading, requestNotification };
};

export const useNotificationAgree = (): Condition => {
  const { notificationState, requestNotification, isLoading } = useGetNotificationState();
  const initialState = useMemo(() => notificationState === 'PermissionStatus.granted', [isLoading]);

  return {
    initialState,
    state: notificationState === 'PermissionStatus.granted',
    onClick: requestNotification,
    isLoading,
  };
};
