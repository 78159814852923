import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { EventRoom } from '../models';

const getEventRoomData = async (id: string) => {
  const docRef = doc(db, 'EventRoom', id);
  const docData = (await getDoc(docRef)).data();

  findMethodAndReplace(docData, 'toDate');

  return { ...docData, id: docRef.id } as EventRoomData;
};

export const getEventRoom = async ({ queryKey, queryClient }: QueryFn<[string, string]>) => {
  const [, roomId] = queryKey;

  const docData = await getEventRoomData(roomId);

  return new EventRoom({ ...docData, queryClient, queryKey });
};
