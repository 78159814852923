import { useEffect } from 'react';

import { useGetUser } from '@db/collections';
import { useRouter } from 'next/router';

import { LogEvent } from './LogEvent';

export let documentReferrer = '';

export const GoogleAnalytics = () => {
  const router = useRouter();
  const { user, isUserExist } = useGetUser();

  useEffect(() => {
    if (!isUserExist || !user) return;
    // 다음 속성을 보기
    // https://help.airbridge.io/en/references/s2s-event#send-in-app-events-body-params-4
    LogEvent.유입.$로그인(user);
  }, [isUserExist]);

  /**
   * next router 단위의 페이지 그룹을 content_group으로 설정합니다.
   */
  useEffect(() => {
    if (typeof window === 'undefined' || !window.gtag) return;

    // 전체 URL을 가져옵니다.
    const fullURL = document.location.href;

    // URL 객체를 생성합니다.
    const url = new URL(fullURL);

    // 도메인 부분을 추출합니다.
    const domain = url.origin;

    LogEvent.setData({ pathname: router.pathname });

    
    LogEvent.유입.pageView({
      send_to: 'G-HTBLW14T84',
    });

    documentReferrer = domain + router.pathname;
  }, [router.pathname]);

  return <></>;
};
