import * as React from 'react';

import { cva } from 'class-variance-authority';
import { cn } from 'tailwind-config';

import { NewBoxButton } from '../../NewBoxButton';

type NewBoxButtonStyles = Exclude<React.ComponentProps<typeof NewBoxButton>['styles'], null>;

export type DialogButtonColor = 'DTPurple-500' | 'gray-900' | 'gray-500' | 'DTRed-400';

const dialogButtonVariants = cva<{ color: Record<DialogButtonColor, string> }>(
  'text-new-Body1-bold flex justify-around',
  {
    variants: {
      color: {
        'DTPurple-500': 'text-new-DTPurple-500',
        'DTRed-400': 'text-new-DTRed-400',
        'gray-900': 'text-new-gray-900',
        'gray-500': 'text-new-gray-500',
      },
    },
    defaultVariants: {
      color: 'gray-900',
    },
  },
);

interface BoxButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: 'box';
  color?: NewBoxButtonStyles;
  text: string;
}

interface TextButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: 'text';
  color: DialogButtonColor;
  text: string;
}

type DialogButtonProps = BoxButtonType | TextButtonType;

export const DialogButton = ({ text, buttonType, color, ...rest }: DialogButtonProps) => {
  if (buttonType === 'text') {
    return (
      <button className={cn(dialogButtonVariants({ color: color }))} {...rest}>
        {text}
      </button>
    );
  }
  return (
    <NewBoxButton
      label={text}
      size='large'
      styles={color ?? 'filled-yellow'}
      {...rest}
      fill={true}
    />
  );
};
