import { useRef, useState } from 'react';

import axios from 'axios';
import { BACKEND_URL } from 'shared-values';

import { Input, Timer } from '@components';

import { ERROR_MESSAGE } from '@constants';
// import { Timer } from '@templates';
import { checkDuplicatePhoneNum } from '@utils/Auth';
import { getRandomCode } from '@utils/Common';

interface CertificatePhoneNumProps {
  isCertified: boolean;
  setIsCertified: (value: boolean) => void;
  onSuccess: (phoneNum: string) => void;
}

const DuplicateAvailablePhoneNum = ['01022518037'];

export const CertificatePhoneNum = ({
  isCertified,
  setIsCertified,
  onSuccess,
}: CertificatePhoneNumProps) => {
  const certificateNumRef = useRef<HTMLInputElement | null>(null);

  const [count, setCount] = useState(0);
  const [certificateNum, setCertificateNum] = useState('');
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [timerCount, setTimerCount] = useState(0);

  const [code, setCode] = useState<string>(getRandomCode());

  const onCheckCertificateNum = () => {
    if (certificateNum !== code) {
      setIsCertified(false);
    } else {
      setIsCertified(true);
      onSuccess(phoneNum);
    }
  };

  const onSendCertificationNum = async () => {
    setIsCertified(false);

    const newCode = getRandomCode();

    if (count > 4) {
      return;
    }

    if (certificateNum.length) {
      setCertificateNum('');
    }

    if (
      !DuplicateAvailablePhoneNum.includes(phoneNum) &&
      (await checkDuplicatePhoneNum(phoneNum))
    ) {
      alert(ERROR_MESSAGE.DUPLICATE_PHONENUM);
      return;
    }

    if (phoneNum.length) {
      await axios.get(`${BACKEND_URL}/sms?phoneNum=${phoneNum}&code=${newCode}`);

      setTimerCount(300);
      setCount((prevCount) => prevCount + 1);
      certificateNumRef.current?.focus();
    }
    setCode(newCode);
    alert('인증번호가 발송되었습니다.');
  };

  return (
    <>
      <Input
        label={
          <div className='flex w-full flex-row items-center justify-between'>
            <div className='text-Section-Title text-gray-900'>휴대폰 번호</div>
            <div className='text-B4 text-gray-400'>{count}/5</div>
          </div>
        }
        placeholder='- 없이 숫자만 입력해주세요.'
        type='number'
        value={phoneNum}
        hasDeleteButton={
          phoneNum.length
            ? {
                onClick: () => setPhoneNum(''),
              }
            : undefined
        }
        buttonProps={{
          label: count ? '다시 인증하기' : '인증번호 받기',
          onClick: onSendCertificationNum,
          disabled: !phoneNum.match(/^01([0|1|6|7|8|9])([0-9]{4})([0-9]{4})$/),
        }}
        onChange={(e) => {
          if (isCertified) {
            setIsCertified(false);
            setCertificateNum('');
            setTimerCount(0);
            setPhoneNum(e.target.value);
          } else {
            setPhoneNum(e.target.value);
          }
        }}
        onInput={(e) => {
          if (e.currentTarget.value.length > 11) {
            e.currentTarget.value = e.currentTarget.value.slice(0, 11);
          }
        }}
        message={
          count && phoneNum.match(/^01([0|1|6|7|8|9])([0-9]{4})([0-9]{4})$/) ? (
            <>
              <div className='text-D2 text-gray-600'>인증번호를 발송했습니다.</div>
              <div className='text-D2 mb-20 text-gray-600'>
                인증번호가 오지 않는다면, 입력하신 정보가 정확한 지 확인해주세요.
              </div>
            </>
          ) : (
            <div />
          )
        }
      />
      <div className='h-30 w-full' />
      <Input
        label={<div className='text-Section-Title text-gray-900'>인증번호</div>}
        placeholder='숫자 6자리 입력'
        inputRef={certificateNumRef}
        type='number'
        value={certificateNum}
        hasDeleteButton={
          certificateNum.length
            ? {
                onClick: () => setCertificateNum(''),
              }
            : undefined
        }
        onChange={(e) => setCertificateNum(e.target.value)}
        onInput={(e) => {
          if (e.currentTarget.value.length > 6) {
            e.currentTarget.value = e.currentTarget.value.slice(0, 6);
          }
        }}
        buttonProps={{
          label: isCertified ? '인증완료' : '인증하기',
          onClick: onCheckCertificateNum,
          disabled: isCertified || certificateNum.length < 6 || !timerCount || !count,
        }}
      />
      {timerCount > 0 && (
        <Timer initialSeconds={timerCount} className='text-Body5 ml-3 mt-5 text-gray-600' />
      )}
    </>
  );
};
