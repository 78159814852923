import { QueryModel } from '@db/query-models';

export class AcademyGeoJson extends QueryModel {
  geoJson: string[];
  id: string;

  constructor({ geoJson, queryClient, queryKey, id }: DataModel<AcademyGeoJsonData>) {
    super({
      queryClient,
      queryKey,
      instanceConstructor: AcademyGeoJson,
      className: 'AcademyGeoJson',
    });

    this.id = id;
    this.geoJson = geoJson;
    this._queryClient = queryClient;
  }
}
