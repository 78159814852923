'use client';

import * as React from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { cn } from 'tailwind-config';

import { NewIcon, NewIconType } from '../NewIcon';

const DropdownMenuRoot = DropdownMenuPrimitive.Root;

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 8, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal
    container={typeof window !== 'undefined' ? document.getElementById('portal') : null}
  >
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 flex flex-col gap-16 overflow-hidden rounded-md border bg-white p-20 pl-20 shadow-[0px_4px_16px_0px_#0000001A]',
        className,
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

export interface DropdownMenuItemViewProps {
  label: string;
  description?: string;
  icon?: NewIconType;
  className?: string;
}

export const DropdownMenuItemView = ({
  label,
  description,
  icon,
  inset,
  className,
}: DropdownMenuItemViewProps & { inset?: boolean }) => {
  return (
    <div
      className={cn(
        'relative flex cursor-default select-none items-center',
        inset && 'pl-8',
        className,
      )}
    >
      {icon && <NewIcon icon={icon} className="mr-8 h-20 w-20" />}
      <span className="text-new-Body1-bold mr-4">{label}</span>
      {description && (
        <span className="text-new-Body2-medium text-new-gray-500">{description}</span>
      )}
    </div>
  );
};

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
  } & DropdownMenuItemViewProps
>(({ className, inset, icon, ...props }, ref) => {
  return (
    <DropdownMenuPrimitive.Item ref={ref} {...props}>
      <DropdownMenuItemView
        label={props.label}
        description={props.description}
        icon={icon}
        inset={inset}
        className={className}
      />
    </DropdownMenuPrimitive.Item>
  );
});
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const DropdownMenu = ({
  trigger,
  children,
  ...props
}: {
  trigger: React.ReactNode;
  children: React.ReactNode;
} & React.ComponentProps<typeof DropdownMenuContent>) => {
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger>{trigger}</DropdownMenuTrigger>
      <DropdownMenuContent side="bottom" {...props}>
        {children}
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

export {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
};
