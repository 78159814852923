import Image from 'next/image';
import Link from 'next/link';

import { Timer } from '@components/Timer';

export const GcooBottomFloatingBar = ({ timeLimit }: { timeLimit: Date }) => {
  return (
    <>
      <Link href='/event/gcoo' className='relative'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-8'>
            <Image src='/events/gcoo/gcoo-event-chip.png' width={67} height={26} alt='event-chip' />
            <div className='text-Body1 text-white'>지쿠 4천원 쿠폰 무료 제공</div>
          </div>
          <Timer date={timeLimit} className='text-Body1 text-DTRed-400' />
        </div>
      </Link>
    </>
  );
};
