export const getDatesByStartAndEnd = (startDate: Date, stopDate: Date) => {
  const dateArray: Date[] = [];
  const currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
};
