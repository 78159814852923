import { atom } from 'jotai';
import superjson from 'superjson';

export const atomWithSuperJsonLocalStorage = <T>(
  key: string,
  initialValue: T,
) => {
  const baseAtom = atom<T>(initialValue);

  baseAtom.onMount = (setValue) => {
    (async () => {
      try {
        const value = localStorage.getItem(key);

        const parsedValue = value
          ? await superjson.parse<T>(value)
          : initialValue;

        setValue(parsedValue);
      } catch (error) {
        console.error(
          `Error loading item with key "${key}" from localStorage:`,
          error,
        );
        setValue(initialValue);
      }
    })();
  };

  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update: T | ((prev: T) => T)) => {
      try {
        const nextValue =
          typeof update === 'function'
            ? (update as (prev: T) => T)(get(baseAtom))
            : update;

        set(baseAtom, nextValue);

        const stringified = superjson.stringify(nextValue);

        localStorage.setItem(key, stringified);
      } catch (error) {
        console.error(
          `Error saving item with key "${key}" to localStorage:`,
          error,
        );
      }
    },
  );

  return derivedAtom;
};
