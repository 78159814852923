import axios from 'axios';
import SuperJSON from 'superjson';

import { Academy } from '../models';

export const getAcademiesData = async () => {
  const { data, status } = await axios.get('https://getallacademies-xupmv5q2rq-du.a.run.app/');

  return data.map((v: string) => SuperJSON.parse(v)) as AcademyData[];
};

export const getAcademies = async () => {
  const docData = await getAcademiesData();

  return docData.map((data) => new Academy({ ...data }));
};
