/**
 *
 * @param date : 날짜입니다.
 * @param formatString : 포맷 문자열입니다. YYYY, YY, MM, DD, HH, MM, SS, -, ., 등의 문자열을 포함할 수 있습니다.
 * @returns
 */
export const formatDate = (inputDate: Date | string | null, formatString: string) => {
  if (!inputDate) return '';
  let date;
  if (typeof inputDate === 'string') {
    date = new Date(inputDate) as Date;
  } else {
    date = inputDate;
  }
  const YY = date.getFullYear();
  const MM = date.getMonth() + 1;
  const DD = date.getDate();
  const HH = date.getHours();
  const Minutes = date.getMinutes();
  const SS = date.getSeconds();

  let result = formatString;

  if (formatString.includes('YYYY') || formatString.includes('yyyy')) {
    result = result.replace(/YYYY|yyyy/g, YY.toString());
  }
  if (formatString.includes('YY') || formatString.includes('yy')) {
    result = result.replace(/YY|yy/g, YY.toString().slice(2, 4));
  }
  if (formatString.includes('MM') || formatString.includes('mm')) {
    // MM은 2가지의 경우가 있으니 g를 제외하고 사용
    result = result.replace(/MM|mm/, MM.toString().padStart(2, '0'));
  }
  if (formatString.includes('DD') || formatString.includes('dd')) {
    result = result.replace(/DD|dd/g, DD.toString().padStart(2, '0'));
  }
  if (formatString.includes('HH') || formatString.includes('hh')) {
    result = result.replace(/HH|hh/g, HH.toString().padStart(2, '0'));
  }
  if (formatString.includes('MM') || formatString.includes('mm')) {
    result = result.replace(/MM|mm/g, Minutes.toString().padStart(2, '0'));
  }
  if (formatString.includes('SS') || formatString.includes('ss')) {
    result = result.replace(/SS|ss/g, SS.toString().padStart(2, '0'));
  }

  return result;
};
