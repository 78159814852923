import { ReactNode } from 'react';

import { BOXBUTTON_COLOR, BoxButton } from '../../Button';

interface FooterProps {
  defaultButton: {
    text: ReactNode;
    color: BOXBUTTON_COLOR.BLACK | BOXBUTTON_COLOR.DTYellow | BOXBUTTON_COLOR.WHITE;
    onClick: any;
  };
  extraButton?: {
    text: ReactNode;
    color: BOXBUTTON_COLOR.BLACK | BOXBUTTON_COLOR.DTYellow | BOXBUTTON_COLOR.WHITE;
    onClick: any;
  };
  isColumn?: boolean;
}

export const Footer = ({ defaultButton, extraButton, isColumn }: FooterProps) => {
  return (
    <div
      className='mt-12 flex w-full gap-[14px] pb-24'
      style={{ flexDirection: isColumn ? 'column' : 'row' }}>
      <BoxButton
        text={defaultButton.text}
        onClick={defaultButton.onClick}
        color={defaultButton.color}
      />
      {extraButton && (
        <BoxButton
          text={extraButton.text}
          onClick={extraButton.onClick}
          color={extraButton.color}
        />
      )}
    </div>
  );
};
