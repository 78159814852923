import { CouponCode } from '@db/collections/CouponCode';
import { QueryModel } from '@db/query-models';
import { isEmpty } from 'lodash-es';

export class UnivEventCertification extends QueryModel {
  id: string;
  email: string;
  univName: string;
  isAffiliated: boolean;
  couponCode: CouponCode;
  univReferralUid: string;

  constructor({
    id,
    email,
    univName,
    isAffiliated,
    queryClient,
    queryKey,
    couponCode,
    univReferralUid,
  }: DataModel<UnivEventCertificationData>) {
    super({
      queryClient,
      queryKey,
      instanceConstructor: UnivEventCertification,
      className: 'UnivEventCertification',
    });

    this.id = id;
    this.email = email;
    this.univName = univName;
    this.isAffiliated = isAffiliated;
    this.couponCode = new CouponCode(couponCode);
    this.univReferralUid = univReferralUid ?? '';
  }

  get canParticipateInReferralEvent() {
    return !this.isAffiliated || !isEmpty(this.univReferralUid);
  }

  get canReceiveAffiliationCoupon() {
    return this.isAffiliated || !isEmpty(this.univReferralUid);
  }
}
