export const compareStartAndEndTime = (time1: StartAndEndTimeData, time2: StartAndEndTimeData) => {
  const [time1StartHour, time1StartMinute] = time1.start.split(':');
  const [time1EndHour, time1EndMinute] = time1.end.split(':');
  const [time2StartHour, time2StartMinute] = time2.start.split(':');
  const [time2EndHour, time2EndMinute] = time2.end.split(':');

  const time1StartTotal = Number(time1StartHour) * 60 + Number(time1StartMinute);
  const time1EndTotal = Number(time1EndHour) * 60 + Number(time1EndMinute);
  const time2StartTotal = Number(time2StartHour) * 60 + Number(time2StartMinute);
  const time2EndTotal = Number(time2EndHour) * 60 + Number(time2EndMinute);

  if (time1StartTotal === time2StartTotal && time1EndTotal === time2EndTotal) {
    return true;
  } else {
    return false;
  }
};
