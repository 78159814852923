import { Divider } from 'design-system';

export const PrivateTerm = () => {
  return (
    <>
      <h1 className="text-new-Title2 p-16">개인정보처리방침</h1>
      <Divider className="bg-new-gray-200 h-8" />
      <div className="text-Body2 overflow-auto bg-white px-20 py-16">
        <div className="mt-16">
          (주)티지소사이어티(이하 '회사')는 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및
          정보보호"에 관한 법률을 준수하고 있습니다. 회사는 개인정보취급방침을 통하여 고객님께서
          제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한
          조치가 취해지고 있는지 알려드립니다. 회사는 개인정보취급방침을 개정하는 경우 어플리케이션
          공지사항(또는 개별공지)을 통하여 공지할 것입니다. 본 방침에 대해 문의가 있는 경우 서비스
          홈페이지 및 어플리케이션 내 고객문의 채널을 통해 의견을 제공하실 수 있습니다.
        </div>
        <div className="mt-16">이 개인정보처리방침은 2022년 11월 14일부터 적용됩니다.</div>
        <div className="text-T3 mt-16 font-bold">1. 수집하는 개인정보 및 이용에 관한 안내</div>
        <div className="mt-16">
          1. 회사는 서비스를 제공하기 위해서 다음과 같이 최소한의 개인정보만을 수집하고 있습니다.
        </div>
        <div className="mt-16">
          2. 회사는 사용자의 회원가입의사 확인, 본인확인, 개인식별, 가입횟수 제한, 회원 탈퇴 의사의
          확인 등을 목적으로 회원가입 시 이름, 이메일, 비밀번호, 휴대폰 번호, 닉네임을 필수 항목으로
          수집 및 처리합니다.
        </div>
        <div className="mt-16">
          3. 회사는 사용자의 본 서비스 이용과정에서 서비스 이용기록, OS 종류 및 버전, 접속로그,
          단말기 정보 등 자동으로 생성된 정보를 수집할 수 있으며, 회사는 해당 정보를 유저 분석 및
          서비스 개선 목적으로 이용할 수 있습니다.
        </div>
        <div className="mt-16">
          4. 회사는 보다 나은 서비스를 제공하기 위한 서비스 상담 목적으로 사용자의 문의를 수신하는
          과정에서 고객문의 사항의 파악 및 회신을 위하여 이메일 주소 등 최소한의 개인정보를
          수집합니다. 또한 고객은 보다 정확한 오류 진단을 받기 위해 서비스 이용에서 발생한 오류
          데이터를 본인에게 전송할 수 있습니다. 이는 필수로 수집하는 사항은 아니며, 회사는 아래
          명시한 목적 이외로는 해당 개인정보를 이용하지 않습니다.
        </div>
        <div className="text-T3 mt-16 font-bold">2. 개인정보의 수집 방법</div>
        <div className="mt-16">
          1. 회사는 원활한 서비스 제공을 위해 다음과 같은 방법으로 본 방침 제1조에서 명시한
          개인정보를 수집합니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;- 모바일 어플리케이션 내 회원가입 페이지를 통한 회원가입 시 회원이
          직접 입력
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;- 서비스 설치 및 사용 과정에서 수집, 생성 정보 수집 툴(쿠키를
          포함)을 통한 수집
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;- 사용 중 회원의 자발적 제공을 통한 수집 등
        </div>
        <div className="text-T3 mt-16 font-bold">3. 개인정보의 보유 및 이용 기간</div>
        <div className="mt-16">
          1. 회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 사용자의 개인정보를 지체없이
          파기합니다. 다만, 회사는 회원이 탈퇴하거나 회원과의 이용계약을 해지하는 경우 권리남용방지,
          악용방지, 각종 분쟁 및 수사협조 의뢰에 대비하기 위하여 이용계약 해지일로부터 1년 동안
          개인정보를 보존합니다.
        </div>
        <div className="mt-16">
          2. 제1항에도 불구하고 「통신비밀보호법」 등 관련 법령에 의하여 보존할 필요가 있는 경우에는
          그 기간 동안 보존합니다. 이 경우, 회사는 보관하는 정보를 그 보관 목적으로만 이용합니다.
        </div>
        <div className="mt-16">
          3. 기타 법률에서 규정한 의무를 준수하기 위해 처리한 개인정보 이력은 그 책임을 입증하기
          위한 기간 동안 보관할 수 있습니다.
        </div>
        <div className="text-T3 mt-16 font-bold">4. 개인정보의 보유 및 이용 기간</div>
        <div className="mb-16 mt-16">
          회사는 서비스 향상을 위해 관계법령에 따라 사용자의 동의를 얻거나 관련 사항을 공개 또는
          고지 후 회원의 개인정보를 외부에 위탁하여 처리하고 있습니다. 현재, 사용자의 개인정보 처리
          수탁자와 그 업무의 내용은 아래와 같습니다.
        </div>
        <tr>
          <th className="border-Grey border-1 p-5">Google LLC</th>
          <th className="border-Grey border-1 p-5">계정시스템 이용 및 유저 분석</th>
        </tr>

        <div className="mt-16">
          서비스 분석 및 통계를 위해 이용하는 구글 애널리틱스(GA)에서는 사용자의 동의를 얻어 수집한
          개인정보, 생성된 설치정보, 개인식별불가정보 및 통계정보를 수집하고 분석/저장합니다.
        </div>
        <div className="text-T3 mt-16 font-bold">5. 개인정보의 제3자 제공</div>
        <div className="mt-16">
          1. 회사는 사용자의 개인정보를 제1조에서 고지한 범위내에서 사용하며, 동 범위를 초과하여
          이용하거나 타인 또는 타기업·기관에 제공하지 않습니다. 다만 다음 각호의 경우에는 예외로
          합니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.1 수사목적으로 관계법률에서 정한 절차와 방법에 따라 수사기관이
          개인정보 제공을 요구하는 경우
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.2 통계작성, 학술연구나 시장조사 등을 위하여 특정 개인을 식별할
          수 없는 형태로 광고주, 협력사나 연구단체 등에 제공하는 경우
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.3 기타 관계법률의 규정에 따른 요청이 있는 경우
        </div>
        <div className="mt-16">
          2. 그 밖에 개인정보의 제3자 제공이 필요한 경우에는 수집 및 이용목적과 항목, 보유기간 등을
          고지하고 사용자의 동의를 얻어 제3자에게 개인정보를 제공할 수 있습니다.
        </div>
        <div className="text-T3 mt-16 font-bold">6. 개인정보의 파기절차 및 방법</div>
        <div className="mt-16">
          회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이
          파기합니다. 구체적인 파기절차와 파기방법은 다음과 같습니다.
        </div>
        <div className="mt-16">1. 파기절차</div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.1 회사가 수집한 개인정보는 수집 목적이 달성된 후 본 약관 및 기타
          관련 법령에 정한 기간동안 저장된 후 파기합니다. 동 개인정보는 법률에 의한 경우가
          아니고서는 동의 받은 보유 목적 이외의 다른 목적으로 이용하지 않습니다.
        </div>
        <div className="mt-16">2. 파기방법</div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;2.1 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
          파기합니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;2.1 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
          기술적 방법을 사용하여 삭제합니다.
        </div>
        <div className="text-T3 mt-16 font-bold">7. 개인정보 유효기간제의 적용</div>
        <div className="mt-16">
          1. 회사는 정보통신망법에 따라 1년(이하 “개인정보 유효기간”) 동안 서비스에 로그인한 기록이
          없는 경우, 개인정보의 안전한 보호를 위해 정보통신망법에 따라 사용자에게 사전 통지하고
          개인정보 유효기간 경과 후 개인정보를 즉시 파기하거나 다른 사용자들의 개인정보와 별도로
          분리하여 저장·관리합니다.
        </div>
        <div className="mt-16">
          2. 전항의 경우 회사는 개인정보 유효기간이 도래하기 30일 전까지 이메일, 문자메시지,
          휴대전화 등 회원이 입력한 연락수단을 통하여 사전 통지합니다. 다만 개인정보 유효기간이
          도래한 회원의 경우에도 회원이 별도 유효기간 갱신의 요청 등 개별 동의가 있는 경우 또는 타
          법령에서 별도로 보존 기간을 정하는 경우에는 관련 법령이 정한 기간 동안 보존 후 조치됩니다.
        </div>
        <div className="text-T3 mt-16 font-bold">8. 개인정보 자동수집장치의 운용 및 선택권</div>
        <div className="mt-16">
          1. 회사는 서비스 웹사이트에서 쿠키를 운용합니다. 쿠키는 사용 중인 브라우저를 식별하여,
          이용 형태 분석 등 사용자에게 유용하고 편리한 서비스를 제공하는 데 사용됩니다.
        </div>
        <div className="mt-16">
          2. 사용자는 쿠키 설치 및 사용에 대한 선택권이 있습니다. 웹 브라우저의 옵션 설정을 통하여
          모든 쿠키의 저장을 허용하거나, 쿠키의 저장 여부를 매번 확인하거나, 모든 쿠키의 저장을
          거부할 수도 있습니다. 다만 쿠키의 저장을 거부할 경우, 일부 서비스의 이용에 제한이 생길 수
          있습니다.
        </div>
        <div className="mt-16">3. Google Analytics 및 맞춤형 광고에 대한 안내</div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;3.1 회사는 고객에게 더 나은 서비스를 제공하기 위한 목적으로
          Google, Inc.(이하 ‘Google’)이 제공하는 웹 분석 서비스인 Google Analytics를 사용하여
          고객들이 회사의 서비스를 어떻게 이용하는지 분석 및 평가하고 고객의 수요를 파악함으로써
          서비스와 제품을 개선하고 맞춤화하여 효율적인 서비스를 제공하고 있습니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;3.2 Google Analytics는 모바일 또는 데스크탑 디바이스에 저장된
          텍스트 파일인 ‘쿠키’를 사용하여 이용자의 서비스 이용방식을 분석합니다. Google의 외부
          분석툴을 통한 분석 및 맞춤형 광고 제공 서비스를 거부하시는 경우google.com/dlpage/gaoptout
          에서 현재 고객의 웹 브라우저에 대한 부가 기능을 다운로드 및 설치하여 Google의 정보 처리를
          거부할 수 있습니다. Google의 정보 처리에 관한 보다 자세한 내용은
          www.google.com/analytics/learn/privacy.html을 참고하시기 바랍니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;3.3 Google은 쿠키 정보의 분석을 위하여 개인식별 가능성이 없는
          ‘쿠키’ 정보를 미국 내 Google 서버로 전송합니다. Google의 정보 처리에 관한 보다 자세한
          내용은google.com/analytics/learn/privacy.html을 참고하시기 바랍니다.
        </div>
        <div className="text-T3 mt-16 font-bold">9. 개인정보의 기술적/관리적 보호 대책</div>
        <div className="mt-16">
          회사는 사용자들의 개인정보를 처리하면서 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지
          않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 마련하고 있습니다.
        </div>
        <div className="mt-16">1. 기술적 보호조치</div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.1 회사는 해킹이나 컴퓨터 바이러스 등에 의해 사용자의 개인정보가
          유출되거나 훼손되는 것을 막기 위해 암호화 통신 등을 통하여 네트워크상에서 개인정보를
          안전하게 전송할 수 있도록 하고 있습니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.2 방화벽을 이용하여 외부로부터의 무단 접근을 방지하고 있으며,
          기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고
          있습니다.
        </div>
        <div className="mt-16">2. 관리적 보호조치</div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;2.1 회사가 개인정보관련 처리를 한정하여 담당하며
          개인정보처리방침의 준수를 항상 명심하고 있습니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;2.2 회사는 본인의 귀책사유 없이 발생하는 사용자 개인의 실수 및
          제3자로 인해 발생하는 개인정보관련 문제에 대해서는 책임을 지지 않습니다.
        </div>
        <div className="mt-16">3. 개인정보 보호책임자</div>
        <div className="mt-16">
          회사는 개인 정보를 안전하게 이용하여 최상의 서비스를 제공하기 위하여 최선을 다하고
          있습니다. 그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의
          위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 사용자 및 제3자가 작성한
          게시물에 의한 각종 분쟁에 관해서는 일체 책임을 지지 않습니다.
        </div>
        <div className="mb-16 mt-16">
          개인정보 보호책임자는 다음과 같으며, 개인정보 관련 문의사항에 신속하고 성실하게
          답변해드리고 있습니다.
        </div>
        <tr>
          <th className="border-Grey border-1 p-5">이름</th>
          <th className="border-Grey border-1 p-5">(주) 티지소사이어티</th>
        </tr>
        <tr>
          <th className="border-Grey border-1 p-5">이메일</th>
          <th className="border-Grey border-1 p-5">info@drivingteacher.co.kr</th>
        </tr>
        <tr>
          <th className="border-Grey border-1 p-5">전화</th>
          <th className="border-Grey border-1 p-5">070-5088-0430</th>
        </tr>
        <div className="mt-16">
          기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;- 개인정보침해신고센터 (privacy.kisa.or.kr / 국번 없이 118)
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;- 대검찰청 사이버수사과 (spo.go.kr / 국번 없이 1301)
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;- 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번 없이 182)
        </div>
        <div className="text-T3 mt-16 font-bold">10. 고지의 의무</div>
        <div className="mt-16">
          개인정보 처리방침을 변경하는 경우 회사는 홈페이지의 공지사항란 또는 이메일로 지체 없이
          알리고, 이용자가 언제든지 변경된 사항을 쉽게 알아볼 수 있도록 조치하겠습니다.
        </div>
        <div className="mt-16">- 개인정보처리방침 버전번호 : 1.0</div>
        <div className="mt-16">- 개인정보처리방침 공지일자 : 2022년 11월 14일</div>
        <div className="mt-16">- 개인정보처리방침 시행일자 : 2022년 11월 14일</div>
      </div>
    </>
  );
};
