import { DocumentReference } from 'firebase/firestore';

const convertDateToKST = (date: Date) => {
  const utc = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  return new Date(utc + KR_TIME_DIFF);
};

export const findMethodAndReplace = (obj: any, methodName: string) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // firebase DocumentReference는 loop가 일어나서 제외
      if (obj[key] instanceof DocumentReference) {
        continue;
      }
      if (typeof obj[key][methodName] === 'function') {
        let result = obj[key][methodName]();

        // 서버와 클라이언트의 시간차이를 보정하기 위해 KST로 변환
        if (result && result instanceof Date) {
          result = convertDateToKST(result);
        }
        obj[key] = result;
      }
      findMethodAndReplace(obj[key], methodName);
    }
  }
  return obj;
};
