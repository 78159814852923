import { auth } from 'firebase-config';
import { signInWithCustomToken } from 'firebase/auth';

export const signInByIdToken = async (idToken: string) => {
  if (!idToken) throw new Error('No Firebase id Token');

  const result = await signInWithCustomToken(auth, idToken);

  return result;
};
