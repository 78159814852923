import { DetailedHTMLProps, LegacyRef } from 'react';

import classNames from 'classnames';
import { NewIcon } from 'design-system';

type buttonType = DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface InputProps
  extends DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  buttonProps?: buttonType & {
    label: string | React.ReactNode;
  };
  inputRef?: LegacyRef<HTMLInputElement> | null;
  hasDeleteButton?: {
    onClick: () => void;
  };
  label?: string | React.ReactNode;
  message?: string | React.ReactNode;
  hasError?: boolean;
}

export const Input = ({
  label,
  className,
  inputRef,
  value,
  hasDeleteButton,
  buttonProps,
  message,
  hasError,
  ...inputProps
}: InputProps) => {
  const Button = () => {
    return (
      <button
        className={classNames(
          'h-30 border-1 mb-6 flex items-center rounded-[100px] border-gray-400 px-10',
        )}
        {...buttonProps}
      >
        <span
          className={classNames(
            'text-D2 flex w-full whitespace-nowrap',
            buttonProps?.disabled ? 'text-gray-400' : 'text-black',
          )}
        >
          {buttonProps?.label}
        </span>
      </button>
    );
  };

  return (
    <div>
      {label && typeof label === 'string' ? (
        <div className="text-T5 mb-7 text-gray-600">{label}</div>
      ) : (
        label
      )}
      <div
        className={classNames(
          'flex w-full flex-row items-center border-b-2 border-b-gray-400',
          hasError && 'border-b-red',
          value && typeof value === 'string' && value.length > 0 && 'border-b-black',
        )}
      >
        <input
          className={classNames(
            'text-Body1 pr-13 placeholder:text-B1 w-full bg-white py-9 placeholder-gray-400 outline-none',
            className,
          )}
          value={value}
          ref={inputRef}
          autoCapitalize="none"
          autoCorrect="off"
          {...inputProps}
        />
        {hasDeleteButton && (
          <div
            className={classNames('mr-8 cursor-pointer', buttonProps && 'mb-6')}
            onClick={hasDeleteButton.onClick}
          >
            <NewIcon icon="x-outlined" size={16} className="fill-new-gray-900" />
          </div>
        )}
        {buttonProps && <Button />}
      </div>
      {message && <div className="mt-8">{message}</div>}
    </div>
  );
};
