export const QUERY_KEY = {
  ADDRESS: 'Address',
  MAP_ADDRESS: 'MapAddress',
  SEARCH: 'Search',
  ORDER: 'Order',
  DRIVING_HISTORY: 'DrivingHistory',
  VISIT_TRAINING_ORDERS: 'VisitTrainingOrders',
  COUPON_CODE: 'CouponCode',
  FIRST_COME_COUPON: 'FirstComeCoupon',
  REQUEST_ORDERS: 'RequestOrders',
  REFUNDED_ORDERS: 'RefundedOrders',
  TIMETABLE_ORDERS: 'TimetableOrders',
  TODAY_ORDERS: 'TodayOrders',
  SUCCESS_ORDERS: 'SuccessOrders',
  // ACADEMY: 'Academy',
  ACADEMY_GEOJSON: 'AcademyGeoJson',
  ALL_ACADEMY_GEOJSON: 'AllAcademyGeoJson',
  ACADEMY_GEOJSONS: 'AcademyGeoJsons',
  RECOMMEND_ACADEMIES: 'RecommendAcademies',
  ACADEMIES: 'Academies',
  INDEXES_ACADEMIES: 'IndexesAcademies',
  ALL_ACADEMIES: 'AllAcademies',
  INFINITE_ACADEMIES: 'InfiniteAcademies',
  ACADEMY_COUPONS: 'AcademyCoupons',
  BOOKABLE_ACADEMIES: 'BookableAcademies',
  SEARCH_ACADEMIES: 'SearchAcademies',
  ACADEMIES_BOOKEABLE: 'AcademiesBookable',
  FlAG: 'Flag',
  BLOG: 'Blog',
  BLOGS: 'Blogs',
  RELATED_BLOGS: 'RelatedBlogs',
  RECOMMENDED_BLOGS: 'RecommendedBlogs',
  INFINITE_BLOGS: 'InfiniteBlogs',
  TRENDY_BLOGS: 'TrendyBlogs',
  TIMETABLE: 'Timetable',
  TIMETABLES: 'Timetables',
  VERIFICATION: 'Verification',
  USER: 'User',
  CURRENT_USER: 'CurrentUser',
  RECOMMENDER: 'Recommender',
  EXERCISES: 'Exercises',
  EXAM_EXERCISES: 'ExamExercises',
  EXERCISES_COUNT: 'ExercisesCount',
  EXERCISE: 'Exercise',
  BOOKMARK_EXERCISE: 'BookmarkExercise',
  INDEXES_EXERCISE: 'IndexesExercise',
  MAIN_EXERCISE: 'MainExercise',
  RESULT_USER_TEST: 'ResultUserTest',
  HISTORY_USER_TEST: 'HistoryUserTest',
  EVENT_ROOM: 'EventRoom',
  USER_TEST: 'UserTest',
  USER_TEST_EXERCISE_LIST: 'UserTestExerciseList',
  TAXINVOICE: 'TaxInvoice',
  ACADEMY: 'Academy',
  ACADEMY_DETAIL: 'AcademyDetail',
  SALES_ACADEMY: 'SalesAcademy',
  SALES_ACADEMIES: 'SalesAcademies',
  COUPON_CODES: 'CouponCodes',
  REVIEW: 'Review',
  UNIV_EVENT_CERTIFICATION: 'UnivEventCertification',
  EVENT_PARTICIPANT_COUNT: 'EventParticipantCount',
  ACADEMY_RESERVATION_REQUEST: 'AcademyReservationRequest',
  TRAINING_GEOJSON: 'TrainingGeoJson',
};
