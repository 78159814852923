import { useState } from 'react';

import { Carousel, CarouselContent, CarouselDots, CarouselItem, Spacer } from 'design-system';
import { db } from 'firebase-config';
import { doc, setDoc } from 'firebase/firestore';
import { isEmpty } from 'lodash-es';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { KAKAO_REDIRECT_URI } from 'shared-values';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';

import { Loading } from '@components';

import { useWindowSize } from '@hooks';
import { isAppApproaching } from '@utils/Common';

import { useProcessLoginCallback } from './actions/use-process-login-callback/use-process-login-callback';
import { Certification } from './components';

const decodeData = (data: Record<string, string>) => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, decodeURIComponent(value)]),
  );
};

export const saveLoginState = async (loginState: LoginState, id: string) => {
  await setDoc(doc(db, 'LoginState', id), decodeData(loginState as Record<string, string>));
};
export const loginStateId = uuidv4();

const isAppApproach = isAppApproaching();

export const useLoginStore = create<{
  needMoreInfo: boolean;
  setNeedMoreInfo: (needMoreInfo: boolean) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  isLoginProcessed: boolean;
  setIsLoginProcessed: (isLoginProcessed: boolean) => void;
}>((set) => ({
  needMoreInfo: false,
  setNeedMoreInfo: (needMoreInfo: boolean) => set({ needMoreInfo }),
  isLoading: false,
  setIsLoading: (isLoading: boolean) => set({ isLoading }),
  isLoginProcessed: false,
  setIsLoginProcessed: (isLoginProcessed: boolean) => set({ isLoginProcessed }),
}));

const LOGIN_CAROUSEL_ITEMS = [
  {
    title: '운전선생에서\n전국 운전학원 예약까지',
    img: { src: '/login/login_greeting_3x.png', alt: '인사하는 자동차' },
  },
  {
    title: '방문, 상담 없이\n학원 등록 가능',
    img: { src: '/need-login.png', alt: '핸드폰을 들고 있는 손' },
  },
  {
    title: '할인 혜택으로\n저렴한 가격으로',
    img: { src: '/banners/new_lowest.png', alt: '돈주머니' },
  },
];

const LoginPageCarousel = () => {
  return (
    <Carousel className="max-w-[var(--app-width)]">
      <CarouselContent>
        {LOGIN_CAROUSEL_ITEMS.map((item) => (
          <CarouselItem key={item.title}>
            <div className="flex flex-col items-center">
              <h2 className="text-new-Title2 whitespace-pre-line text-center">{item.title}</h2>
              <div className="aspect-square w-[150px] pt-32">
                <Image src={item.img.src} width={150} height={150} alt={item.img.alt} />
              </div>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselDots className="pt-34" />
    </Carousel>
  );
};

export const Login = () => {
  const router = useRouter();
  const { height } = useWindowSize();
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const { redirectUrl } = router.query;

  useProcessLoginCallback();

  const { needMoreInfo, isLoading } = useLoginStore();

  if (needMoreInfo) return <Certification />;

  const isIllustHidden = isAppApproach && height < 650;

  return (
    <>
      {(isLoading || isLoginLoading) && <Loading />}
      <div style={{ height: height }}>
        <div className="flex flex-col items-center">
          <Spacer className="h-32" />
          <div role="img" className="flex items-center justify-center gap-4 pb-24">
            <Image src="/logo/dt-logo.svg" alt="운전선생 로고" width={24} height={24} />
            <Image src="/logo/운전선생.svg" alt="운전선생" width={68} height={18} />
          </div>
          <div>{!isIllustHidden && <LoginPageCarousel />}</div>
          <nav className="fixed bottom-0 px-16 pb-32 pt-10" style={{ paddingBottom: 32 }}>
            <div className="pb-8">
              <button
                aria-label="카카오톡으로 빠른 시작"
                type="button"
                onClick={async () => {
                  try {
                    setIsLoginLoading(true);
                    if (isAppApproach) {
                      await window.flutter_inappwebview.callHandler('KakaoLogin');
                    } else {
                      await saveLoginState(router.query as unknown as LoginState, loginStateId);
                      await router.replace(
                        `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_KAKAO_LOGIN_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code&state=${loginStateId}`,
                      );
                    }
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setIsLoginLoading(false);
                  }
                }}
                className="bg-DTYellow-400 flex h-[52px] w-[calc(var(--app-width)-32px)] justify-center rounded-[16px]"
              >
                <Image src="/login/kakao_3x.png" width={254} height={52} alt="kakao login button" />
              </button>
            </div>
            {/* 추천인 가입 시 이메일로 로그인 불가 */}
            {(isEmpty(redirectUrl) || redirectUrl === '/profile') && (
              <div className="text-new-gray-500 text-new-Body2-medium flex w-full justify-center pt-8 ">
                <Link
                  href={{ pathname: '/login/email', query: { ...router.query } }}
                  className="underline"
                >
                  이메일로 시작하기
                </Link>
              </div>
            )}
          </nav>
        </div>
      </div>
    </>
  );
};
