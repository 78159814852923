import { formatDate, subtractHHMM } from 'shared-values';

import { BaseModel } from './base-model';
import { TimeList } from './time-list';
import { TimeWithDate } from './time-with-date';

export class SelectedTime<T extends SelectedTimeData = SelectedTimeData> extends BaseModel<T> {
  id: string;
  times: TimeWithDate[];
  timeList: TimeList;
  // client에서만 사용되는 데이터
  status?: 'available' | 'finished';

  constructor({ id, times, status }: SelectedTimeData) {
    super('SelectedTime');
    this.id = id;
    this.times = times.map((time) => new TimeWithDate(time));
    this.timeList = new TimeList(this.times);
    this.status = status;
  }

  get startTime() {
    return this.timeList.startTime;
  }

  get endTime() {
    return this.timeList.endTime;
  }

  get date() {
    return this.timeList.date;
  }

  get AMPM() {
    const hour = Number(this.startTime.split(':')[0]);

    if (hour < 12) {
      return '오전';
    } else {
      return '오후';
    }
  }

  get startEndString() {
    return subtractHHMM(this.startTime, 10) + ' ~ ' + this.endTime;
  }

  get startTimeString() {
    return subtractHHMM(this.startTime, 10);
  }

  get fullString() {
    if (!this.date) return '';
    return `${formatDate(this.date, 'YY.MM.DD')}` + '   ' + this.startEndString;
  }

  public get() {
    // status는 client에서만 사용되는 데이터이므로 제외
    return super.get(['status', 'timeList']);
  }

  public orderTimes() {
    this.times = this.times.sort(function (a, b) {
      const { date: ADate, start: AStart } = a;
      const [AstartHour, AstartMinute] = AStart.split(':');
      const { date: BDate, start: BStart } = b;
      const [BstartHour, BstartMinute] = BStart.split(':');

      return (
        Number(formatDate(ADate, 'YYYY-MM-DD').replaceAll('-', '')) * 10000 +
        Number(AstartHour) * 60 +
        Number(AstartMinute) -
        (Number(formatDate(BDate, 'YYYY-MM-DD').replaceAll('-', '')) * 10000 +
          Number(BstartHour) * 60 +
          Number(BstartMinute))
      );
    });

    return this;
  }
}
