import { useEffect, useState } from 'react';

function parseJsonSafely(value: string) {
  try {
    // TODO : superJson으로 바꾸기
    return JSON.parse(value);
  } catch (ex) {
    //
  }

  return value;
}

export const useLocalStorageState = <T>({
  key,
  defaultValue,
}: {
  key: string;
  defaultValue: T;
}): [T, (arg0: T) => void, boolean, () => void] => {
  const [localStorageState, setLocalStorageState] = useState<T>(defaultValue);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const localStorageState = localStorage.getItem(key);
    if (localStorageState) {
      const json = parseJsonSafely(localStorageState);

      // T가 number type이라면 json을 number type으로 변경
      const newData =
        typeof json === 'object'
          ? json
          : typeof defaultValue === 'number'
          ? Number(json)
          : (json as T);

      setLocalStorageState(newData);
    }
    setIsLoading(false);
  }, []);

  const setLocalStorage = (arg0: T) => {
    setLocalStorageState(arg0);
    localStorage.setItem(key, typeof arg0 === 'string' ? arg0 : JSON.stringify(arg0));
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(key);
  };

  return [localStorageState, setLocalStorage, isLoading, clearLocalStorage];
};
