'use client';

import { SVGProps, useEffect, useState } from 'react';

import iconMapping from '@ds/assets/deprecated-icon-mapping';
import classNames from 'classnames';
import { Colors } from 'tailwind-config';

export type IconType = keyof typeof iconMapping;

export interface IconProps extends Omit<SVGProps<SVGSVGElement>, 'fill'> {
  icon: IconType;
  color?: keyof typeof Colors;
  size?: number;
}

export const Icon = ({
  icon: iconComponent,
  size = 24,
  width,
  height,
  color,
  className = '',
  ...props
}: IconProps) => {
  const [SvgIcon, setSvgIcon] = useState<React.ComponentType<React.SVGProps<SVGSVGElement>> | null>(
    null,
  );

  useEffect(() => {
    import(`../../../assets/deprecated_icon/${iconComponent}.svg`)
      .then((iconModule) => {
        setSvgIcon(() => iconModule.default);
      })
      .catch((err) => console.error(`Icon not found: ${iconComponent}.svg`, err));
  }, [iconComponent]);

  if (!SvgIcon)
    return (
      <div
        style={{
          width: size,
          height: size,
        }}
      />
    );

  return (
    <>
      {SvgIcon && (
        <SvgIcon
          {...props}
          width={width ?? size}
          height={height ?? size}
          {...(color ? { fill: Colors[color], stroke: Colors[color] } : {})}
          className={classNames('inline-block flex-shrink-0', className)}
        />
      )}
    </>
  );
};
