import { BaseModel } from '@db/base-models';

export class RequestGoods extends BaseModel {
  type: string;
  data: {
    '수강비-학과'?: number;
    '수강비-기능'?: number;
    '수강비-도로'?: number;
    '검정료-기능'?: number;
    '검정료-도로': number;
    보험비: number;
    교재비: number;
    인지대: number;
  };

  constructor({ type, data }: RequestGoodsData) {
    super('RequestGoods');
    this.type = type;
    this.data = data;
  }
}
