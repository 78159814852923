import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { cn } from 'tailwind-config';

export const Divider = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ className, children }, ref) => {
    if (!children)
      return <div role='separator' className={cn('bg-new-gray-100 h-2', className)} ref={ref} />;

    /**
     * css 가상 선택자를 사용해서 children이 있을 때만 Divider를 그릴 수 있도록 구현
     * https://stackoverflow.com/questions/55303409/react-component-children-detect-if-empty-null-before-render
     */
    return (
      <div ref={ref}>
        {children}
        <div className={cn('bg-new-gray-100 h-2 only:h-0', className)} />
      </div>
    );
  },
);
