import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getRefundedOrders, getSuccessOrders } from '../calls';

type OrdersType = 'refunded' | 'success';

export const useGetOrders = ({ academyId, type }: { academyId: string; type: OrdersType }) => {
  const result = useQueryWithClient({
    queryKey: [
      type === 'refunded'
        ? (QUERY_KEY.REFUNDED_ORDERS as OrdersType)
        : type === 'success'
        ? (QUERY_KEY.SUCCESS_ORDERS as OrdersType)
        : '',
      academyId,
    ],
    queryFn:
      type === 'refunded' ? getRefundedOrders : type === 'success' ? getSuccessOrders : () => [],
    enabled: !!academyId && academyId.length > 0,
  });

  return result;
};
