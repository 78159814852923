export type CRMButtonType = {
  name: string;
  url: string;
};

export const CRMData = {
  userData: [] as { value: string; readOnly: boolean }[][],
  columnLabels: [] as string[],
  crmLists: {
    'kakao-alim': {
      name: '카카오 알림톡',
      content: '' as string,
      template_code: '' as string,
      title: '' as string,
      buttons: [] as CRMButtonType[],
      isSelected: false,
      msg_type: 'AL' as 'AL' | 'AI',
    },
    'kakao-friend': {
      name: '카카오 친구톡',
      content: '' as string,
      image: '' as string,
      buttons: [] as CRMButtonType[],
      isSelected: false,
    },
    push: { name: '푸시알림', title: '' as string, body: '' as string, isSelected: false },
  },
} as const;

export type CRMType = keyof (typeof CRMData)['crmLists'];

export type CRMListData<T extends CRMType> = Omit<
  (typeof CRMData)['crmLists'][T],
  'isSelected' | 'name'
>;

export interface CRMResult {
  result: string;
  message: string;
  type: keyof (typeof CRMData)['crmLists'];
  userId: string;
  phoneNum: string;
  token?: string | null;
}
