import { QueryFunctionContext } from '@tanstack/react-query';
import { db } from 'firebase-config';
import { collection, documentId, getDocs, query, where } from 'firebase/firestore';

export const getExercise = async ({ queryKey }: QueryFunctionContext<[string, number]>) => {
  const [, index] = queryKey;

  if (index === 0) {
    return null;
  }

  const q = query(collection(db, 'NewExercise'), where(documentId(), '==', index.toString()));

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs[0].data() as Exercise;
};
