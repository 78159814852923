import { useMemo } from 'react';

import { useGetUser } from '@db/collections';
import { isUserManager } from '@db/collections/User/utils';
import { useRouter } from 'next/router';

const prod = process.env.NEXT_PUBLIC_PLATFORM_ENV === 'production';

export const ADSense = () => {
  const router = useRouter();

  const { user } = useGetUser();
  const isManager = user && isUserManager(user?.uid);

  const isAdExist = useMemo(() => {
    return router.pathname.includes('test') && prod && isManager;
  }, [router.pathname, isManager, prod]);

  return (
    <>
      {/* {isAdExist && (
        <Script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9018600439614344"
          crossOrigin="anonymous"
        ></Script>
      )} */}
    </>
  );
};

export default ADSense;
