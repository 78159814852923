import { BaseModel } from '@db/base-models';

export class Payment extends BaseModel {
  ordNm: string;
  discountType: string;
  amt: string;
  authType: string;
  lessonName: string;
  discountAmt: string;
  usePointAmt: string;
  ordNo: string;
  payMethod: string;
  nointFlg: string;
  quota: string;
  cardNo: string;
  cancelYN: string;
  appDtm: string;
  resultMsg: string;
  cardType: string;
  appNo: string;
  mbsReserved: string;
  tid: string;
  acqCardCd: string;
  appCardCd: string;
  resultCd: string;
  fnNm: string;

  constructor({
    ordNm,
    discountType,
    amt,
    authType,
    lessonName,
    discountAmt,
    usePointAmt,
    ordNo,
    payMethod,
    nointFlg,
    quota,
    cardNo,
    cancelYN,
    appDtm,
    resultMsg,
    cardType,
    appNo,
    mbsReserved,
    tid,
    acqCardCd,
    appCardCd,
    resultCd,
    fnNm,
  }: PaymentData) {
    super('Payment');
    this.ordNm = ordNm;
    this.discountType = discountType;
    this.amt = amt;
    this.authType = authType;
    this.lessonName = lessonName;
    this.discountAmt = discountAmt;
    this.usePointAmt = usePointAmt;
    this.ordNo = ordNo;
    this.payMethod = payMethod;
    this.nointFlg = nointFlg;
    this.quota = quota;
    this.cardNo = cardNo;
    this.cancelYN = cancelYN;
    this.appDtm = appDtm;
    this.resultMsg = resultMsg;
    this.cardType = cardType;
    this.appNo = appNo;
    this.mbsReserved = mbsReserved;
    this.tid = tid;
    this.acqCardCd = acqCardCd;
    this.appCardCd = appCardCd;
    this.resultCd = resultCd;
    this.fnNm = fnNm;
  }
}
