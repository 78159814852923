'use client';

import {
  DefaultError,
  InfiniteData,
  QueryClient,
  QueryFunctionContext,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

type CustomQueryFunction<T = unknown, TQueryKey extends QueryKey = QueryKey, TPageParam = never> = (
  context: CustomQueryFunctionContext<TQueryKey, TPageParam>,
) => T | Promise<T>;

type CustomQueryFunctionContext<
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = never,
> = QueryFunctionContext<TQueryKey, TPageParam> & {
  queryClient: QueryClient;
};

export function useQueryWithClient<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryFn'> & {
    queryFn: CustomQueryFunction<TQueryFnData, TQueryKey>;
  },
): UseQueryResult<TData, TError> {
  const queryClient = useQueryClient();

  const wrappedQueryFn: CustomQueryFunction<TQueryFnData, TQueryKey> = (context) => {
    const customContext: CustomQueryFunctionContext<TQueryKey> = {
      ...context,
      queryClient,
    };

    return options.queryFn(customContext);
  };

  return useQuery({
    ...options,
    queryFn: wrappedQueryFn as any,
  });
}

export function useInfiniteQueryWithClient<
  TQueryFnData,
  TError = DefaultError,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
>(
  options: Omit<
    UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey, TPageParam>,
    'queryFn'
  > & {
    queryFn: CustomQueryFunction<TQueryFnData, TQueryKey, TPageParam>;
  },
): UseInfiniteQueryResult<TData, TError> {
  const queryClient = useQueryClient();

  const wrappedQueryFn = (context: any) => {
    const customContext = {
      ...context,
      queryClient,
    };

    return options.queryFn(customContext);
  };

  return useInfiniteQuery({
    ...options,
    queryFn: wrappedQueryFn as any,
  });
}
