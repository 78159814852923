import { BaseModel } from '@db/base-models';
import { Academy } from '@db/collections/Academy';
import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { replaceLessonCode } from 'shared-values';

export class Bookmark extends BaseModel {
  id: string;
  lessonCode: LessonCode;

  constructor({
    id,
    lessonCode,
    licenseFilter,
  }: {
    id: string;
    lessonCode: LessonCode;
    licenseFilter?: LessonCode;
  }) {
    super('Bookmark');
    this.id = id;
    /**
     * 변수명을 licenseFilter에서 lessonCode 으로 변경하였으나,
     * 기존 DB에는 licenseFilter로 저장되어 있어, 기존 데이터를 사용하기 위해 다음과 같이 지정했습니다.
     */
    this.lessonCode = licenseFilter ? replaceLessonCode(licenseFilter) : lessonCode;
  }

  public async getAcademyData() {
    const academyDoc = doc(db, 'Academy', this.id);
    const academyData = await getDoc(academyDoc);

    if (!academyData.exists()) {
      return null;
    }

    return new Academy({ ...(academyData.data() as AcademyData), id: this.id });
  }
}
