import { isEmptyString } from 'shared-values';

import { BaseModel } from '../base-model';

export class LessonCondition extends BaseModel {
  registType?: string;
  lessonCode?: string;
  periodText?: string;

  constructor({ registType, lessonCode, periodText }: LessonConditionData) {
    super('LessonCondition');

    this.registType = registType ?? '';
    this.lessonCode = lessonCode ?? '';
    this.periodText = periodText ?? '';
  }

  public canApply(condition: DiscountCondition) {
    return (
      (isEmptyString(condition.registType) ||
        isEmptyString(this.registType) ||
        this.registType === condition.registType) &&
      (isEmptyString(condition.lessonCode) ||
        isEmptyString(this.lessonCode) ||
        this.lessonCode === condition.lessonCode) &&
      (isEmptyString(condition.periodText) ||
        isEmptyString(this.periodText) ||
        this.periodText === condition.periodText)
    );
  }
}
