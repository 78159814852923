/**
 * LESSON 관련 상수들은 여기에서 정의해서 사용
 */
import RawCurriculumContent from './curriculum';
import RawLessonContent from './lesson-data';
import RawPriceInfoContent from './price-info.json';

export const LESSON_CONTENT = RawLessonContent as unknown as LessonContent;

export const CURRICULUM_CONTENT = RawCurriculumContent as unknown as CurriculumRecord;

export const LESSONS = Object.keys(RawLessonContent) as LessonCode[];

export const LESSON_PRIORITY = LESSONS.reduce(
  (acc, key) => ({
    ...acc,
    [key]: LESSON_CONTENT[key].priority,
  }),
  {} as Record<LessonCode, number>,
);

export const PRICE_INFO_CONTENT = RawPriceInfoContent as unknown as PriceInfoContent;
