import { useRouter } from 'next/router';

interface QueryFlag<T> {
  key: string;
  value: T;
}

type QueryFn = {
  (arg: QueryFlag<boolean>): boolean;
  (arg: QueryFlag<boolean>[]): boolean[];
  (arg: QueryFlag<string>): string;
  (arg: QueryFlag<string>[]): string[];
};

export const useQueryFlag: QueryFn = (queryFlag: any) => {
  const router = useRouter();

  if (Array.isArray(queryFlag)) {
    if (typeof queryFlag[0].value === 'boolean') {
      return queryFlag.map((name) =>
        router.query[name.key] ? router.query[name.key] === 'true' : name.value,
      );
    } else if (typeof queryFlag[0].value === 'string') {
      return queryFlag.map((name) => router.query[name.key] ?? name.value);
    }
  } else if (!Array.isArray(queryFlag)) {
    if (typeof queryFlag.value === 'boolean') {
      return router.query[queryFlag.key] ? router.query[queryFlag.key] === 'true' : queryFlag.value;
    } else if (typeof queryFlag.value === 'string') {
      return router.query[queryFlag.key] ?? queryFlag.value;
    }
  }
};
