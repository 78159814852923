import { SelectedTime, SelectedTimeList, StartAndEndTime } from '@db/base-models';
import { QueryModel } from '@db/query-models';
import { isEmpty } from 'lodash-es';
import {
  checkIsListLocked,
  convertDateToWeekday,
  formatDate,
  replaceLessonCode,
} from 'shared-values';

import { TimeList } from '../../../base-models';
import { convertDayToString, getDatesByStartAndEnd, getDaysInMonth } from '../utils';
import { LockedData } from './locked-data';

export const 당일예약가능학원 = [
  // 삼천포
  'IdiE9ngLqYWDY4MW0Td0',
  // 한미
  'Ct8H8g2g21PrEt4DHUoH',
  // 대진
  'M3iAmzacROKlcOaVAsk5',
  // 서대구
  'YNwzLg7D7cydDUHkSTUS',
  // 안전
  'u6WfsKt7CroUmhG7mf54',
  // 동양
  'QLN0dYXZ1hHPjiTD4iw9',
  // 수원현대
  'ndbdRgTrbGsZ9Anphtbp',
  // 수원원천
  'FPM9ycTzLmFTSDJg5ull',
  // 안양
  '5X0f10OuqeKaUmHFzCQG',
  // 창동
  'aL1qG0sqv4OmscS55Xpz',
  // 청구
  'tgAyP4WIv2IODXQhVRIh',
  // 제주
  '166gqvaZPw6dSzvPcnvS',
  // 원영
  'ZJawK6iLTTE4mYm4ndy9',
  // 송탄역
  'G8QnGz7vJWwsGBEzOfEL',
  // 신일
  '593fExEwBSRe1vMmMbC7',
  // 용화
  'qaHvS0nLSw77NZDCmDRj',
  // 천앙중앙
  '3mi3ZvqYS275OuGAumFN',
  // 건양
  '7DiEvG0ZGQBEZrh2hyec',
];

export const 하루전예약가능학원 = [
  // 신정
  'hAllqm62rvWkKbIWGHEf',
];

const today = new Date();
today.setHours(0, 0, 0, 0);
const todayDay = today.getDay();

// safari에서 안전하게 변환하는 함수
const parseDateToKST = (YYYYMMDDdate: string, startTime: string) => {
  // 날짜와 시간을 ISO 8601 형식으로 합칩니다.
  const isoFormattedStr = YYYYMMDDdate + 'T' + startTime; // 'Z'를 추가하여 UTC로 명시

  // ISO 8601 형식의 문자열로 Date 객체를 생성합니다.
  const date = new Date(isoFormattedStr);

  // UTC 시간으로 변환합니다.
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  // 한국 시간으로 변환 (UTC+9)
  const kstDate = new Date(utcDate.getTime() + 9 * 60 * 60 * 1000);

  return kstDate;
};

export const replaceLessonCodeKey = (object: OneDayTimetableData) => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    acc[replaceLessonCode(key as LessonCode)] = value;
    return acc;
  }, {} as OneDayTimetableData);
};

export class Timetable extends QueryModel {
  id: string;
  sunday: OneDayTimetableData;
  monday: OneDayTimetableData;
  tuesday: OneDayTimetableData;
  wednesday: OneDayTimetableData;
  thursday: OneDayTimetableData;
  friday: OneDayTimetableData;
  saturday: OneDayTimetableData;
  timeList: TimeList;
  lockedTime: {
    [key: string]: Partial<Record<LessonCode, LockedData[]>>;
  };

  constructor({
    id,
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    timeList,
    lockedTime,
    queryClient,
    queryKey,
  }: DataModel<TimetableData>) {
    super({ queryClient, queryKey, instanceConstructor: Timetable, className: 'Timetable' });

    this.id = id;

    // class 변환
    for (const day of [sunday, monday, tuesday, wednesday, thursday, friday, saturday]) {
      for (const lessonCode of Object.keys(day) as LessonCode[]) {
        day[lessonCode] = day[lessonCode].map((time) => new SelectedTime(time));
      }
    }

    for (const dateString of Object.keys(lockedTime) as string[]) {
      for (const lessonCode of Object.keys(lockedTime[dateString]) as LessonCode[]) {
        if (!dateString || !lockedTime[dateString] || !lockedTime[dateString][lessonCode]) {
          lockedTime[dateString][lessonCode] = [];
          continue;
        }

        lockedTime[dateString][lessonCode] = lockedTime[dateString][lessonCode]?.map(
          (data) => new LockedData(data),
        );
      }
    }

    this.sunday = replaceLessonCodeKey(sunday);
    this.monday = replaceLessonCodeKey(monday);
    this.tuesday = replaceLessonCodeKey(tuesday);
    this.wednesday = replaceLessonCodeKey(wednesday);
    this.thursday = replaceLessonCodeKey(thursday);
    this.friday = replaceLessonCodeKey(friday);
    this.saturday = replaceLessonCodeKey(saturday);
    this.timeList = new TimeList(timeList.map((time) => new StartAndEndTime(time)));
    this.lockedTime = lockedTime;
  }

  public getAvailableDaysByStartAndEnd({
    startDay,
    endDay,
    lessonCode,
  }: {
    startDay: Date;
    endDay: Date;
    lessonCode: LessonCode;
  }) {
    const dates = getDatesByStartAndEnd(startDay, endDay);

    return this._getAvailableDays({ dates, lessonCode });
  }

  public getAvailableDaysInMonth({ date, lessonCode }: { date: Date; lessonCode: LessonCode }) {
    const dates = getDaysInMonth(date.getMonth(), date.getFullYear());

    return this._getAvailableDays({ dates, lessonCode });
  }

  private _getAvailableDays({ dates, lessonCode }: { dates: Date[]; lessonCode: LessonCode }) {
    const newTimeList: Record<string, SelectedTime[]> = {};
    dates.map((date: Date) => {
      const { lockedTime } = this;

      const currentDay = convertDayToString[date.getDay() as keyof typeof convertDayToString];

      const currentStringDate = formatDate(date, 'YYYY-MM-DD');

      // 해당 날짜에 해당 상품이 없으면
      if (!(lessonCode in this[currentDay])) {
        newTimeList[currentStringDate] = [];
        return;
      }

      let lockedData: LockedData[] = [];

      // load LockedData from LockedTime
      if (currentStringDate in lockedTime) {
        lockedData = lockedTime[currentStringDate][lessonCode] ?? [];
      }

      const newSelectedTimeList: SelectedTime[] = [];

      this[currentDay][lessonCode].map((selectedTime) => {
        const { times } = selectedTime;

        let newSelectedTime: SelectedTime = new SelectedTime({
          ...selectedTime,
          times: times.map((time: StartAndEndTime) => ({ ...time, date })),
        });

        const { startTime } = newSelectedTime;

        const kstDate = parseDateToKST(formatDate(date, 'YYYY-MM-DD'), startTime);

        // 이미 지난 수업인지 확인
        if (kstDate < new Date()) {
          newSelectedTime = new SelectedTime({ ...newSelectedTime, status: 'finished' });
          return;
        }

        // 잠긴 시간인지 확인
        else if (checkIsListLocked(newSelectedTime, lockedData)) {
          newSelectedTime = new SelectedTime({ ...newSelectedTime, status: 'finished' });
          return;
        } else {
          newSelectedTime = new SelectedTime({ ...newSelectedTime, status: 'available' });
        }

        newSelectedTimeList.push(newSelectedTime);
      });

      newTimeList[currentStringDate] = newSelectedTimeList;
    });

    return newTimeList;
  }

  public getExcludeDates({
    date,
    availableDays,
    isFromOnSiteEvent,
  }: {
    date: Date;
    availableDays: Record<string, SelectedTime[]>;
    isFromOnSiteEvent?: boolean;
  }) {
    if (isEmpty(availableDays)) return [];

    const excludedDates: Date[] = [];

    const monthArray = getDaysInMonth(date.getMonth(), date.getFullYear());

    monthArray.map((date: Date) => {
      const dateString = formatDate(date, 'YYYY-MM-DD');

      if (dateString in availableDays && availableDays[dateString].length === 0) {
        excludedDates.push(date);
        return;
      }

      return;
    });

    return excludedDates;
  }

  public checkDateValid({
    date,
    lessonCode,
    selectedTimeId,
  }: {
    date: Date;
    lessonCode: LessonCode;
    selectedTimeId: string;
  }): boolean {
    const currentDay = convertDayToString[date.getDay() as keyof typeof convertDayToString];

    if (!(lessonCode in this[currentDay])) return false;

    // id와 time1,time2,time3가 일치하는지 확인
    const idMatchArray = this[currentDay][lessonCode].filter(
      (selectedTime) => selectedTime.id === selectedTimeId,
    );

    if (idMatchArray.length === 0) return false;

    return true;
  }

  public getSelectedTimeList({ date, lessonCode }: { date: Date; lessonCode: LessonCode }) {
    const currentDay = date.getDay() as keyof typeof convertDayToString;

    const dayString = convertDayToString[currentDay];

    const selectedTimes = this[dayString][lessonCode];

    if (!selectedTimes) return new SelectedTimeList({ items: [] });

    return new SelectedTimeList({ items: selectedTimes });
  }

  public convertToOneDayTimetable(date: Date) {
    const todayString = convertDateToWeekday({ date });

    const dateString = formatDate(date, 'YYYY-MM-DD');

    let lockedTime = null;

    if (dateString in this.lockedTime) {
      lockedTime = this.lockedTime[dateString];
    }

    return {
      timeList: this.timeList,
      lockedTime,
      ...this[
        todayString as
          | 'sunday'
          | 'monday'
          | 'tuesday'
          | 'wednesday'
          | 'thursday'
          | 'friday'
          | 'saturday'
      ],
    } as OneDayTimetable;
  }
}
