import { db } from 'firebase-config';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { Order } from '../models';

export const getRequestOrders = ({ queryClient, queryKey }: QueryFn) => {
  const [, academyId] = queryKey as [string, string];

  const q = query(
    collection(db, 'Order'),
    where('receipt.academyId', '==', academyId),
    where('state', '==', 'Request'),
    where('visitTrainingReceipt', '==', null),
    orderBy('requestedAt'),
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const result: Order[] = [];
    for (const doc of querySnapshot.docs) {
      const docData = { id: doc.id, ...doc.data() } as OrderData;

      // timestamp를 date로 변환
      findMethodAndReplace(docData, 'toDate');
      result.push(new Order({ ...docData, queryClient }));
    }
    queryClient.setQueryData(queryKey, result);
  });

  return () => {
    unsubscribe();
  };
};
