export class BaseList {
  protected items: any[];
  private _className: string;

  constructor({ items, className }: { items: any[]; className: string }) {
    this.items = items;
    this._className = className;
  }

  protected get firstItem() {
    return this.items[0];
  }

  protected get lastItem() {
    return this.items[this.items.length - 1];
  }

  get length() {
    return this.items.length;
  }

  get isEmpty() {
    return this.items.length === 0;
  }

  get isNotEmpty() {
    return this.items.length > 0;
  }

  get isSingle() {
    return this.items.length === 1;
  }

  // todo : 타입 지정하기
  get() {
    return this.items;
  }

  public getInstanceName() {
    return this._className;
  }
}
