import { FirstVisit } from './first-visit';
import { SelectedTime } from './selected-time';

export class ConfirmedSelectedTime extends SelectedTime<ConfirmedSelectedTimeData> {
  id: string | 'custom';
  dateRange: string[];
  firstVisit: FirstVisit;

  constructor({ id, times, dateRange, firstVisit, ...rest }: ConfirmedSelectedTimeData) {
    super({
      id,
      times,
      ...rest,
    });

    this.id = id;
    this.dateRange = dateRange;
    this.firstVisit = new FirstVisit(firstVisit);
  }
}
