import { QueryClient } from '@tanstack/react-query';
import { db } from 'firebase-config';
import { doc } from 'firebase/firestore';

import { BaseModel } from '../base-models';

// react-query에 상태 관리를 위임할 모델의 기본 클래스
export class QueryModel<T = any> extends BaseModel<T> {
  protected _queryClient?: QueryClient;
  protected _queryKey?: any[];
  protected _instanceConstructor: QueryModelConstructor;

  constructor({ queryClient, queryKey, instanceConstructor, className }: QueryModelProps) {
    super(className);
    this._queryClient = queryClient;
    this._queryKey = queryKey;
    // 자식의 클래스를 넘겨줘야 함
    this._instanceConstructor = instanceConstructor;
    this._className = className;
  }

  public getQueryVariables() {
    return { queryKey: this._queryKey, queryClient: this._queryClient };
  }

  protected _getDocRef(id: string) {
    if (!this._className) throw new Error('className is not defined');

    return doc(db, this._className, id);
  }

  protected _setData<T>(data: Partial<T>) {
    if (!this._queryClient || !this._queryKey)
      throw new Error('queryClient or queryKey is not defined');

    this._queryClient.setQueryData<QueryModelConstructor | undefined>(this._queryKey, (old) => {
      return new this._instanceConstructor({
        ...old,
        ...data,
        queryClient: this._queryClient,
        queryKey: this._queryKey,
      });
    });
  }

  public get(excludeKeys: string[] = []): T {
    const defaultExcludeKeys = ['_queryKey', '_queryClient', '_instanceConstructor'];
    const combinedExcludeKeys = [...new Set([...defaultExcludeKeys, ...excludeKeys])];
    return super.get(combinedExcludeKeys);
  }
}
