import { db } from 'firebase-config';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { Order } from '../models';

const today = new Date();
const tommorow = new Date();
tommorow.setDate(today.getDate() + 1);

export const getTodayOrders = ({ queryClient, queryKey }: QueryFn) => {
  const [, academyId] = queryKey as [string, string];

  const todayMidNight = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const tommorowMidNight = new Date(
    tommorow.getFullYear(),
    tommorow.getMonth(),
    tommorow.getDate(),
  );

  const q = query(
    collection(db, 'Order'),
    where('receipt.academyId', '==', academyId),
    where('selectedTime.firstVisit.date', '>=', todayMidNight),
    where('selectedTime.firstVisit.date', '<', tommorowMidNight),
    where('visitTrainingReceipt', '==', null),
    where('state', 'in', ['Appointed', 'Arrival', 'NoShow']),
    orderBy('selectedTime.firstVisit.date'),
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const result: Order[] = [];
    for (const doc of querySnapshot.docs) {
      const docData = { id: doc.id, ...doc.data() } as OrderData;

      // timestamp를 date로 변환
      findMethodAndReplace(docData, 'toDate');
      result.push(new Order({ ...docData, queryClient }));
    }
    queryClient.setQueryData(queryKey, result);
  });

  return () => {
    unsubscribe();
  };
};
