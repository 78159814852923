import React from 'react';

import { useGetUser } from '@db/collections';
import {
  NewBottomSheet,
  NewBottomSheetClose,
  NewBottomSheetContent,
  NewBottomSheetDescription,
  NewBottomSheetHeader,
  NewBottomSheetTitle,
  NewBottomSheetTrigger,
  Spacer,
} from 'design-system';
import Image from 'next/image';
import { cn } from 'tailwind-config';

import { KakaoLoginButton } from '@components/Activation/KakaoLoginButton';

export const NeedLoginBottomSheet = ({
  children,
  className,
  redirectUrl,
}: {
  children: React.ReactNode;
  className?: string;
  redirectUrl?: string;
}) => {
  const { user, isLoading } = useGetUser();

  if (user || isLoading) return children;

  return (
    <NewBottomSheet>
      <NewBottomSheetTrigger
        className={cn('w-full', className)}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </NewBottomSheetTrigger>
      <NewBottomSheetContent>
        <NewBottomSheetHeader>
          <NewBottomSheetTitle>로그인하고 참여하기</NewBottomSheetTitle>
          <NewBottomSheetDescription className="text-new-Body2-medium text-new-gray-500">
            쿠폰을 받으려면, 로그인/회원가입이 필요해요.
          </NewBottomSheetDescription>
        </NewBottomSheetHeader>
        <div className="flex w-full items-center justify-center">
          <Image src="/need-login.png" width={96} height={96} alt="로그인 필요" />
        </div>
        <Spacer className="h-[24px]" />
        <KakaoLoginButton redirectUrl={redirectUrl} />
      </NewBottomSheetContent>
    </NewBottomSheet>
  );
};
