import axios from 'axios';
import { KAKAO_REDIRECT_URI } from 'shared-values';

export const getKakaoTokenData = async (KAKAO_LOGIN_CODE: string, host?: string) => {
  try {
    if (!KAKAO_LOGIN_CODE) throw new Error('No Kakao Login Code');

    const url = `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${process.env.NEXT_PUBLIC_KAKAO_LOGIN_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&code=${KAKAO_LOGIN_CODE}&client_secret=${process.env.NEXT_PUBLIC_KAKAO_LOGIN_CLIENT_SECRET}`;

    return await axios.post(url, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    });
  } catch (error) {
    console.error(error);
    return;
  }
};
