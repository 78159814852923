import { subtractHHMM } from 'shared-values';

import { BaseModel } from './base-model';
import { StartAndEndTime } from './start-and-end-time';
import { TimeList } from './time-list';

export class SelectedTimeWithoutDate extends BaseModel {
  id: string;
  times: StartAndEndTime[];
  timeList: TimeList;

  constructor({ id, times }: SelectedTimeWithoutDateData) {
    super('SelectedTimeWithoutDate');
    this.id = id;
    this.times = times.map((time) => new StartAndEndTime(time));
    this.timeList = new TimeList(this.times);
  }

  get startTime() {
    return this.timeList.startTime;
  }

  get endTime() {
    return this.timeList.endTime;
  }

  get date() {
    return this.timeList.date;
  }

  get AMPM() {
    const hour = Number(this.startTime.split(':')[0]);

    if (hour < 12) {
      return '오전';
    } else {
      return '오후';
    }
  }

  get startEndString() {
    return subtractHHMM(this.startTime, 10) + ' ~ ' + this.endTime;
  }

  public get() {
    return super.get(['timeList']);
  }
}
