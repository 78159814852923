import { BaseModel } from '@db/base-models';

export class Address extends BaseModel {
  bubAddress: string;
  bubCode: number;
  latitude: number;
  longitude: number;

  constructor({
    bubAddress,
    bubCode,
    latitude,
    longitude,
  }: {
    bubAddress: string;
    bubCode: number;
    latitude: number;
    longitude: number;
  }) {
    super('Address');
    this.bubAddress = bubAddress;
    this.bubCode = bubCode;
    this.latitude = latitude;
    this.longitude = longitude;
  }
}
