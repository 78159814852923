import { QueryModel } from '@db/query-models';

export class AcademyDetail extends QueryModel {
  id: string;
  기능: AcademyDetailData['기능'];
  도로: AcademyDetailData['도로'];

  constructor({ queryClient, queryKey, id, 기능, 도로 }: DataModel<AcademyDetailData>) {
    super({
      queryClient,
      queryKey,
      instanceConstructor: AcademyDetail,
      className: 'AcademyDetail',
    });

    this.id = id;
    this.기능 = 기능 ?? [];
    this.도로 = 도로 ?? [];
  }
}
