import { db } from 'firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { CouponCode } from '../models';

export const getCouponCodesByAcademyId = async ({ queryKey, queryClient }: QueryFn) => {
  const [, academyId, isValidCoupon, condition] = queryKey as [
    string,
    string,
    boolean,
    DiscountCondition?,
  ];
  const docRef = query(collection(db, 'CouponCode'), where('discount.academyId', '==', academyId));

  const getDocData = await getDocs(docRef);

  if (getDocData.empty) return [];

  const coupons = getDocData.docs.reduce((acc, doc) => {
    const docData = doc.data() as CouponCodeData;
    findMethodAndReplace(docData, 'toDate');

    const result = new CouponCode({ ...docData, id: doc.id, queryClient, queryKey });

    // 조건에 맞지 않으면, result를 배열에 추가하지 않음
    if (
      (condition && !result.discount.canApply(condition)) ||
      (isValidCoupon && result.isExpired)
    ) {
      return acc; // 현재까지의 누적 결과를 그대로 반환
    }

    // 조건에 맞는 result를 배열에 추가
    acc.push(result);
    return acc; // 업데이트된 누적 결과를 반환
  }, [] as CouponCode[]);

  return coupons;
};
