import React from 'react';

import Image from 'next/image';

// 환경 변수를 통해 Next.js 환경인지 판단
const isNextEnv = process.env.VITE_IS_NEXT ? process.env.VITE_IS_NEXT === 'true' : true;
interface DSImageProps extends React.ComponentProps<typeof Image> {
  src: string;
  alt: string;
}

let ImageComponent: React.FC<DSImageProps>;

if (isNextEnv) {
  ImageComponent = ({ src, alt, ...props }) => <Image src={src} alt={alt} {...props} />;
} else {
  // Next.js 환경이 아닐 때는 기본 img 태그 사용
  ImageComponent = ({ src, alt, ...props }) => (
    <img src={src} alt={alt} {...(props as React.ImgHTMLAttributes<HTMLImageElement>)} />
  );
}

export const DSImage = ({ src, alt, ...props }: DSImageProps) => {
  return <ImageComponent src={src} alt={alt} {...props} />;
};
