export * from './academy';
export * from './academy-blog';
export * from './academy-list';
export * from './academy-location';
export * from './academy-review';
export * from './base-academy';
export * from './detail-info';
export * from './lesson';
export * from './request-goods';
export * from './request-info';
export * from './smallmall-info';
export * from './submall-info';
