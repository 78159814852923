import { BaseModel } from '@db/base-models';

export class AcademyBlog extends BaseModel {
  images: string[];
  title: string;
  description: string;
  writtenAt: Date;
  url: string;
  isInternal: boolean;

  constructor({ images, title, description, writtenAt, url, isInternal }: AcademyBlogData) {
    super('AcademyBlog');
    this.images = images;
    this.title = title;
    this.description = description;
    this.writtenAt = writtenAt;
    this.url = url;
    this.isInternal = isInternal;
  }
}
