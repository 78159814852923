import { NextRouter } from 'next/router';

export const redirect = async ({
  router,
  loginState,
  isUserExist,
  coupon,
}: {
  router: NextRouter;
  loginState: LoginState;
  isUserExist: boolean;
  coupon: IssuedCouponData | null;
}) => {
  const { redirectUrl, roomId } = loginState;

  if (roomId && roomId.length) {
    await router.replace(`/event/together/room/${roomId}`);
  } else if (redirectUrl && redirectUrl.length) {
    // router를 사용하면 zustand-queryString 인식에 문제가 있어
    // window.location.href로 일단 변경
    location.replace(redirectUrl);
  } else {
    await router.replace('/');
  }
};
