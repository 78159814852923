import { QueryListModel } from '@db/query-models';
import { db } from 'firebase-config';
import { doc, setDoc } from 'firebase/firestore';
import { isEqual } from 'lodash-es';
import { formatDate, modifyArray } from 'shared-values';
import { v4 as uuidv4 } from 'uuid';

import { Exercise } from './exercise';

const Categories = [
  {
    answerNum: 1,
    text: '문장형',
    score: 2,
  },
  {
    answerNum: 2,
    text: '문장형',
    score: 3,
  },
  {
    answerNum: 2,
    text: '사진형',
    score: 3,
  },
  {
    answerNum: 1,
    text: '안전표지형',
    score: 2,
  },
  {
    answerNum: 2,
    text: '일러스트형',
    score: 3,
  },
  {
    answerNum: 1,
    text: '동영상형',
    score: 5,
  },
];

export class ExerciseList extends QueryListModel {
  items: Exercise[];
  _score = -1;
  _key = '';
  _rightNumber = -1;
  _isPassed = false;
  _prevHistory: TestHistory[] = [];
  _targetLicense = '';

  constructor({ items, queryClient, queryKey }: DataListModel<Exercise>) {
    super({
      items,
      queryClient,
      queryKey,
      instanceConstructor: ExerciseList,
      className: 'ExerciseList',
    });
    this.items = items;
  }

  public setUserAnswer = ({ index, userAnswer }: { index: number; userAnswer: number[] }) => {
    if (!this._queryClient || !this._queryKey)
      throw new Error('queryClient or queryKey is not defined');
    const next = [...this.items];
    const current = next[index];

    const newExercises = modifyArray(
      next,
      index,
      new Exercise({
        ...current,
        userAnswer,
      }),
    );

    this.items = newExercises;

    this._queryClient.setQueryData<ExerciseList | undefined>(
      this._queryKey,
      (old: ExerciseList | undefined) => {
        if (!old) return old;

        return new ExerciseList({
          items: newExercises,
          queryClient: this._queryClient,
          queryKey: this._queryKey,
        });
      },
    );
  };

  public async scoring() {
    let score = 0;
    let rightNumber = 0;

    this.items = this.items.map((exercise) => {
      const { answer, userAnswer, answerNum, type } = exercise;
      if (isEqual(answer.sort(), userAnswer?.sort())) {
        const targetScore =
          Categories.find((category) => {
            return category.answerNum === answerNum && category.text === type;
          })?.score ?? 0;
        score += targetScore;
        rightNumber += 1;
        exercise.setIsCorrect(true);
        return exercise;
      }
      exercise.setIsCorrect(false);
      return exercise;
    });

    const targetLicense = localStorage.getItem('targetLicense');
    const isPassed =
      targetLicense === '1종 보통' || targetLicense === '1종 대형' ? score >= 70 : score >= 60;

    this._targetLicense = targetLicense ?? '';
    this._score = score;
    this._isPassed = isPassed;
    this._rightNumber = rightNumber;

    this._setKey();
    this._setPrevHistory();
    this._setHighScore();
    this._setWrongProblems();
    await this._setDocument();

    return this._key;
  }

  private _setKey() {
    const key = uuidv4();
    this._key = key;
  }

  private _setPrevHistory() {
    const prevHistory: TestHistory[] = JSON.parse(localStorage.getItem('testHistory') ?? '[]');

    prevHistory.push({
      id: this._key,
      score: this._score,
      isPassed: this._isPassed,
      rightNumber: this._rightNumber,
      date: formatDate(new Date(), 'YY/MM/DD'),
    } as TestHistory);

    this._prevHistory = prevHistory;

    localStorage.setItem('testHistory', JSON.stringify(prevHistory));
  }

  private _setHighScore() {
    const userHighScore = this._prevHistory.reduce((acc, cur) => {
      if (acc < cur.score) {
        return cur.score;
      }
      return acc;
    }, 0);

    localStorage.setItem('userHighScore', userHighScore.toString());
  }

  private async _setDocument() {
    if (this._key === '') throw new Error('key is not set');
    if (this._score === -1) throw new Error('score is not set');
    if (this._rightNumber === -1) throw new Error('rightNumber is not set');

    await setDoc(doc(db, 'UserTest', this._key), {
      exercises: this.items.map((exercise) => exercise.get()),
      score: this._score,
      license: this._targetLicense,
      isPassed: this._isPassed,
      rightNumber: this._rightNumber,
    });
  }

  private _setWrongProblems() {
    const localWrongProblems = localStorage.getItem('wrongProblems');

    let wrongProblems: WrongProblem[] = localWrongProblems ? JSON.parse(localWrongProblems) : [];

    const wrongExercises = this.items
      .filter((exercise) => !exercise.isCorrect)
      .filter((exercise) => (exercise.userAnswer ?? []).length > 0);

    Promise.all(
      wrongExercises.map(async (exercise) => {
        await exercise.updateNumber('wrong');
      }),
    );

    // 병렬처리를 하지 않기 위해 for문 사용
    for (const exercise of wrongExercises) {
      wrongProblems = exercise.addWrongProblem(wrongProblems);
    }

    localStorage.setItem('wrongProblems', JSON.stringify(wrongProblems));
  }

  public setWrongCount(wrongProblems: { index: number; count: number }[]) {
    this.items = this.items.map((exercise) => {
      const wrongCount =
        wrongProblems.find((problem) => problem.index === exercise.index)?.count || 0;
      exercise.setWrongCount(wrongCount);
      return exercise;
    });
    return this;
  }
}
