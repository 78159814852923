import { useEffect, useState } from 'react';

import { useGetEventRoom, useGetUser } from 'database';
import { Label, NewIcon } from 'design-system';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { isAppApproaching, pad } from 'shared-values';
import { cn } from 'tailwind-config';

const isAppApproach = isAppApproaching() && !window.isBottomNavbarVisible;

const useHandleData = () => {
  const [eventRoomId, setEventRoomId] = useState<string>('');
  const result = useGetEventRoom(eventRoomId);

  const { user } = useGetUser();

  useEffect(() => {
    if (!user) return;
    setEventRoomId(user.eventRoomId);
  }, [user]);

  return result;
};

export const TogetherRoomBottomFloatingBar = ({ close }: { close: () => void }) => {
  const router = useRouter();
  const { data: eventRoom, isLoading, isRoomNotExist } = useHandleData();

  const onRouteRoom = (roomId: string) => {
    router.push(`/event/together/room/${roomId}`);
  };

  useEffect(() => {
    if (isRoomNotExist) {
      close();
    }
  }, [isRoomNotExist]);

  const [leftTime, setLeftTime] = useState(0);

  useEffect(() => {
    if (!eventRoom?.timeLimit) return;

    const interval: any = setInterval(() => {
      const leftTime = eventRoom.timeLimit.getTime() - new Date().getTime();
      if (leftTime <= 0) {
        return clearInterval(interval);
      }
      setLeftTime(leftTime);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [eventRoom?.timeLimit]);

  const leftTimeString = `
  ${pad(Math.floor(leftTime / 1000 / 60 / 60))}:${pad(Math.floor(leftTime / 1000 / 60) % 60)}:${pad(
    Math.floor((leftTime / 1000) % 60),
  )}`;

  if (!eventRoom || isLoading || isRoomNotExist || eventRoom.isExpired) return null;

  return (
    <>
      <div className="h-[60px]" />
      <motion.main
        className={cn(
          'py-18 bg-new-gray-900 fixed z-50 flex w-full cursor-pointer items-center justify-between px-16',
        )}
        style={{ bottom: !isAppApproach ? 82 : 0 }}
        initial={{ y: 200 }}
        animate={{ y: 0 }}
        exit={{ y: 200 }}
        transition={{ type: 'spring', bounce: 0 }}
        onClick={() => onRouteRoom(eventRoom.id)}
      >
        <div className="flex items-center gap-8">
          <Label label="남은 결제 시간" styles="filled-red" className="h-fit" />
          <p className="text-new-Body1-bold text-new-DTRed-400">{leftTimeString}</p>
        </div>
        <div className="flex items-center gap-4">
          <p className="text-new-Body1-bold text-white">같이취득 방</p>
          <NewIcon icon="arrowright-outlined" size={16} className="fill-new-white" />
        </div>
      </motion.main>
    </>
  );
};
