import { LessonCondition } from './lesson-condition/lesson-condition';

const isValidDiscount = ([key, value]: [string, number]) =>
  key !== 'condition' && value !== undefined;

const convertToAmount = (amount: number) => {
  if (amount <= 1) {
    return `${amount * 100}%`;
  } else {
    return `${amount / 10000}원`;
  }
};

// 일단 4인의 가격에 대해서만 table을 만들어둠
export class TogetherEventTable {
  '2명'?: number;
  '3명'?: number;
  '4명'?: number;
  lessonConditions: LessonCondition[];

  constructor(params: any) {
    this['2명'] = params['2명'];
    this['3명'] = params['3명'];
    this['4명'] = params['4명'];
    this['lessonConditions'] = params['lessonConditions']
      ? params['lessonConditions'].map(
          (lessonCondition: LessonConditionData) => new LessonCondition(lessonCondition),
        )
      : [];
  }

  public getPercent(number: number) {
    if (number < 2 || number > 4) return 0;
    if (number === 2) return this['2명'] ?? 0;
    if (number === 3) return this['3명'] ?? 0;
    if (number === 4) return this['4명'] ?? 0;
    return 0;
  }

  public get() {
    return {
      ...(this['2명'] ? { '2명': this['2명'] } : {}),
      ...(this['3명'] ? { '3명': this['3명'] } : {}),
      ...(this['4명'] ? { '4명': this['4명'] } : {}),
    };
  }
  get highestPercent(): number {
    const prices = [this['2명'], this['3명'], this['4명']].filter(
      (price): price is number => price !== undefined,
    );
    if (prices.length === 0) return 0;

    const highestValue = Math.max(...prices);

    return highestValue;
  }

  get getHighestDiscountAmount(): string {
    return convertToAmount(this.highestPercent);
  }

  public getDiscountAmount(people: number) {
    if (this.getPercent(people) <= 1) {
      return `${this.getPercent(people) * 100}%`;
    }
    return `${this.getPercent(people) / 10000}원`;
  }

  public canApply(discountCondition: DiscountCondition) {
    return this.lessonConditions.some((lessonCondition) =>
      lessonCondition.canApply(discountCondition),
    );
  }

  public getPriceTable() {
    return (Object.entries(this ?? []) as [string, number][])
      .filter(isValidDiscount)
      .map((discount) => {
        const [key, value] = discount;

        return [key, convertToAmount(value)];
      });
  }
}
