'use client';

import { ReactElement } from 'react';

import { Portal } from '@ds/components/reusable';
import { AnimatePresence, motion } from 'framer-motion';

export const BottomSheetWrapper = ({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement | ReactElement[];
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Portal onClickBackground={() => onClose()}>
          <motion.div
            className='absolute bottom-0 left-1/2 w-full max-w-[500px]'
            initial={{ y: 1000, x: '-50%' }}
            animate={{ y: 0, x: '-50%' }}
            exit={{ y: 1000, x: '-50%' }}
            transition={{ type: 'spring', bounce: 0, duration: 0.7 }}>
            {children}
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  );
};
