import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getUserTest } from '../apis';

export const useGetUserTest = (id: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.USER_TEST, id],
    queryFn: getUserTest,
  });

  return { ...result };
};
