import type { FC, HTMLAttributes } from 'react';

import { cn } from 'tailwind-config';

import { useCarouselDotButton } from './use-carousel-dot-button';

export const CarouselDots: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  const dots = useCarouselDotButton();

  return (
    <div className={cn('flex justify-center gap-4', className)} {...props}>
      {dots.scrollSnaps.map((carouselItemScrollSnap, carouselItemIndex) => (
        <div
          key={carouselItemScrollSnap}
          className={cn(
            'h-5 w-5 rounded-full',
            dots.selectedIndex === carouselItemIndex ? 'bg-new-gray-900' : 'bg-new-black-25',
          )}
        />
      ))}
    </div>
  );
};
