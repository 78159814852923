import { ProviderData, UserInfo } from '../../use-process-login-callback';
import { getFirebaseCustomToken } from './get-firebase-custom-token';
import { getKakaoData } from './get-kakao-data';
import { getKakaoUserData } from './get-kakao-user-data';

const getUserInfo = async (providerData: ProviderData) => {
  if (providerData.provider === 'kakao-rest') {
    const { code, host } = providerData;

    return await getKakaoData(code, host);
  } else if (providerData.provider === 'kakao-native') {
    const { accessToken } = providerData;

    const kakaoUserData = await getKakaoUserData(accessToken);
    return { ...kakaoUserData, ...providerData };
  } else if (providerData.provider === 'apple-native') {
    const { userName, userEmail } = providerData;
    return {
      ...providerData,
      name: userName,
      email: userEmail,
      // apple에서는 다음 정보를 받아올 수 없어서 빈값으로 처리
      // 추가 정보 받아야 함.
      notificationAgreed: false,
      birthYear: '',
      phoneNum: '',
      realName: '',
      birthday: '',
    };
  }
  throw new Error('알 수 없는 로그인 방식입니다.');
};

export const getUserInfoByProvider = async (providerData: ProviderData): Promise<UserInfo> => {
  const userInfo = await getUserInfo(providerData);

  // 인가 토큰을 이용해 유저 관련 정보를 가져옴.
  const firebaeInfo = await getFirebaseCustomToken(userInfo.idToken, providerData.provider);

  // email의 경우 idToken을 decode 한 값으로 overrride
  return {
    ...userInfo,
    ...firebaeInfo,
  };
};
