import { Divider } from 'design-system';

export const NotificationTerm = () => {
  return (
    <>
      <h1 className="text-new-Title2 p-16">광고성 정보 이용약관</h1>
      <Divider className="bg-new-gray-200 h-8" />
      <div className="text-Body2 bg-white px-20 py-16">
        <div className="text-T3 mt-16 font-bold">1. 광고성 정보의 이용목적</div>
        <div className="mt-16">
          운전선생이 제공하는 이용자 맞춤형 서비스, 뉴스레터 발송, 새로운 기능의 안내, 각종 경품
          행사, 이벤트 등의 광고성 정보를 전자우편이나 서신우편, 문자(SMS), 푸시 등을 통해
          이용자에게 제공합니다. 이메일 및 SMS 수신거부와 관계없이 광고나 영리성 목적 외의 약관안내
          및 서비스내용, 회사의 주요 정책 관련 변경에 따른 안내 등 의무적으로 안내되어야 하는 메일은
          정상적으로 발송됩니다. 마케팅 수신 동의는 거부하실 수 있으며 동의 이후에라도 고객의 의사에
          따라 동의를 철회할 수 있습니다. 동의를 거부하시더라도 운전선생이 제공하는 서비스의 이용에
          제한이 되지 않습니다. 단, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가
          제한됩니다.
        </div>
        <div className="text-T3 mt-16 font-bold">2. 미동의 시 불이익 사항</div>
        <div className="mt-16">
          개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의 거부하시더라도 서비스
          이용에 제한되지 않습니다. 단, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내
          서비스가 제한됩니다.
        </div>
        <div className="text-T3 mt-16 font-bold">3. 서비스 정보 수신 동의 철회</div>
        <div className="mt-16">
          운전선생에서 제공하는 마케팅 정보를 원하지 않을 경우 info@drivingteacher.co.kr로 철회를
          요청할 수 있습니다
        </div>
      </div>
    </>
  );
};
