import { QUERY_KEY } from '@db/constants';

import { useQueryWithClient } from '../../../hooks';
import { getAcademyDetail } from '../calls';

export const useGetAcademyDetail = (id?: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.ACADEMY_DETAIL, id ?? ''],
    queryFn: getAcademyDetail,
    enabled: !!id && id !== '',
  });

  return result;
};
