import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getAcademies } from '../calls';

export const useGetAcademies = (
  {
    isBookable,
    isSimulation,
  }: {
    isBookable?: boolean;
    isSimulation?: boolean;
  } = {
    isBookable: false,
    isSimulation: false,
  },
) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.ALL_ACADEMIES],
    queryFn: getAcademies,
  });

  return {
    ...result,

    data: result.data
      ? result.data.filter((academy) => {
          if (isBookable) {
            return academy.isBookable;
          }

          if (isSimulation) {
            return academy.isSimulation;
          }

          return true;
        })
      : [],
  };
};
