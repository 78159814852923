export const MockLesson: LessonData = {
  code: 'ONE_AUTO_NEW',
  price: 0,
  additionalPrice: 0,
  discountPrice: 0,
  isPopular: true,
  arrivalMethod: 'firstVisit',
  isPriceInquiry: false,
  description: '설명설명',
  name: '테스트 수업',
};

export const MockSimulationLesson: SimulationLessonData = {
  code: 'ONE_AUTO_NEW',
  price: 0,
  additionalPrice: 0,
  discountPrice: 0,
  isPopular: true,
  arrivalMethod: 'firstVisit',
  isPriceInquiry: false,
  description: '',
  name: '테스트 수업',
  registType: 'license',
  period: {
    dateTrackingType: 'day',
    text: '3개월',
    limitDays: 90,
  },
  notice:
    '- 첫 이용일로부터 최대 3개월 이용하실 수 있습니다.\\n- 1일 최대 2시간 이용 가능하며, 지점에 따라 협의 후 변동 가능합니다.\\n- 고수의운전면허 매니저를 통해 운전면허시험 접수 및 안내 서비스가 제공됩니다.\\n- 운전면허시험에 합격할 경우, 잔여 유효기간 여부와는 상관 없이 이용이 종료됩니다.\\n- 이용 중간에 환불할 경우, 고수의운전면허 정책에 따라 이용 금액+(전체 금액*10%)이 공제된 잔여 금액이 환불됩니다.\\n이용 금액: 2종 보통 시간당 33,000원, 1종 보통 시간당 44,000원',
  priceNotice: [],
  lessonNotice: [],
};
