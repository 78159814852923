import { useEffect, useState } from 'react';

import { cn } from 'tailwind-config';

import { useMounted } from '@hooks';

interface TimerProps {
  initialSeconds?: number;
  date?: Date;
  className?: string;
}
const HourToSecond = 3600;
const MinuteToSecond = 60;

export const Timer = ({ initialSeconds, date, className }: TimerProps) => {
  const [timerCount, setTimerCount] = useState(
    date ? Math.floor((date.getTime() - new Date().getTime()) / 1000) : initialSeconds ?? 0,
  );
  const isMounted = useMounted();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerCount((prevTimerCount) => prevTimerCount - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  if (timerCount <= 0 || !isMounted) return null;

  return (
    <div className={cn('', className)}>
      {`${timerCount >= HourToSecond ? `${Math.floor(timerCount / HourToSecond)}:` : ''}${
        Math.floor((timerCount % HourToSecond) / MinuteToSecond) < 10
          ? `0${Math.floor((timerCount % HourToSecond) / MinuteToSecond)}`
          : Math.floor((timerCount % HourToSecond) / MinuteToSecond)
      }:${
        timerCount % MinuteToSecond < 10
          ? `0${timerCount % MinuteToSecond}`
          : timerCount % MinuteToSecond
      }`}
    </div>
  );
};
