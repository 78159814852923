import { ConfirmedSelectedTime } from './confirmed-selected-time';
import { FirstVisit } from './first-visit';
import { SelectedRange } from './selected-range';
import { SelectedTime } from './selected-time';
import { StartAndEndTime } from './start-and-end-time';
import { TimeWithDate } from './time-with-date';

// base-model을 상속받은 모델들
// prettier-ignore
export const BASE_MODELS_MAP = {
  'ConfirmedSelectedTime' : ConfirmedSelectedTime,
  'FirstVisit' : FirstVisit,
  'SelectedRange' : SelectedRange,
  'SelectedTime' : SelectedTime,
  'StartAndEndTime' : StartAndEndTime,
  'TimeWithDate' : TimeWithDate,
}

// list는 나중에 추가
