import { BaseModel, TogetherEventTable } from '@db/base-models';
import { DocumentReference } from 'firebase/firestore';

export class EventAcademy extends BaseModel {
  ref: DocumentReference;
  name: string;
  locationAttachedName: string;
  image: string;
  paymentType: PaymentType;
  type: AcademyType;
  togetherEventTable?: TogetherEventTable;

  constructor({
    ref,
    name,
    locationAttachedName,
    image,
    paymentType,
    togetherEventTable,
    type,
  }: EventAcademyData) {
    super('EventAcademy');
    this.ref = ref;
    this.name = name;
    this.locationAttachedName = locationAttachedName ?? '';
    this.image = image;
    this.paymentType = paymentType ?? 'difference';
    this.type = type;
    this.togetherEventTable = togetherEventTable
      ? new TogetherEventTable(togetherEventTable)
      : undefined;
  }

  get isSimulation() {
    return this.type === 'simulation';
  }
}
