import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getTaxInvoiceById } from '../calls';

export const useGetTaxInvoice = (id: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.TAXINVOICE, id],
    queryFn: getTaxInvoiceById,
  });

  return result;
};
