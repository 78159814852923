import { QUERY_KEY } from '@db/constants';
import { useQuery } from '@tanstack/react-query';

import { getCouponCodes } from '../calls';

export const useGetCouponCodes = () => {
  const result = useQuery({
    queryKey: [QUERY_KEY.COUPON_CODES],
    queryFn: getCouponCodes,
  });

  return result;
};
