import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';
import { isEmpty } from 'lodash-es';

import { getOrder } from '../calls';

export const useGetOrder = (id?: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.ORDER, id as string],
    queryFn: getOrder,
    enabled: !isEmpty(id),
  });

  return result;
};
