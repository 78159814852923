import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { Timetable, replaceLessonCodeKey } from '../models';

export const getTimetableData = async (id: string) => {
  const docRef = doc(db, 'Timetable', id);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) throw new Error('Document does not exist');

  const rawDocData = { id, ...docSnap.data() } as TimetableData;

  const dirtyDocData = {
    ...rawDocData,
    monday: replaceLessonCodeKey(rawDocData.monday),
    tuesday: replaceLessonCodeKey(rawDocData.tuesday),
    wednesday: replaceLessonCodeKey(rawDocData.wednesday),
    thursday: replaceLessonCodeKey(rawDocData.thursday),
    friday: replaceLessonCodeKey(rawDocData.friday),
    saturday: replaceLessonCodeKey(rawDocData.saturday),
    sunday: replaceLessonCodeKey(rawDocData.sunday),
  };

  findMethodAndReplace(dirtyDocData, 'toDate');
  return dirtyDocData as TimetableData;
};

export const getTimetable = async ({ queryKey, queryClient }: QueryFn) => {
  const [, id] = queryKey as [string, string];
  const docData = await getTimetableData(id);
  return new Timetable({ ...docData, queryClient, queryKey });
};
