'use client';

import { useEffect } from 'react';

import { QUERY_KEY } from '@db/constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getRequestOrders, getTimetableOrders, getTodayOrders } from '../calls';

type OrdersType = 'request' | 'timetable' | 'today';

export const useGetRealtimeOrders = ({
  academyId,
  type,
  date,
}: {
  academyId: string;
  type: OrdersType;
  date?: Date;
}) => {
  const baseQueryKey = [
    type === 'request'
      ? QUERY_KEY.REQUEST_ORDERS
      : type === 'today'
      ? QUERY_KEY.TODAY_ORDERS
      : type === 'timetable'
      ? QUERY_KEY.TIMETABLE_ORDERS
      : '',
    academyId,
  ] as [string, string];
  const queryKey =
    type === 'timetable' ? ([...baseQueryKey, date] as [string, string, Date]) : baseQueryKey;

  const result = useQuery<Order[]>({
    queryKey,
    queryFn: () => [],
    enabled: false,
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!academyId || academyId.length === 0) return;

    (type === 'request'
      ? getRequestOrders
      : type === 'timetable'
      ? getTimetableOrders
      : type === 'today'
      ? getTodayOrders
      : () => {})({
      queryClient,
      queryKey,
    });
  }, [academyId, date]);

  return result;
};
