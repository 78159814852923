import { db } from 'firebase-config';
import { collection, getDocs, query } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { SalesAcademy } from './../models/sales-academy';

export const getSalesAcademies = async ({ queryClient }: QueryFn) => {
  const q = query(collection(db, 'SalesAcademy'));

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs
    .map((doc) => {
      const docData = { ...doc.data(), id: doc.id } as SalesAcademyData;

      findMethodAndReplace(docData, 'toDate');

      return new SalesAcademy({ ...docData, queryClient });
    })
    .filter((academy) => !academy.isTestAcademy);
};
