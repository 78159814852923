import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { Blog } from '../models';

export const getBlog = async ({ queryKey, queryClient }: QueryFn) => {
  const [, id] = queryKey as [string, string];
  const docRef = doc(db, 'Blog', id);
  const docData = { ...(await getDoc(docRef)).data(), id: docRef.id } as BlogData;

  findMethodAndReplace(docData, 'toDate');

  return new Blog({
    ...docData,
    queryKey,
    queryClient,
  });
};
