import { useGetAcademy } from '@db/collections';
import { useRouter } from 'next/router';

export const useGetAcademyByQuery = () => {
  const router = useRouter();

  const { id = '' } = router.query;

  return useGetAcademy(id as string);
};
