import { QueryModel } from '@db/query-models';
import { db } from 'firebase-config';
import { collection, getDocs, increment, query, updateDoc, where } from 'firebase/firestore';
import { modifyArray } from 'shared-values';

export class Exercise extends QueryModel {
  id: string;
  index: number;
  type: ExerciseType;
  answerNum: number;
  problem: string;
  problemPictures: string[];
  firstChoice: string;
  secondChoice: string;
  thirdChoice: string;
  fourthChoice: string;
  fifthChoice?: string;
  answer: number[];
  answerPictures: string[];
  choicesPictures: string[];
  explanation: string;
  roadExplanation: string;
  wrongNum: number;
  rightNum: number;
  userAnswer?: number[] = [];
  isCorrect?: boolean;
  wrongCount?: number;

  constructor({
    id,
    index,
    type,
    answerNum,
    problem,
    problemPictures,
    firstChoice,
    secondChoice,
    thirdChoice,
    fourthChoice,
    fifthChoice,
    answer,
    answerPictures,
    choicesPictures,
    explanation,
    roadExplanation,
    wrongNum,
    rightNum,
    userAnswer,
    queryClient,
    queryKey,
  }: DataModel<ExerciseData>) {
    super({ queryClient, queryKey, instanceConstructor: Exercise, className: 'Exercise' });
    this.id = id;
    this.index = index;
    this.type = type;
    this.answerNum = answerNum;
    this.problem = problem;
    this.problemPictures = problemPictures;
    this.firstChoice = firstChoice;
    this.secondChoice = secondChoice;
    this.thirdChoice = thirdChoice;
    this.fourthChoice = fourthChoice;
    if (fifthChoice) {
      this.fifthChoice = fifthChoice;
    }
    if (userAnswer) {
      this.userAnswer = userAnswer;
    }
    this.answer = answer;
    this.answerPictures = answerPictures;
    this.choicesPictures = choicesPictures;
    this.explanation = explanation;
    this.roadExplanation = roadExplanation;
    this.wrongNum = wrongNum;
    this.rightNum = rightNum;
  }

  public setUserAnswer(userAnswer: number[]) {
    this.userAnswer = userAnswer;
  }

  public setIsCorrect(isCorrect: boolean) {
    this.isCorrect = isCorrect;
  }

  public setWrongCount(wrongCount: number) {
    this.wrongCount = wrongCount;
  }

  public async correct() {
    this.updateNumber('right');
  }

  public async wrong() {
    const wrongProblems = JSON.parse(localStorage.getItem('wrongProblems') || '[]');
    this.updateNumber('wrong');
    const newWrongProblems = this.addWrongProblem(wrongProblems);
    localStorage.setItem('wrongProblems', JSON.stringify(newWrongProblems));
  }

  public async updateNumber(type: 'right' | 'wrong') {
    const q = query(collection(db, 'Exercise'), where('index', '==', this.index));

    const querySnapshot = await getDocs(q);

    // 학원 업데이트
    await updateDoc(querySnapshot.docs[0].ref, {
      ...(type === 'right' && { rightNum: increment(1) }),
      ...(type === 'wrong' && { wrongNum: increment(1) }),
    });
  }

  public addWrongProblem(wrongProblems: WrongProblem[]) {
    const findIndex = wrongProblems.findIndex((prob) => prob.index === this.index);

    if (findIndex !== -1) {
      return modifyArray(wrongProblems, findIndex, {
        index: this.index,
        count: wrongProblems[findIndex].count + 1,
      });
    } else {
      const newWrongProblems = [...wrongProblems];
      newWrongProblems.push({
        index: this.index,
        count: 1,
      });
      return newWrongProblems;
    }
  }

  public bookmark(bookmarkProblems: number[]) {
    if (!this.isBookmarked(bookmarkProblems)) {
      return this._addBookmarkProblem(bookmarkProblems);
    } else {
      return this._removeBookmarkProblem(bookmarkProblems);
    }
  }

  public isBookmarked(bookmarkProblems: number[]) {
    return bookmarkProblems.includes(this.index);
  }

  private _addBookmarkProblem(bookmarkProblems: number[]) {
    const findIndex = bookmarkProblems.findIndex((prob) => prob === this.index);

    let newBookmarkProblems = [...bookmarkProblems];
    if (findIndex !== -1) {
      newBookmarkProblems = modifyArray(newBookmarkProblems, findIndex, this.index);
    } else {
      newBookmarkProblems.push(this.index);
    }

    localStorage.setItem('bookmarkProblems', JSON.stringify(newBookmarkProblems));
    return newBookmarkProblems;
  }

  private _removeBookmarkProblem(bookmarkProblems: number[]) {
    const newBookmarkProblems = [...bookmarkProblems].filter((prob) => prob !== this.index);

    localStorage.setItem('bookmarkProblems', JSON.stringify(newBookmarkProblems));

    return newBookmarkProblems;
  }
}
