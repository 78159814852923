import { DEFAULT_LOCATION } from '@constants/defaultValue';

export const getBrowserLocation = async (): Promise<{
  location: LocationType;
  locationPermission: Exclude<LocationPermission, 'prompt'>;
}> =>
  new Promise((resolve) =>
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        resolve({
          // 소수점 3자리까지만 표시하는 이유는 위치정보가 너무 자주 바뀌면
          // 캐싱이 안되서 렌더링이 자주 일어나기 때문이다.
          location: {
            latitude: Number(pos.coords.latitude.toFixed(3)),
            longitude: Number(pos.coords.longitude.toFixed(3)),
          },
          locationPermission: 'granted',
        });
      },
      //  실패시 (유저가 위치 공유를 거부했을 경우.)
      () => {
        const { latitude, longitude } = DEFAULT_LOCATION;
        resolve({
          location: {
            latitude,
            longitude,
          },
          locationPermission: 'denied',
        });
      },
    ),
  );
