export const replaceLessonCode = (lessonCode: LessonCode): LessonCode => {
  return lessonCode
    .replace('ONE_NORMAL_ROAD', 'ONE_MANUAL_ROAD')
    .replace('TWO_NORMAL_ROAD', 'TWO_AUTO_ROAD')
    .replace('TWO_NORMAL_VISIT_TRAINING_8_HOURS', 'TWO_AUTO_VISIT_TRAINING_8_HOURS')
    .replace('ONE_NORMAL', 'ONE_MANUAL_NEW')
    .replace('TWO_NORMAL', 'TWO_AUTO_NEW')
    .replace('ONE_CANCEL', 'ONE_MANUAL_RE')
    .replace('TWO_CANCEL', 'TWO_AUTO_RE') as LessonCode;
};
