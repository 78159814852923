import { db } from 'firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const getRecommenderByCode = async (code: string) => {
  const result = [];

  const q = query(collection(db, 'User'), where('code', '==', code));

  const querySnapshot = await getDocs(q);

  for (const doc of querySnapshot.docs) {
    const docData = doc.data() as UserData;

    result.push({
      ...docData,
      uid: doc.id,
    });
  }

  if (result.length === 0) {
    throw new Error('해당 코드에 일치하는 유저가 없습니다.');
  }

  return result;
};
