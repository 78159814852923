import { BaseModel } from '@db/base-models';

export class SubmallInfo extends BaseModel {
  subMid: string;
  subNm: string;
  subCoNo: string;
  bankCd: string;
  acntNo: string;
  acntNm: string;

  constructor({ subMid, subNm, subCoNo, bankCd, acntNo, acntNm }: SubmallInfoData) {
    super('SubmallInfo');
    this.subMid = subMid;
    this.subNm = subNm;
    this.subCoNo = subCoNo;
    this.bankCd = bankCd;
    this.acntNo = acntNo;
    this.acntNm = acntNm;
  }
}
