import { useCallback } from 'react';

import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';
import { useQueryClient } from '@tanstack/react-query';

import { getCouponCode, getCouponCodeWithSchoolName } from '../calls';

export const useGetCouponCode = (code = '') => {
  const queryClient = useQueryClient();
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.COUPON_CODE, code],
    queryFn: getCouponCode,
    enabled: code.length > 0,
  });

  const getManually = useCallback(
    async ({ code, schoolName }: { code?: string; schoolName?: string }) => {
      let couponCode: CouponCode | null = null;

      try {
        // 1. 코드로 직접 가져오기
        if (code) {
          if (queryClient.getQueryData([QUERY_KEY.COUPON_CODE, code])) {
            couponCode =
              queryClient.getQueryData<CouponCode>([QUERY_KEY.COUPON_CODE, code]) ?? null;
          } else {
            couponCode = await getCouponCode({
              queryKey: [QUERY_KEY.COUPON_CODE, code],
              queryClient,
            });
          }
        }
        // 2. 학교 이름으로 가져오기
        else if (schoolName) {
          couponCode = await getCouponCodeWithSchoolName({
            schoolName,
            queryClient,
          });
        }
      } catch (error) {
        throw new Error('해당하는 쿠폰이 없습니다.');
      }

      if (!couponCode) throw new Error('해당하는 쿠폰이 없습니다.');

      queryClient.setQueryData([QUERY_KEY.COUPON_CODE, couponCode.id], couponCode);

      return couponCode;
    },
    [queryClient],
  );

  return { ...result, getManually };
};
