import { db } from 'firebase-config';
import { doc, increment, updateDoc } from 'firebase/firestore';

import { getEventParticipantCount } from './get-event-participant-count';

export const updateEventParticipantCount = async (
  updateKey: keyof Awaited<ReturnType<typeof getEventParticipantCount>>,
) => {
  const docRef = doc(db, 'Config', 'eventParticipantCount');

  await updateDoc(docRef, {
    [updateKey]: increment(1),
  });
};
