// import EventAPI from 'airbridge-web-sdk-loader/build/airbridge_api';
import airbridge from 'airbridge-web-sdk-loader';
import AirbridgeAPI from 'airbridge-web-sdk-loader/build/airbridge_api';

export class SendAirbridgeEvent implements AirbridgeAPI.EventAPI {
  private isAppApproaching: boolean;
  private isValid: boolean;

  constructor({ isValid, isAppApproaching }: { isValid: boolean; isAppApproaching: boolean }) {
    this.isAppApproaching = isAppApproaching;
    this.isValid = isValid;
  }

  public homeViewEvent = () => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler('SendAirbridgeHomeView');
    } else {
      airbridge.events.homeViewEvent();
    }
  };

  public signIn = (option?: AirbridgeAPI.SignOption | undefined) => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler(
        'SendAirbridgeSignIn',
        JSON.stringify({
          id: option?.userID,
          email: option?.userEmail,
          phone: option?.userPhone,
          attributes: option?.attributes,
        }),
      );
    } else {
      airbridge.events.signIn(option);
    }
  };

  public signUp = (option?: AirbridgeAPI.SignOption | undefined) => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler(
        'SendAirbridgeSignUp',
        JSON.stringify({
          id: option?.userID,
          email: option?.userEmail,
          phone: option?.userPhone,
          attributes: option?.attributes,
        }),
      );
    } else {
      airbridge.events.signUp(option);
    }
  };

  public signOut = (option?: AirbridgeAPI.EventOption | undefined) => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler('SendAirbridgeSignOut');
    } else {
      airbridge.events.send('airbridge.user.signout');
      airbridge.clearUser();
    }
  };

  public send = (category: string, option?: AirbridgeAPI.EventOption | undefined) => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler(
        'SendAirbridgeIndividual',
        category,
        JSON.stringify(option),
      );
    } else {
      airbridge.events.send(category, option);
    }
  };

  public productDetailsViewEvent = (option?: AirbridgeAPI.ProductDetailsViewOption | undefined) => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler(
        'SendAirbridgeProductDetails',
        JSON.stringify(option),
      );
    } else {
      airbridge.events.productDetailsViewEvent(option);
    }
  };

  public productListViewEvent = (option?: AirbridgeAPI.ProductListViewOption | undefined) => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler('SendAirbridgeProductList', JSON.stringify(option));
    } else {
      airbridge.events.productListViewEvent(option);
    }
  };

  public searchResultViewEvent = (option?: AirbridgeAPI.SearchResultViewOption | undefined) => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler('SendAirbridgeSearchResult', JSON.stringify(option));
    } else {
      airbridge.events.searchResultViewEvent(option);
    }
  };

  public addedToCart = (option?: AirbridgeAPI.AddedToCartOption | undefined) => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler('SendAirbridgeAddedToCart', JSON.stringify(option));
    } else {
      airbridge.events.addedToCart(option);
    }
  };

  public purchased = (option?: AirbridgeAPI.PurchasedOption | undefined) => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler('SendAirbridgePurchased', JSON.stringify(option));
    } else {
      airbridge.events.purchased(option);
    }
  };

  public sendIndividual = (category: string, option?: AirbridgeAPI.EventOption | undefined) => {
    if (!this.isValid) return;
    if (this.isAppApproaching) {
      window.flutter_inappwebview.callHandler(
        'SendAirbridgeIndividual',
        category,
        JSON.stringify(option),
      );
    } else {
      airbridge.events.send(category, option);
    }
  };

  wait = (timeout: number, callback: AirbridgeAPI.WaitCallback) => void {};
}
