import { db } from 'firebase-config';
import { collection, documentId, getDocs, query, where } from 'firebase/firestore';
import { chunkArray } from 'shared-values';

import { Academy, AcademyList, TestAcademyList } from '../models';

export const getAcademiesByIds = async ({ queryClient, queryKey }: QueryFn) => {
  const [, indexes] = queryKey as [string, (number | string)[]];

  let result: Academy[] = [];

  if (indexes.length === 0) {
    return new AcademyList({ items: [], queryClient, queryKey });
  }

  const chunks = chunkArray(
    indexes.filter((i) => !TestAcademyList.includes(i as string)),
    10,
  );

  for await (const chunk of chunks) {
    const q = query(
      collection(db, 'Academy'),
      where(documentId(), 'in', chunk),
      where('isVisible', '==', true),
    );
    const querySnapshot = await getDocs(q);
    const newArray: Academy[] = [];
    querySnapshot.forEach((docQuery) => {
      const docData = { ...docQuery.data(), id: docQuery.id } as AcademyData;
      newArray.push(new Academy(docData));
    });

    result = [...result, ...newArray];
  }

  return new AcademyList({ items: result, queryClient, queryKey });
};
