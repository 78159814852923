import { getKakaoTokenData } from './get-kakao-token-data';
import { getKakaoUserData } from './get-kakao-user-data';

/**
 * REST API 방식에서 code -> 토큰 데이터를 가져온 후, 유저 데이터를 가져옵니다.
 */
export const getKakaoData = async (code: string, host?: string) => {
  const tokenData = await getKakaoTokenData(code, host);

  if (!tokenData) throw new Error('카카오 토큰 데이터가 없습니다.');

  const data = await getKakaoUserData(tokenData.data.access_token);

  return {
    ...data,
    idToken: tokenData.data.id_token,
    accessToken: tokenData.data.access_token,
  };
};
