import axios from 'axios';

const KakaoTermTags = ['user_age_check', 'drivingteacher_notification'] as const;

type KakaoTermTag = (typeof KakaoTermTags)[number];

interface KakaoTerms {
  agreed: boolean;
  agreed_at: string;
  required: boolean;
  revocable: boolean;
  tag: KakaoTermTag;
}

export const getKakaoTerms = async (accessToken: string) => {
  const kakaoUserInfo = await axios.get<{
    id: string;
    service_terms: KakaoTerms[];
  }>(`https://kapi.kakao.com/v2/user/service_terms`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  let notificationAgreed = false;

  kakaoUserInfo.data.service_terms.map((term) => {
    if (term.tag === 'drivingteacher_notification') {
      if (term.agreed) {
        notificationAgreed = true;
      }
    }
  });

  return { notificationAgreed };
};

/**
 * accessToken을 이용하여 카카오 유저 정보를 가져옵니다.
 */
export const getKakaoUserData = async (accessToken: string) => {
  const kakaoUserInfo = await axios.get(`https://kapi.kakao.com/v2/user/me`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  window.Kakao.Auth.setAccessToken(accessToken);

  const {
    phone_number,
    has_phone_number,
    email,
    birthyear = '',
    birthday = '',
    name: realName = '',
  } = kakaoUserInfo.data.kakao_account;

  const phoneNum = has_phone_number ? phone_number.replace('+82 ', '0').replaceAll('-', '') : '';

  const name = kakaoUserInfo.data.kakao_account.profile.nickname;

  const { notificationAgreed } = await getKakaoTerms(accessToken);
  const birth = `${birthyear}-${birthday.substring(0, 2)}-${birthday.substring(2)}`;

  return {
    phoneNum,
    email,
    name,
    birthYear: birthyear,
    birthday: birth,
    realName,
    notificationAgreed,
  };
};
