import { QUERY_KEY } from '@db/constants';
import { useInfiniteQueryWithClient } from '@db/hooks';
import { QueryDocumentSnapshot } from 'firebase/firestore';

import { getExercises } from '../calls';

export const useGetCategoryExercises = ({
  koreanType,
  choicesNum,
  answerNum,
}: {
  koreanType: string;
  choicesNum: string;
  answerNum: string;
}) => {
  const result = useInfiniteQueryWithClient({
    queryKey: [QUERY_KEY.EXERCISES, koreanType, choicesNum, answerNum] as [
      string,
      string,
      string,
      string,
    ],
    queryFn: getExercises,
    getNextPageParam: (querySnapshot) =>
      querySnapshot.nextPageParam as QueryDocumentSnapshot<Exercise>,
    initialPageParam: null,
  });

  return result;
};
