import React from 'react';

import { VariantProps, cva } from 'class-variance-authority';
import { cn } from 'tailwind-config';

import { NewIcon } from '../NewIcon';

const chipVariants = cva(
  'px-[14px] py-[6px] rounded-[19px] text-new-Body2-bold inline-block whitespace-nowrap',
  {
    variants: {
      styles: {
        filled: 'text-new-white bg-new-gray-900 border-new-gray-900 border-2',
        'outline-black': 'text-new-gray-900 border-new-gray-900 border-2',
        'outline-gray': 'text-new-gray-500 border-new-gray-200 border-2',
        noline: 'text-new-gray-900',
      },
      showIcon: {
        true: 'flex items-center gap-4',
      },
    },
    defaultVariants: {
      styles: 'filled',
    },
  },
);

interface ChipProps
  extends React.ButtonHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof chipVariants> {
  label: string;
}

const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  ({ className, label, styles = 'filled', showIcon, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          chipVariants({ styles, className }),
          'flex min-w-[52px] items-center justify-center gap-2',
        )}
        {...props}
      >
        {label}
        {showIcon && (
          <NewIcon
            icon="arrowdown-outlined"
            size={16}
            className={styles === 'filled' ? 'fill-new-white' : 'fill-new-gray-900'}
          />
        )}
      </div>
    );
  },
);

export { Chip, chipVariants };
