import { QueryClient } from '@tanstack/react-query';

import { BaseList } from '../base-models/base-list';

// react-query에 상태 관리를 위임할 모델 List의 기본 클래스
export class QueryListModel extends BaseList {
  protected _queryClient?: QueryClient;
  protected _queryKey?: any[];
  protected _instanceConstructor: QueryModelConstructor;

  constructor({
    items,
    queryClient,
    queryKey,
    instanceConstructor,
    className,
  }: QueryListModelProps) {
    super({ items, className });
    this._queryClient = queryClient;
    this._queryKey = queryKey;
    // 자식의 클래스를 넘겨줘야 함
    this._instanceConstructor = instanceConstructor;
  }

  public getQueryVariables() {
    return { queryKey: this._queryKey, queryClient: this._queryClient };
  }

  protected _setData<T>(data: Partial<T>) {
    if (!this._queryClient || !this._queryKey)
      throw new Error('queryClient or queryKey is not defined');

    this._queryClient.setQueryData<QueryModelConstructor | undefined>(
      this._queryKey,
      (old) =>
        old &&
        new this._instanceConstructor({
          ...old,
          ...data,
          queryClient: this._queryClient,
          queryKey: this._queryKey,
        }),
    );
  }
}
