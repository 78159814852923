import { range, sampleSize } from 'lodash-es';

const SentenceOneAnswer = [
  209, 425, 360, 272, 20, 347, 382, 558, 241, 191, 601, 58, 566, 567, 401, 379, 50, 671, 502, 501,
  165, 86, 380, 491, 591, 234, 415, 301, 495, 268, 636, 664, 169, 275, 433, 139, 656, 96, 140, 25,
  549, 217, 611, 345, 677, 619, 624, 606, 615, 453, 524, 498, 302, 358, 240, 672, 91, 649, 117, 255,
  590, 3, 102, 535, 407, 228, 131, 159, 399, 584, 544, 426, 424, 613, 157, 512, 637, 263, 370, 1,
  670, 295, 214, 542, 245, 35, 448, 539, 161, 22, 279, 594, 74, 583, 199, 39, 273, 81, 337, 394,
  237, 513, 573, 409, 400, 194, 626, 298, 350, 282, 475, 522, 40, 151, 648, 313, 408, 188, 634, 145,
  368, 158, 504, 514, 642, 405, 322, 2, 93, 51, 327, 487, 132, 641, 5, 531, 222, 526, 560, 638, 229,
  11, 546, 33, 315, 599, 660, 277, 71, 276, 23, 668, 119, 72, 517, 88, 171, 556, 640, 631, 476, 616,
  432, 652, 596, 59, 65, 586, 230, 323, 470, 47, 597, 215, 16, 183, 386, 617, 510, 398, 168, 12,
  262, 410, 225, 489, 464, 137, 610, 537, 519, 678, 198, 32, 269, 622, 324, 565, 84, 492, 482, 654,
  152, 585, 205, 48, 150, 133, 416, 342, 578, 203, 483, 369, 236, 261, 340, 488, 37, 420, 444, 200,
  303, 336, 508, 4, 307, 233, 393, 661, 148, 627, 64, 435, 461, 278, 530, 376, 285, 663, 66, 545,
  572, 304, 657, 575, 499, 452, 120, 650, 118, 227, 563, 160, 213, 582, 521, 43, 363, 136, 548, 181,
  614, 73, 204, 436, 141, 419, 471, 417, 378, 414, 443, 239, 178, 418, 381, 403, 34, 339, 474, 644,
  306, 146, 333, 265, 646, 348, 515, 105, 56, 319, 67, 216, 54, 27, 224, 543, 371, 402, 511, 106,
  675, 257, 187, 206, 193, 446, 177, 49, 78, 602, 314, 44, 658, 95, 395, 101, 579, 253, 361, 633,
  231, 144, 666, 153, 125, 527, 296, 297, 628, 211, 603, 659, 292, 364, 373, 223, 170, 308, 438,
  312, 647, 235, 598, 645, 329, 600, 121, 561, 497, 182, 338, 467, 396, 104, 185, 607, 14, 75, 53,
  219, 87, 384, 202, 68, 595, 36, 258, 10, 540, 252, 80, 17, 383, 385, 441, 562, 463, 154, 274, 676,
  69, 574, 300, 109, 570, 28, 559, 283, 123, 180, 99, 31, 325, 13, 377, 210, 172, 449, 189, 149,
  623, 163, 309, 618, 673, 197, 94, 486, 242, 576, 166, 669, 46, 437, 538, 520, 220, 587, 26, 536,
  7, 509, 38, 97, 674, 609, 267, 190, 98, 156, 288, 60, 651, 421, 107, 592, 365, 317, 284, 293, 629,
  316, 330, 472, 465, 143, 630, 254, 82, 518, 653, 413, 564, 411, 404, 212, 179, 625, 665, 310, 532,
  349, 79, 447, 462, 516, 167, 442, 311, 52, 374, 593, 77, 62, 547, 332, 281, 266, 142, 541, 679,
  612, 63, 130, 344, 392, 639, 655, 334, 70, 341, 434, 264, 55, 450, 466, 326, 557, 291, 218, 108,
  525, 19, 9, 496, 280, 375, 124, 331, 359, 103, 571, 186, 523, 155, 490, 201, 580, 632, 162, 232,
  221, 581, 454, 445, 328, 643, 208, 397, 147, 667, 21, 362, 533, 473, 6, 244, 42, 100, 83, 135,
  534, 164, 412, 238, 305, 528, 343, 24, 286, 635, 680, 318, 406, 503, 507, 287, 423, 173, 335, 184,
  468, 620, 256, 500, 138, 30, 85, 116, 422, 207, 605, 662, 608, 439, 192, 621, 346, 243, 45, 122,
];

const SentenceTwoAnswer = [
  551, 320, 427, 249, 29, 458, 493, 176, 134, 113, 485, 175, 387, 367, 554, 589, 57, 321, 506, 457,
  246, 248, 494, 357, 440, 110, 555, 431, 351, 247, 372, 195, 270, 89, 174, 8, 477, 114, 354, 271,
  484, 478, 366, 390, 128, 480, 568, 126, 553, 604, 451, 460, 92, 289, 115, 481, 356, 479, 429, 127,
  391, 294, 112, 226, 459, 129, 577, 250, 18, 260, 111, 469, 428, 529, 550, 505, 588, 15, 456, 259,
  355, 388, 569, 251, 352, 196, 389, 430, 90, 61, 41, 299, 76, 290, 353, 552, 455,
];

const TestIndexMap = {
  picture: {
    start: 681,
    end: 780,
  },
  illust: {
    start: 781,
    end: 865,
  },
  sign: {
    start: 866,
    end: 965,
  },
  video: {
    start: 966,
    end: 1000,
  },
};
const Picture = range(TestIndexMap.picture.start, TestIndexMap.picture.end, 1);

const Illust = range(TestIndexMap.illust.start, TestIndexMap.illust.end, 1);

const Sign = range(TestIndexMap.sign.start, TestIndexMap.sign.end, 1);

const Video = range(TestIndexMap.video.start, TestIndexMap.video.end, 1);

export const getRandomExam = () => {
  return [
    ...sampleSize(SentenceOneAnswer, 17),
    ...sampleSize(SentenceTwoAnswer, 4),
    ...sampleSize(Picture, 6),
    ...sampleSize(Illust, 7),
    ...sampleSize(Sign, 5),
    ...sampleSize(Video, 1),
  ];
};
