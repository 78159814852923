import { BaseModel } from '@db/base-models';

export class UserVerification extends BaseModel {
  isVerified: boolean;
  verificationDate: Date;

  constructor({ isVerified, verificationDate }: { isVerified: boolean; verificationDate: Date }) {
    super('UserVerification');
    this.isVerified = isVerified;
    this.verificationDate = verificationDate;
  }
}
