import { BASE_MODELS_MAP, BaseModel } from '@db/base-models';
import { COLLECTION_MAP } from '@db/collections';
import SuperJSON from 'superjson';

export const registCustomSuperJSON = () => {
  SuperJSON.registerCustom<BaseModel, string>(
    {
      isApplicable: (v): v is BaseModel => v instanceof BaseModel,
      serialize: (v) => {
        return { ...v.get(), _DT_MODEL: v.getInstanceName() };
      },
      deserialize: (v) => {
        const { _DT_MODEL, ...rest } = v as any;

        if (Object.keys(BASE_MODELS_MAP).includes(_DT_MODEL)) {
          return new BASE_MODELS_MAP[_DT_MODEL as keyof typeof BASE_MODELS_MAP]({
            ...rest,
          });
        }
        if (Object.keys(COLLECTION_MAP).includes(_DT_MODEL)) {
          return new COLLECTION_MAP[_DT_MODEL as keyof typeof COLLECTION_MAP]({
            ...rest,
          });
        }

        return { ...rest } as any;
      },
    },
    'custom-model',
  );
};
