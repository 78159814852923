interface z2k {
  [key: number]: number;
}

export const Zoom2Km: z2k = {
  9: 30,
  10: 20,
  11: 10,
  12: 5,
  13: 3.5,
  14: 0.5,
};
