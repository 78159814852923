'use client';

import * as React from 'react';

import { DSImage, Portal, Spacer } from '@ds/components/reusable';
import { cn } from 'tailwind-config';

import { DialogButton } from './components';

interface DialogProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  image?: {
    alt: string;
    src: string;
  };
  title: string;
  body: React.ReactNode;
  buttons: React.ReactElement<typeof DialogButton>[];
  buttonMatrix?: 'horizontal' | 'vertical';
  className?: string;
  close?: () => void;
  isOpen?: boolean;
}

const Dialog = React.forwardRef<HTMLElement, DialogProps>(
  (
    { image, title, body, buttonMatrix = 'horizontal', buttons, className, close, isOpen = true },
    ref,
  ) => {
    if (!isOpen) return null;

    return (
      <Portal onClickBackground={close}>
        <div
          ref={ref as React.Ref<HTMLDivElement>}
          className={cn(
            'bg-new-white-100 absolute left-1/2 top-1/2 flex min-h-[164px] min-w-[312px] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center rounded-3xl px-16 pb-24 pt-32',
            className,
          )}>
          {image && (
            <>
              <DSImage src={image.src} alt={image.alt} width={72} height={72} />
              <Spacer className='h-10' />
            </>
          )}
          <div className='flex flex-col items-center px-16'>
            <div className='text-new-Sub-Title text-new-gray-900 whitespace-pre-wrap text-center'>
              {title}
            </div>
            <Spacer className='h-10' />
            <div className='text-new-Body2-medium text-new-gray-500 whitespace-pre-wrap text-center'>
              {body}
            </div>
          </div>
          <Spacer className='h-24' />
          <div
            className={cn(
              'flex w-full',
              buttonMatrix === 'horizontal' ? 'justify-around' : 'flex-col gap-12',
            )}>
            {buttons.map((button, index) => {
              return <React.Fragment key={`DialogButton-${index}`}>{button}</React.Fragment>;
            })}
          </div>
        </div>
      </Portal>
    );
  },
);

export { Dialog, DialogButton };
