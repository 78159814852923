import { db } from 'firebase-config';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { findMethodAndReplace, shuffleArray } from 'shared-values';

import { Blog } from '../models';

export const getBlogsRandom = async ({
  queryKey,
}: QueryFn<[string, string, number]>): Promise<Blog[]> => {
  const [, , limitNum] = queryKey;
  const result = [];

  const q = query(collection(db, 'Blog'), orderBy('createdAt', 'desc'));

  const querySnapshot = await getDocs(q);

  for await (const doc of querySnapshot.docs) {
    const docData = { ...doc.data(), id: doc.id } as BlogData;
    findMethodAndReplace(docData, 'toDate');
    result.push(new Blog(docData));
  }

  shuffleArray(result);

  return result.slice(0, limitNum) as Blog[];
};
