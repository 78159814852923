import { QueryModel } from '@db/query-models';
import { DocumentReference } from 'firebase/firestore';

import { Address } from './address';

export class AcademyGeoJsons extends QueryModel {
  addresses: Address[];
  geoJsons: DocumentReference[];

  constructor({ addresses, geoJsons, queryClient, queryKey }: DataModel<AcademyGeoJsonsData>) {
    super({
      queryClient,
      queryKey,
      instanceConstructor: AcademyGeoJsons,
      className: 'AcademyGeoJsons',
    });
    this.addresses = addresses.map((address) => new Address(address));
    this.geoJsons = geoJsons;
  }

  get bubAddresses() {
    return this.addresses.map((address) => address.bubAddress);
  }
}
