import { BaseList } from './base-list';

// 모든 모델의 부모 클래스
export class BaseModel<T = any> {
  protected _className: string;

  constructor(className: string) {
    this._className = className;
  }

  // 모든 프로퍼티를 가져오는 메소드
  // 클라이언트에서 모델로 사용되던 객체들을 firebase에 업데이트 해야하는 경우
  // client model => firebase json 형태로 변환해주는 메서드
  // updateDoc 과 같은 mutation을 진행할 때 이 메서드를 꼭 호출하자!
  get(excludeKeys: string[] = []): T {
    const properties = {};

    // 현재 인스턴스에 대한 모든 프로퍼티를 반복
    for (const key of Object.keys(this)) {
      if (['_className', ...excludeKeys].includes(key)) {
        continue;
      }

      const value = (this as any)[key];

      // 객체나 배열의 경우 재귀적으로 탐색
      if (value instanceof BaseModel) {
        (properties as any)[key] = value.get();
      } else if (value instanceof BaseList) {
        (properties as any)[key] = value.get();
      } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof BaseModel) {
        (properties as any)[key] = value.map((item) => item.get());
      }
      // firebase에서 undefined를 허용하지 않으므로, undefined인 값은 삭제
      else if (value !== undefined) {
        (properties as any)[key] = value;
      }
    }

    return properties as T;
  }

  // 개발 시에는 this._instanceConstructor.name;로 가져오면 되지만,
  // 빌드 시에 클래스 이름이 난독화되므로 string으로 직접 넘겨줘야 함
  public getInstanceName() {
    return this._className;
  }
}
