import { LESSONS } from 'shared-values';

import { BaseList } from '../../../../base-models';

export class LessonList extends BaseList {
  lessons: Lesson[];

  constructor(lessons: Lesson[]) {
    super({ items: lessons, className: 'LessonList' });
    this.lessons = lessons;
  }

  public sort() {
    this.lessons = this.lessons
      .sort((a, b) => LESSONS.indexOf(a.code) - LESSONS.indexOf(b.code))
      .sort((a, b) => {
        if (a.isPopular && !b.isPopular) {
          return -1;
        }
        if (!a.isPopular && b.isPopular) {
          return 1;
        }
        return 0;
      });

    return this;
  }
}
