import { QueryFunctionContext } from '@tanstack/react-query';
import { db } from 'firebase-config';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';

export const getExerciseCount = async ({
  queryKey,
}: QueryFunctionContext<[string, string, string, string]>) => {
  const [, type, choicesNum, answerNum] = queryKey;
  const q = query(
    collection(db, 'NewExercise'),
    where('type', '==', type),
    where('choicesNum', '==', Number(choicesNum)),
    where('answerNum', '==', Number(answerNum)),
  );

  const snapshot = await getCountFromServer(q);

  return snapshot.data().count;
};
