import { produce } from 'immer';
import { create } from 'zustand';

type ReviewInputStatus = '초기화' | '최초입력' | '재입력';

type ReviewState = {
  status: {
    lectureProgress: ReviewInputStatus;
    rate: ReviewInputStatus;
  };
  lectureProgress: string;
  rate: {
    facility: number;
    lecture: number;
    service: number;
    average: number;
  };
  reviewText: string;
  reviewImages: File[];
  reviewLessonCode: string;
  dispatch: (args: ReviewStateAction) => void;
};

type ReviewStateAction =
  | {
      type: '수업업데이트';
      payload: string;
    }
  | {
      type: '진행단계업데이트';
      payload: string;
    }
  | {
      type: '시설별점업데이트';
      payload: number;
    }
  | {
      type: '강의별점업데이트';
      payload: number;
    }
  | {
      type: '서비스별점업데이트';
      payload: number;
    }
  | {
      type: '리뷰텍스트업데이트';
      payload: string;
    }
  | {
      type: '리뷰이미지업데이트';
      payload: File[];
    }
  | {
      type: '리뷰이미지삭제';
      payload: number;
    }
  | {
      type: '초기화';
    };

const initialReviewState: Omit<ReviewState, 'dispatch'> = {
  status: {
    lectureProgress: '초기화',
    rate: '초기화',
  },
  lectureProgress: '',
  rate: {
    facility: 0,
    lecture: 0,
    service: 0,
    average: 0,
  },
  reviewText: '',
  reviewImages: [],
  reviewLessonCode: '',
};

const reviewStateReducer = (state: ReviewState, action: ReviewStateAction) => {
  switch (action.type) {
    case '진행단계업데이트':
      return produce(state, (draft) => {
        draft.lectureProgress = action.payload;
        if (draft.status.lectureProgress === '초기화') {
          draft.status.lectureProgress = '최초입력';
        } else {
          draft.status.lectureProgress = '재입력';
        }
      });
    case '시설별점업데이트':
      return produce(state, (draft) => {
        draft.rate.facility = action.payload;

        const { facility, lecture, service } = draft.rate;

        if (facility && lecture && service) {
          draft.rate.average = Number(Math.round((facility + lecture + service) / 3).toFixed(1));

          if (draft.status.rate === '초기화') {
            draft.status.rate = '최초입력';
          } else {
            draft.status.rate = '재입력';
          }
        }
      });
    case '강의별점업데이트':
      return produce(state, (draft) => {
        draft.rate.lecture = action.payload;

        const { facility, lecture, service } = draft.rate;

        if (facility && lecture && service) {
          draft.rate.average = Number(((facility + lecture + service) / 3).toFixed(1));

          if (draft.status.rate === '초기화') {
            draft.status.rate = '최초입력';
          } else {
            draft.status.rate = '재입력';
          }
        }
      });
    case '서비스별점업데이트':
      return produce(state, (draft) => {
        draft.rate.service = action.payload;

        const { facility, lecture, service } = draft.rate;

        if (facility && lecture && service) {
          draft.rate.average = Number(((facility + lecture + service) / 3).toFixed(1));

          if (draft.status.rate === '초기화') {
            draft.status.rate = '최초입력';
          } else {
            draft.status.rate = '재입력';
          }
        }
      });
    case '리뷰텍스트업데이트':
      return produce(state, (draft) => {
        draft.reviewText = action.payload;
      });
    case '리뷰이미지업데이트':
      return produce(state, (draft) => {
        draft.reviewImages = action.payload;
      });
    case '리뷰이미지삭제':
      return produce(state, (draft) => {
        draft.reviewImages.splice(action.payload, 1);
      });
    case '초기화':
      return {
        ...initialReviewState,
        dispatch: state.dispatch,
      };
    case '수업업데이트':
      return produce(state, (draft) => {
        draft.reviewLessonCode = action.payload;
      });
    default:
      return state;
  }
};

export const useReviewState = create<ReviewState>((set) => {
  return {
    ...initialReviewState,
    dispatch: (args: ReviewStateAction) => set((state) => reviewStateReducer(state, args)),
  };
});
