import { BaseModel } from './base-model';

export class SelectedRange extends BaseModel<SelectedRangeData> {
  startDate: Date;
  endDate: Date;

  constructor({ startDate, endDate }: SelectedRangeData) {
    super('SelectedRange');
    this.startDate = new Date(startDate);
    this.endDate = new Date(endDate);
  }

  public setTimeZone() {
    this.startDate = new Date(
      this.startDate.getTime() - this.startDate.getTimezoneOffset() * 60000,
    );
    this.endDate = new Date(this.endDate.getTime() - this.endDate.getTimezoneOffset() * 60000);

    return this;
  }
}
