import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getCouponCodesByAcademyId } from '../calls/get-coupon-codes-by-academy-id';

export const useGetAcademyCouponCodes = ({
  academyId = '',
  enabled = true,
  initialData,
  condition,
  isValidCoupon = false,
}: {
  academyId?: string;
  enabled?: boolean;
  initialData?: () => CouponCode[] | undefined;
  condition?: DiscountCondition;
  isValidCoupon?: boolean;
}) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.COUPON_CODE, academyId, isValidCoupon, condition],
    queryFn: getCouponCodesByAcademyId,
    enabled: academyId.length > 0 && enabled,
    initialData,
  });

  return { ...result };
};
