import { QUERY_KEY } from '@db/constants';
import { useInfiniteQueryWithClient } from '@db/hooks';
import { QueryDocumentSnapshot } from 'firebase/firestore';

import { getReviewsByAcademyId } from '../calls';

export const useGetReviewsByAcademyId = ({
  academyId,
  sortOption = 'createdAtDesc',
  license = 'all',
  state = 'published',
}: {
  academyId: string;
  sortOption?: 'createdAtDesc' | 'likeCountDesc' | 'rateDesc' | 'rateAsc';
  license?: LicenseCode | 'all';
  state?: ReviewData['state'];
}) => {
  return useInfiniteQueryWithClient({
    queryKey: [QUERY_KEY.REVIEW, academyId, sortOption, license, state] as [
      string,
      string,
      string,
      string,
      ReviewData['state'],
    ],
    queryFn: getReviewsByAcademyId,
    enabled: academyId.length > 0,
    getNextPageParam: (querySnapshot) =>
      querySnapshot.nextPageParam as QueryDocumentSnapshot<Review>,
    initialPageParam: undefined,
  });
};
