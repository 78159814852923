export const ERROR_MESSAGE = {
  WRONG_APPROACH: '잘못된 접근입니다.',
  DUPLICATE_PHONENUM:
    '동일한 휴대폰 번호로 가입한 계정이 존재합니다. 해당 계정으로 로그인 해주세요.',
  SAME_PHONENUM: '현재와 같은 번호는 지정할 수 없습니다.',
  NO_EXIST_PHONENUM: '전화번호가 존재하지 않습니다.',
  INVALID_NAME: '이름이 올바르지 않습니다. 다시 입력해주세요.',
  INVALID_CODE: '인증 번호가 틀립니다!',
  VERIFY_EXCEEDED: '더 이상 본인인증을 시도할 수 없습니다.',
  SIGNIN_ERROR: '회원가입하는데 오류가 발생했습니다. 다시 시도해주세요.',
  COPY_ERROR: '복사에 실패했습니다. 다시 시도해주세요.',
  AUTH_ERROR: '회원 인증에 실패했습니다. 재로그인 후 시도해주세요.',
  SERVER_ERROR: '현재 서버에 문제가 있습니다. 잠시 후에 다시 시도해주세요.',
  UNDEFINED: '알 수 없는 에러가 발생했습니다. 잠시 후에 다시 시도해주세요.',
};

export const SUCCESS_MESSAGE = {
  ADD_SUCCESS: '추가했습니다!',
  SEND_CODE: '인증 번호가 전송되었습니다!',
  CERTIFICATE_SUCCESS: '인증에 성공했습니다!',
};
