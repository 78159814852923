import { useEffect } from 'react';

import { focusManager } from '@tanstack/react-query';

import { isAppApproaching } from '@utils/Common';

const isAppApproach = isAppApproaching();

/**
 * 앱에서 외부 화면으로 나갔다가 돌아왔을 때 작동하는 callback (Ex : 알림 권한 변경을 위해 설정으로 이동했다가 돌아옴)
 * 웹 동작 : visibilitychange 이벤트를 사용
 * 앱 동작 : receiveResumeCallback 이벤트를 사용 (앱에서 전송해줌)
 */
export const callbackWhenResume = (callback: (arg?: any) => void) => {
  window.addEventListener('visibilitychange', callback as any);
  // 앱일 때는 receiveResumeCallback라는 customEvent를 사용해서 focus를 감지한다.
  if (isAppApproach) {
    window.addEventListener('receiveResumeCallback', callback as any);
  }

  return () => {
    window.removeEventListener('visibilitychange', callback as any);
    if (isAppApproach) {
      window.removeEventListener('receiveResumeCallback', callback as any);
    }
  };
};

// react-query의 focusManager를 사용하기 위한 hook
// 시간 되면 useQueryFocusEffect를 가지는 useFocusQuery를 제작해보는 걸로 (type 문제로 일단은 hold)
export const useQueryFocusEffect = () => {
  useEffect(() => {
    focusManager.setEventListener((handleFocus) => {
      return callbackWhenResume(handleFocus);
    });
  }, []);
};
