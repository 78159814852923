import { QueryModel } from '@db/query-models';

export class Blog extends QueryModel {
  id: string;
  content: string;
  markdown?: string;
  meta: {
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
    ogDescription: string;
    ogTitle: string;
    ogImage: string;
  };
  hashTags: string[];
  createdAt: Date;
  image: string;
  title: string;
  isBest?: boolean;
  cta?: {
    href: string;
    text: string;
  };

  constructor({
    id,
    content,
    markdown,
    meta,
    hashTags,
    createdAt,
    image,
    title,
    isBest,
    queryClient,
    queryKey,
    cta,
  }: DataModel<BlogData>) {
    super({ queryClient, queryKey, instanceConstructor: Blog, className: 'Blog' });
    this.id = id;
    this.content = content;
    this.markdown = markdown;
    this.meta = meta;
    this.hashTags = hashTags;
    this.createdAt = createdAt;
    this.image = image;
    this.title = title;
    this.isBest = isBest;
    this.cta = cta;
  }
}
