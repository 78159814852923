import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { atomWithSuperJsonLocalStorage } from './storage';

export const FilterDefaultValue: AcademyFilters = {
  lesson: { value: 'TWO_AUTO_NEW', label: '2종 자동' },
  reservationRange: { value: null, label: '' },
  sortOrder: { value: '가까운 순', label: '가까운 순' },
  shuttle: { value: [], label: '' },
  isOnlyBookable: { value: true, label: '' },
  additionalProperties: { value: [], label: '' },
};

export const filterStateAtom = atomWithReset<AcademyFilters>(FilterDefaultValue);

export const searchedAcademyStateAtom = atom<boolean>(false);

export const recentSearchStateAtom = atomWithSuperJsonLocalStorage<
  SearchAcademyData[] | SearchAddressData[]
>('recentSearchState', []);

export const simulationLaunchCoachMarkStateAtom = atomWithSuperJsonLocalStorage<{
  onExplore: boolean;
  onAcademy: string[];
  onEvent: boolean;
}>('simulationLaunchCoachMark', {
  onExplore: true,
  onAcademy: [],
  onEvent: true,
});
