import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getAcademiesByIds } from '../calls';

export const useGetAcademiesByIds = (indexes: (number | string)[]) => {
  const result = useQueryWithClient({
    queryFn: getAcademiesByIds,
    queryKey: [QUERY_KEY.INDEXES_ACADEMIES, indexes],
  });

  return result;
};
