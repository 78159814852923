import { useCallback, useState } from 'react';

export const useGeolocation = () => {
  const [location, setLocation] = useState<{
    lat?: number;
    lng?: number;
  }>({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getGeolocation = useCallback(() => {
    if (!navigator.geolocation) {
      setError('Geolocation is not supported by your browser');
      return;
    }
    setIsLoading(true);

    const handleSuccess: PositionCallback = (position) => {
      setLocation({
        lat: Number(position.coords.latitude),
        lng: Number(position.coords.longitude),
      });
      setIsLoading(false);
    };

    const handleError: PositionErrorCallback = (error) => {
      setError(error.message);
      setIsLoading(false);
    };

    const geoOptions = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(handleSuccess, handleError, geoOptions);
  }, []);

  return { isLoading, location, error, getGeolocation };
};
