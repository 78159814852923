export const getDataWithReactQuery = async <T>({
  queryClient,
  queryKey,
  queryFn,
}: Optional<QueryFn, 'queryClient'> & {
  queryFn(arg: QueryFn): Promise<T>;
}) => {
  if (!queryClient || !queryKey)
    throw new Error('데이터 변경시에는 queryClient와 queryKey가 필요합니다.');

  // await queryClient.fetchQuery로 대체?
  const queryCache = queryClient.getQueryCache();

  const existCache = queryCache.find({ queryKey });

  if (existCache) {
    return existCache.state.data as T;
  }

  const docData = (await queryFn({ queryKey, queryClient })) as T;
  queryClient.setQueryData(queryKey, docData);

  return docData;
};
