import { QueryFunctionContext } from '@tanstack/react-query';
import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';

export const getVerification = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<Verification | null> => {
  const [, id] = queryKey;
  if (!id) return null;
  const docRef = doc(db, 'Verification', id);
  const docSnap = (await getDoc(docRef)).data() as Verification;
  return docSnap;
};
