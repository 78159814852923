import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';

import { AcademyGeoJsons } from '../models';

export const getAcademyGeoJsons = async ({ queryKey, queryClient }: QueryFn) => {
  const [, academyId] = queryKey as [string, string];

  const geoJsonDocRef = doc(db, 'AcademyGeoJsons', academyId);
  const geoJsonDocQuery = await getDoc(geoJsonDocRef);

  if (!geoJsonDocQuery.exists()) throw new Error('geoJsonDocQuery is not exists');

  const docData = geoJsonDocQuery.data() as AcademyGeoJsonsData;

  return new AcademyGeoJsons({ ...docData, queryClient, queryKey });
};
