import { db } from 'firebase-config';
import {
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';

import { Exercise, ExerciseList } from '../models';

export const getMainExercises = async ({
  queryKey,
  queryClient,
  pageParam,
}: QueryFn<[string], QueryDocumentSnapshot<Exercise> | null>) => {
  const result: Exercise[] = [];

  let q = null;
  if (pageParam) {
    q = query(
      collection(db, 'NewMainExercise'),
      orderBy('index'),
      limit(10),
      startAfter(pageParam),
    );
  } else {
    q = query(collection(db, 'NewMainExercise'), orderBy('index'), limit(10));
  }

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const docData = {
      ...doc.data(),
      id: doc.id,
    } as ExerciseData;

    result.push(new Exercise(docData));
  });

  let nextPageParam = null;
  if (querySnapshot.size >= 10) {
    nextPageParam = querySnapshot.docs[querySnapshot.docs.length - 1];
  }

  return { data: new ExerciseList({ items: result, queryClient, queryKey }), nextPageParam };
};
