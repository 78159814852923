import colorObjects from '../styles/themes/color.json';

export default {
  PC: {
    '.text-new-Title1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '-1.44px',
    },
    '.text-new-Title2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '-1.08px',
    },
    '.text-new-Title3': {
      fontFamily: 'Pretendard Variable',
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '-0.84px',
    },
    '.text-new-Blog-Title-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '-0.84px',
    },
    '.text-new-Blog-Title-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: '-0.84px',
    },
    '.text-new-Blog-Sub-Title-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-0.6px',
    },
    '.text-new-Blog-Sub-Title-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '-0.6px',
    },
    '.text-new-Blog-Body-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: 1.9,
      letterSpacing: '-0.54px',
    },
    '.text-new-Blog-Body-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: 1.9,
      letterSpacing: '-0.54px',
    },
    '.text-new-Sub-Title': {
      fontFamily: 'Pretendard Variable',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-0.72px',
    },
    '.text-new-Section-Title': {
      fontFamily: 'Pretendard Variable',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-0.54px',
    },
    '.text-new-Body1-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '-0.48px',
    },
    '.text-new-Body1-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '-0.48px',
    },
    '.text-new-Body1-light': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: '-0.48px',
    },
    '.text-new-Body2-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '-0.42px',
    },
    '.text-new-Body2-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '-0.42px',
    },
    '.text-new-Body2-light': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: '-0.28px',
    },
    '.text-new-Caption1-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '-0.36px',
    },
    '.text-new-Caption1-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '-0.36px',
    },
    '.text-new-Caption1-light': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: '-0.36px',
    },
    '.text-new-Caption2-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-0.33px',
    },
    '.text-new-Caption2-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '11px',
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '-0.33px',
    },
  },
  MOBILE: {
    '.text-new-Title1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '34px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-1.02px',
    },
    '.text-new-Title2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-0.84px',
    },
    '.text-new-Title3': {
      fontFamily: 'Pretendard Variable',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-0.72px',
    },
    '.text-new-Blog-Title-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '-0.84px',
    },
    '.text-new-Blog-Title-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: '-0.84px',
    },
    '.text-new-Blog-Sub-Title-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-0.6px',
    },
    '.text-new-Blog-Sub-Title-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '-0.6px',
    },
    '.text-new-Blog-Body-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: 1.9,
      letterSpacing: '-0.54px',
    },
    '.text-new-Blog-Body-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: 1.9,
      letterSpacing: '-0.54px',
    },
    '.text-new-Sub-Title': {
      fontFamily: 'Pretendard Variable',
      fontSize: '21px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-0.63px',
    },
    '.text-new-Section-Title': {
      fontFamily: 'Pretendard Variable',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-0.54px',
    },
    '.text-new-Body1-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '-0.48px',
    },
    '.text-new-Body1-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '-0.48px',
    },
    '.text-new-Body1-light': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: '-0.48px',
    },
    '.text-new-Body2-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '-0.42px',
    },
    '.text-new-Body2-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '-0.42px',
    },
    '.text-new-Body2-light': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: '-0.28px',
    },
    '.text-new-Caption1-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '-0.36px',
    },
    '.text-new-Caption1-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '-0.36px',
    },
    '.text-new-Caption1-light': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: '-0.36px',
    },
    '.text-new-Caption2-bold': {
      fontFamily: 'Pretendard Variable',
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '-0.33px',
    },
    '.text-new-Caption2-medium': {
      fontFamily: 'Pretendard Variable',
      fontSize: '11px',
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '-0.33px',
    },
  },
  LEGACY: {
    '.text-Header': {
      fontFamily: 'Pretendard Variable',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: 1,
      color: colorObjects['gray-900'],
      letterSpacing: '-0.4px',
    },
    '.text-Title1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 1.5,
      color: colorObjects['gray-900'],
      letterSpacing: '-0.4px',
    },
    '.text-Title2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: 1.5,
      color: colorObjects['gray-900'],
      letterSpacing: '-0.4px',
    },
    '.text-SubTitle': {
      fontFamily: 'Pretendard Variable',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: 1.5,
      color: colorObjects['gray-900'],
      letterSpacing: '-0.3px',
    },
    '.text-Section-Title': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 1.5,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.4px',
    },
    '.text-BlogBody1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 1.7,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.4px',
    },
    '.text-BlogBody2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.7,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.4px',
    },
    '.text-Body1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 1.5,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.4px',
    },
    '.text-Body2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.5,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.4px',
    },
    '.text-Body3': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: 1.5,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.4px',
    },
    '.text-Body4': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 1.5,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.4px',
    },
    '.text-Body5': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.5,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.4px',
    },
    '.text-Body6': {
      fontFamily: 'Pretendard Variable',
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: 1.6,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.3px',
    },
    '.text-Body7': {
      fontFamily: 'Pretendard Variable',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: 1.6,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.3px',
    },
    '.text-Detail1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: 1.6,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.3px',
    },
    '.text-Detail2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 1.6,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.3px',
    },
    '.text-Detail3': {
      fontFamily: 'Pretendard Variable',
      fontSize: '11px',
      fontWeight: 600,
      lineHeight: 1.6,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.3px',
    },
    '.text-Line': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.4px',
      textDecoration: 'underline',
    },
    '.text-Line2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1,
      color: colorObjects['gray-600'],
      letterSpacing: '-0.4px',
      textDecoration: 'underline',
    },
    '.text-Score': {
      fontFamily: 'Pretendard Variable',
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: 1,
      color: colorObjects['gray-800'],
      letterSpacing: '-0.6px',
    },
    '.text-Tag1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 1,
      color: colorObjects['gray-900'],
      letterSpacing: '-0.2px',
    },
    '.text-Tag2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: 1,
      color: colorObjects['gray-900'],
      letterSpacing: '-0.2px',
    },
    '.text-Chip': {
      fontFamily: 'Pretendard Variable',
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: 1,
      color: colorObjects['gray-900'],
      letterSpacing: '-0.33px',
    },
    '.text-H1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '28px',
      fontWeight: 800,
      lineHeight: '40px',
    },
    '.text-H2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '24px',
      fontWeight: 800,
      lineHeight: '38px',
    },
    '.text-H3': {
      fontFamily: 'Pretendard Variable',
      fontSize: '22px',
      fontWeight: 800,
      lineHeight: '32px',
    },
    '.text-H4': {
      fontFamily: 'Pretendard Variable',
      fontSize: '20px',
      fontWeight: 800,
      lineHeight: '30px',
    },
    '.text-T1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '30px',
    },
    '.text-T2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '30px',
    },
    '.text-T3': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 800,
      lineHeight: '26px',
    },
    '.text-T4': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
    },
    '.text-T5': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
    },

    '.text-B1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '28px',
    },
    '.text-B2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '28px',
    },
    '.text-B3': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
    },
    '.text-B4': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    '.text-B5': {
      fontFamily: 'Pretendard Variable',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '19px',
    },
    '.text-D1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
    },
    '.text-D2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '18px',
    },
    '.text-D3': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px',
    },
    '.text-D4': {
      fontFamily: 'Pretendard Variable',
      fontSize: '11px',
      fontWeight: 500,
      lineHeight: '16px',
    },
    '.text-Bt1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '16px',
      fontWeight: 800,
      lineHeight: '21px',
    },
    '.text-Bt2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 800,
      lineHeight: '18px',
    },
    '.text-Bt3': {
      fontFamily: 'Pretendard Variable',
      fontSize: '12px',
      fontWeight: 800,
      lineHeight: '16px',
    },
    '.text-L1': {
      fontFamily: 'Pretendard Variable',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    '.text-L2': {
      fontFamily: 'Pretendard Variable',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '13px',
    },
  },
};
