'use client';

import { motion } from 'framer-motion';
import { cn } from 'tailwind-config';

/**
 * Pressed 기능을 사용하고 싶은 곳에서 자식으로 사용합니다.
 * isForced : 유저의 입력을 무시하고 강제로 Pressed 상태를 만들고 싶을 때 사용합니다.
 */
export const Pressed = ({ isForced = false }: { isForced?: boolean }) => {
  return (
    <motion.div
      whileTap={{
        backgroundColor: 'rgba(0,0,0,0.03)',
      }}
      className={cn(
        'absolute right-0 top-0 z-10 h-full w-full rounded-[inherit]',
        isForced && 'bg-[rgba(0,0,0,0.03)]',
      )}
    />
  );
};
