'use client';

import React, { SVGProps, useEffect, useState } from 'react';

import { cn } from 'tailwind-config';

import newIconMapping from '../../../assets/icon-mapping';

export type NewIconType = keyof typeof newIconMapping;

interface IconProps extends Omit<SVGProps<SVGSVGElement>, 'fill'> {
  icon: NewIconType;
  size?: number;
}

const defaultSize = 24;

export const NewIcon = ({ icon, className, size, ...props }: IconProps) => {
  const [loading, setLoading] = useState(false);
  const [ImportedIcon, setImportedIcon] = useState<React.FC<React.SVGProps<SVGElement>>>();
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    const importSvgIcon = async (): Promise<void> => {
      // vite에서 svg 아이콘 불러오기
      // https://stackoverflow.com/questions/70309561/unable-to-import-svg-with-vite-as-reactcomponent
      try {
        setLoading(true);
        const newIcon = (await import(`../../../assets/icon/${icon}.svg?react`)).default;
        setImportedIcon(() => newIcon);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    importSvgIcon();
  }, [icon]);

  // TODO: skeleton 추가
  if (loading || error || !ImportedIcon) {
    return (
      <div
        style={{
          // TODO : defaultSize를 변경할 수 있도록 수정
          width: size ?? defaultSize,
          height: size ?? defaultSize,
        }}
      />
    );
  }

  return (
    <>
      <div className="leading-[0px]">
        <ImportedIcon
          className={cn('inline-block flex-shrink-0', className)}
          style={
            size
              ? {
                  width: size,
                  height: size,
                }
              : {}
          }
          {...props}
        />
      </div>
    </>
  );
};
