import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getRecommendationAcademies } from '../calls';
import { AcademyLocation } from '../models';

export const useGetRecommendationAcademies = ({
  academyLocation,
  isSimulation,
  items,
}: {
  academyLocation: AcademyLocation;
  isSimulation?: boolean;
  items?: number;
}) => {
  const result = useQueryWithClient({
    queryKey: [
      QUERY_KEY.RECOMMEND_ACADEMIES,
      academyLocation as AcademyLocation,
      isSimulation as boolean,
      items as number,
    ],
    queryFn: getRecommendationAcademies,
    enabled: !!academyLocation,
  });

  return result;
};
