import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { UnivEventCertification } from '../models';

export const getUnivEventCertification = async ({ queryKey, queryClient }: QueryFn) => {
  const [, id] = queryKey as [string, string];

  const docRef = doc(db, 'UnivEventCertification', id);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) throw new Error('Document does not exist');

  const docData = { id, ...docSnap.data() } as UnivEventCertificationData;

  findMethodAndReplace(docData, 'toDate');
  return new UnivEventCertification({ ...docData, queryClient, queryKey });
};
