import { BaseModel } from '@db/base-models';

export class SmallmallInfo extends BaseModel {
  smbsCoNo: string;
  smbsCoNm: string;
  smbsRepNm: string;
  smbsPostNo: string;
  smbsAddrNo1: string;
  smbsAddrNo2: string;
  smbsTelNo: string;
  smbsEmail: string;
  smbsBsKindNm: string;
  smbsUrl: string;
  smbsRank: string;

  constructor({
    smbsCoNo,
    smbsCoNm,
    smbsRepNm,
    smbsPostNo,
    smbsAddrNo1,
    smbsAddrNo2,
    smbsTelNo,
    smbsEmail,
    smbsBsKindNm,
    smbsUrl,
    smbsRank,
  }: SmallmallInfoData) {
    super('SmallmallInfo');
    this.smbsCoNo = smbsCoNo;
    this.smbsCoNm = smbsCoNm;
    this.smbsRepNm = smbsRepNm;
    this.smbsPostNo = smbsPostNo;
    this.smbsAddrNo1 = smbsAddrNo1;
    this.smbsAddrNo2 = smbsAddrNo2;
    this.smbsTelNo = smbsTelNo;
    this.smbsEmail = smbsEmail;
    this.smbsBsKindNm = smbsBsKindNm;
    this.smbsUrl = smbsUrl;
    this.smbsRank = smbsRank;
  }
}
