/**
 * LESSON 관련 상수들은 여기에서 정의해서 사용
 */
import RawLicenseContent from './license.json';

export const LICENSE_CONTENT = RawLicenseContent as LicenseContent;

export const LICENSES = Object.keys(LICENSE_CONTENT) as LicenseCode[];

export const LICENSE_BLOG_METATAG = LICENSES.reduce(
  (acc, key) => ({
    ...acc,
    [key]: LICENSE_CONTENT[key].blogMetaTag,
  }),
  {} as Record<LicenseCode, LicenseContentProperty['blogMetaTag']>,
);

export const LICENSE_BLOG_PREVIEW = LICENSES.reduce(
  (acc, key) => ({
    ...acc,
    [key]: LICENSE_CONTENT[key].blogPreview,
  }),
  {} as Record<LicenseCode, LicenseContentProperty['blogPreview']>,
);

export const LICENSE_RELATED_BLOG_PREVIEWS = LICENSES.reduce(
  (acc, key) => ({
    ...acc,
    [key]: LICENSE_CONTENT[key].relatedLicense.map((license) => {
      return LICENSE_CONTENT[license].blogPreview;
    }),
  }),
  {} as Record<LicenseCode, LicenseContentProperty['blogPreview'][]>,
);
