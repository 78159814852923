import { Academy } from '@db/collections/Academy';

// TODO : QueryModel이 아니라 Academy를 extends 할 것

export class SalesAcademy extends Academy {
  assignee: SalesAcademyData['assignee'];
  rank: SalesAcademyData['rank'];
  id: SalesAcademyData['id'];
  history: SalesAcademyData['history'];
  purchaseData: SalesAcademyData['purchaseData'];
  contacts: SalesAcademyData['contacts'];
  studentsHistory: SalesAcademyData['studentsHistory'];
  pvSource: SalesAcademyData['pvSource'];
  입점일: SalesAcademyData['입점일'];
  chats: SalesAcademyData['chats'];

  constructor({
    assignee,
    rank,
    id,
    history,
    purchaseData,
    contacts,
    studentsHistory,
    pvSource,
    입점일,
    chats,
    ...rest
  }: DataModel<SalesAcademyData> & Partial<QueryModelInnerProps>) {
    super({
      instanceConstructor: SalesAcademy,
      className: 'SalesAcademy',
      id: id,
      ...rest,
    });
    this.assignee = assignee;
    this.rank = rank;
    this.id = id;
    this.history = history;
    this.purchaseData = purchaseData;
    this.contacts = contacts;
    this.studentsHistory = studentsHistory;
    this.pvSource = pvSource ?? {
      naver: 0,
      google: 0,
      total: 0,
      IOS: 0,
      ANDROID: 0,
    };
    this.입점일 = 입점일;
    this.chats = chats ?? [];
  }

  get 유선count() {
    return this.history.filter((history) => history.type === '유선').length;
  }

  get 방문count() {
    return this.history.filter((history) => history.type === '방문').length;
  }

  get 영업내역요약() {
    return `유선 : ${this.유선count} / 방문 : ${this.방문count}`;
  }

  get 지역() {
    const [city] = this.location.jibunAddress.split(' ');
    return city;
  }
}
