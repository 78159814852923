import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getUnivEventCertification } from '../calls';

export const useGetUnivEventCertification = (id?: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.UNIV_EVENT_CERTIFICATION, id as string],
    queryFn: getUnivEventCertification,
    enabled: !!id,
    retry: 0,
  });

  return result;
};
