interface Curriculum {
  hour: number;
  score: number;
  license: string;
}

export default {
  학과: ({ hour, score, license }: Curriculum) => ({
    title: '학과(필기)',
    hour,
    images: [
      {
        src: '/lesson/illust-학과시험.png',
        alt: '학과시험',
      },
    ],
    education: {
      title: '학과교육',
      description: '운전 기초 이론을 배우는 시간으로, 학과(필기)시험 전 이수하게 됩니다.',
      banner:
        score === 0
          ? `${license}은(는) 학과 시험이 면제됩니다. 단, 면허취소자의 경우 학과시험을 응시해야 합니다.`
          : `학과시험은 운전면허시험장에서 응시할 수 있어요. 합격 점수는 100점 만점에 ${score}점입니다.`,
    },
  }),
  장내기능: ({ hour, score, license }: Curriculum) => ({
    title: '장내기능',
    hour,
    images: [
      {
        src: '/lesson/illust-장내기능.png',
        alt: '장내기능',
      },
    ],
    education: {
      title: '장내기능교육',
      description: '차량 기본 조작에 대해 배우는 시간으로, 이후 장내기능시험을 치릅니다.',
      banner: '이 학원은 일반 운전학원으로, 학원 내에서 장내기능시험을 응시할 수 없습니다.',
    },
    examination: {
      title: '장내기능시험',
      description:
        score === 0
          ? `${license}은 학과 시험이 면제됩니다.`
          : `운전면허시험장 및 운전전문학원에서 응시 가능하며, 합격 점수는 100점 만점에 ${score}점입니다.`,
    },
  }),
  도로주행: ({ hour, score, license }: Curriculum) => ({
    title: '도로주행',
    hour: hour,
    images: [
      {
        src: '/lesson/illust-도로주행.png',
        alt: '도로주행',
      },
    ],
    education: {
      title: '도로주행교육',
      description:
        '학원 코스를 따라 실제 도로에서 교육을 받는 시간으로, 이후 도로주행시험을 치릅니다.',
      banner: '이 학원은 일반 운전학원으로, 학원 내에서 도로주행시험을 응시할 수 없습니다.',
    },
    examination: {
      title: '도로주행시험',
      description:
        score === 0
          ? `${license}은 학과 시험이 면제됩니다.`
          : `운전면허시험장 및 운전전문학원에서 응시 가능하며, 합격 점수는 100점 만점에 ${score}점입니다.`,
    },
  }),
  운전연수: ({ hour }: { hour: number }) => ({
    title: '',
    hour: hour,
    images: [
      {
        src: '/lesson/illust-운전연수.png',
        alt: '운전연수',
      },
    ],
    education: {
      title: '운전연수',
      description:
        '실제 도로 위에서의 운전을 교육받습니다. 학원에 따라 추가금을 납부하고 고급 차종을 선택할 수 있습니다.',
    },
  }),
};
