import { type FC, type SVGProps, memo } from 'react';

import { cn } from 'tailwind-config';

type BaseIcon = `${'home' | 'compass' | 'pencil' | 'content' | 'profile' | 'bookmark'}-${
  | 'outlined'
  | 'filled'}`;
type ButtonIcon = 'arrowline-outlined' | 'arrowright-outlined' | 'x-outlined';
type TestPageIcon =
  | 'exam-mini'
  | 'exam'
  | 'prev-exam'
  | 'prev-missed'
  | 'bookmarked'
  | 'category'
  | 'frequently-missed';

type IconNames = BaseIcon | ButtonIcon | TestPageIcon;

interface StaticIconProps extends SVGProps<SVGSVGElement> {
  icon: IconNames;
  size?: number;
}

const Icon: FC<StaticIconProps> = ({ icon, size = 24, className, ...props }) => {
  return (
    <div role="img" className="leading-[0px]">
      <svg
        aria-labelledby={icon}
        className={cn('flex-shrink-0', className)}
        width={size}
        height={size}
        {...props}
      >
        <title id={icon}>{icon}</title>
        <use href={`/icon/sprite.svg#${icon}`} />
      </svg>
    </div>
  );
};

export const StaticIcon = memo(Icon);
