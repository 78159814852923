export const convertDateToWeekday = ({
  date,
  isKorean = false,
}: {
  date: Date;
  isKorean?: boolean;
}) => {
  const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const koreanWeek = ['일', '월', '화', '수', '목', '금', '토'];
  const today = date.getDay();
  if (isKorean) {
    return koreanWeek[today];
  }
  return week[today];
};
