import { BaseModel, SelectedRange, SelectedTime } from '../../../base-models';

export class EventMember extends BaseModel {
  id: string;
  isLeader: boolean;
  name: string;
  lessonInfo: LessonInfo | null;
  selectedTimes: SelectedTime[];
  selectedRange: SelectedRange | null;
  state: EventMemberState;
  isPaid: boolean;
  isAlerted: boolean;
  joinedAt: Date;

  constructor({
    id,
    isLeader,
    name,
    lessonInfo,
    selectedTimes,
    selectedRange,
    state,
    isPaid,
    isAlerted,
    joinedAt,
  }: EventMemberData) {
    super('EventMember');
    this.id = id;
    this.isLeader = isLeader;
    this.name = name;
    this.lessonInfo = lessonInfo;
    this.selectedTimes = selectedTimes.map((v) => new SelectedTime(v));
    this.selectedRange = selectedRange ? new SelectedRange(selectedRange) : null;
    this.state = state;
    this.isPaid = isPaid;
    this.isAlerted = isAlerted;
    this.joinedAt = joinedAt;
  }
}
