import { getPrevPageParam } from '@db/utils';
import { db } from 'firebase-config';
import {
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { isEmpty } from 'lodash-es';
import { findMethodAndReplace, lessonMapping } from 'shared-values';

import { Review } from '../models';

const QUERY_LIMIT = 10;

const sortOptionMap = {
  createdAtDesc: orderBy('createdAt', 'desc'),
  likeCountDesc: orderBy('likeCount', 'desc'),
  rateDesc: orderBy('rate.average', 'desc'),
  rateAsc: orderBy('rate.average', 'asc'),
};

const queryByFilter = (
  academyId: string,
  sortOption: string,
  license: string,
  state: ReviewData['state'],
  pageParam?: QueryDocumentSnapshot<Review>,
) => {
  const lessonCodes = lessonMapping[license as keyof typeof lessonMapping];

  return query(
    collection(db, 'Review'),
    limit(QUERY_LIMIT),
    where('state', '==', state),
    where('academyId', '==', academyId),
    sortOptionMap[sortOption as keyof typeof sortOptionMap],
    ...(isEmpty(license) || license === 'all' ? [] : [where('lesson.code', 'in', lessonCodes)]),
    ...(pageParam ? [startAfter(pageParam)] : []),
  );
};

export const getReviewsByAcademyId = async ({
  queryKey,
  pageParam,
}: QueryFn<
  [string, string, string, string, ReviewData['state']],
  QueryDocumentSnapshot<Review> | string | null
>) => {
  const [, academyId, sortOption, license, state] = queryKey;

  // pageParam이 null이면 끝난 것으로 판단
  if (pageParam === null) return { data: [], nextPageParam: null };

  const prevPageParam = await getPrevPageParam<Review>('Review', pageParam);

  const q = queryByFilter(academyId, sortOption, license, state, prevPageParam);

  const querySnapshot = await getDocs(q);

  let nextPageParam = null;
  if (querySnapshot.size >= QUERY_LIMIT) {
    nextPageParam = querySnapshot.docs[querySnapshot.docs.length - 1];
  }

  return {
    data: querySnapshot.docs.map((doc) => {
      const docData = { ...doc.data(), id: doc.id } as ReviewData;
      findMethodAndReplace(docData, 'toDate');
      return new Review(docData);
    }),
    nextPageParam,
  };
};
