import { QUERY_KEY } from '@db/constants';
import { useQuery } from '@tanstack/react-query';

import { getEventParticipantCount } from '../calls';

export const useGetEventParticipantCount = () => {
  return useQuery({
    queryKey: [QUERY_KEY.EVENT_PARTICIPANT_COUNT],
    queryFn: getEventParticipantCount,
  });
};
