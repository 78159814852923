import { atom } from 'jotai';

import { isAppApproaching } from '@utils/Common';

const isAppApproach = isAppApproaching();

/**
 * server 환경, pc web 환경, mobile web 환경에서는 무조건 true가 와야 함.
 * app 환경에서는 기본값으로 false가 와야 함.
 */

export const isWebviewReadyAtom = atom<boolean>(
  process.env.NODE_ENV === 'development' || !isAppApproach,
);

export const isScriptReadyAtom = atom<boolean>(false);
