import { useMemo } from 'react';

import axios from 'axios';
import { useGetUser } from 'database';
import { BACKEND_URL, isAppApproaching } from 'shared-values';
import { v4 as uuidv4 } from 'uuid';

import { useSetUpJquery } from '@hooks/use-set-up-jquery';
import { addVerification } from '@utils/Application';

import { Condition } from '../MarketingAgreementBottomSheet';

const isAppApproach = isAppApproaching();

// useSetupJQuery가 실행되어야 함
export const verification = async (user: User, onSuccessOnPC: () => void) => {
  const { status } = await axios.get(BACKEND_URL);
  if (status !== 200) {
    throw new Error();
  }

  const merchant_uid = uuidv4();

  await addVerification(merchant_uid, user.uid, user.phoneNum);

  if (isAppApproach) {
    window.flutter_inappwebview.callHandler(
      'ScreenMove',
      'goToVerification',
      merchant_uid,
      user.phoneNum,
      user.realName ?? '',
    );
    return 0;
  }

  const { IMP } = window;
  IMP.init('imp64710875');
  IMP.certification(
    {
      merchant_uid,
      company: '주식회사 티지소사이어티',
      name: user.realName,
      phone: user.phoneNum,
      m_redirect_url: `${BACKEND_URL}/verification/mobile-roulette`,
      popup: false,
    },
    async (rsp: any) => {
      if (!rsp.success) {
        return;
      }

      const { status } = await axios({
        url: `${BACKEND_URL}/verification`,
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: {
          verificationId: merchant_uid,
          userPhonenum: user.phoneNum,
          userId: user.uid,
          imp_uid: rsp.imp_uid,
        },
      });

      if (status === 200) {
        // pc의 경우 reset
        onSuccessOnPC();
      } else {
        alert('결제하는데 알 수 없는 문제가 발생했습니다. 고객센테로 문의해주세요. ');
      }
    },
  );
};

export const useVerificationAgree = (): Condition => {
  const { user, isLoading: isUserLoading, refetch } = useGetUser();
  useSetUpJquery();

  const isVerified = Boolean(user && user.verification);
  const initialState = useMemo(() => isVerified, [isUserLoading]);

  return {
    initialState,
    state: isVerified,
    onClick: async () => {
      if (!user) return;
      await verification(user, refetch);
    },
    isLoading: isUserLoading,
  };
};
