import { db } from 'firebase-config';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { findMethodAndReplace, formatDate } from 'shared-values';

import { Order } from '../models';

export const getTimetableOrders = ({ queryClient, queryKey }: QueryFn) => {
  const [, academyId, date] = queryKey as [string, string, Date];

  if (!date) throw new Error('date is required');

  const dateString = formatDate(date, 'YYYY-MM-DD');

  const q = query(
    collection(db, 'Order'),
    where('receipt.academyId', '==', academyId),
    where('selectedTime.dateRange', 'array-contains', dateString),
    where('visitTrainingReceipt', '==', null),
    where('state', 'in', ['Appointed', 'Arrival', 'NoShow']),
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const result: Order[] = [];
    for (const doc of querySnapshot.docs) {
      const docData = { id: doc.id, ...doc.data() } as OrderData;

      // timestamp를 date로 변환
      findMethodAndReplace(docData, 'toDate');
      result.push(new Order({ ...docData, queryClient }));
    }
    queryClient.setQueryData(queryKey, result);
  });

  return () => {
    unsubscribe();
  };
};
