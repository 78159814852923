const TEST_URI =
  typeof window === 'undefined'
    ? `https://test.drivingteacher.co.kr`
    : `https://${window.location.host}`;

export const KAKAO_REDIRECT_URI =
  process.env.NEXT_PUBLIC_PLATFORM_ENV === 'production'
    ? 'https://drivingteacher.co.kr/login'
    : process.env.NEXT_PUBLIC_PLATFORM_ENV === 'test'
    ? `${TEST_URI}/login`
    : process.env.NEXT_PUBLIC_PLATFORM_ENV === 'payment'
    ? 'https://payment.drivingteacher.co.kr/login'
    : 'http://localhost:3000/login';
export const HOME_URI =
  process.env.NEXT_PUBLIC_PLATFORM_ENV === 'production'
    ? 'https://drivingteacher.co.kr'
    : process.env.NEXT_PUBLIC_PLATFORM_ENV === 'test'
    ? TEST_URI
    : process.env.NEXT_PUBLIC_PLATFORM_ENV === 'payment'
    ? 'https://payment.drivingteacher.co.kr'
    : 'http://localhost:3000';

export const KAKAO_LOGOUT_REDIRECT_URI =
  process.env.NEXT_PUBLIC_PLATFORM_ENV === 'production'
    ? 'https://drivingteacher.co.kr'
    : process.env.NEXT_PUBLIC_PLATFORM_ENV === 'test'
    ? TEST_URI
    : process.env.NEXT_PUBLIC_PLATFORM_ENV === 'payment'
    ? 'https://payment.drivingteacher.co.kr'
    : 'http://localhost:3000';

export const APP_DOWNLOAD_URL = 'https://abr.ge/i5rln6';
export const BACKEND_URL = 'https://calm-plains-36506.herokuapp.com';
// export const BACKEND_URL = 'http://172.30.1.11:8000';

export const 제휴문의URL =
  'https://docs.google.com/forms/d/1yQsweRsm0Lvr67FTfOvFOTE9nQJfPgQbGndkm23rXLk';
