import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getSalesAcademies } from '../calls';

export const useGetSalesAcademies = () => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.SALES_ACADEMIES],
    queryFn: getSalesAcademies,
  });

  return result;
};
