import { db } from 'firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { Academy, AcademyList } from '../models';

export const getBookableAcademiesData = async () => {
  const q = query(collection(db, 'Academy'), where('state', '==', '입점'));

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => {
    const docData = { ...doc.data(), id: doc.id } as AcademyData;

    findMethodAndReplace(docData, 'toDate');
    return docData;
  });
};

export const getBookableAcademies = async ({ queryClient, queryKey }: QueryFn) => {
  const docData = await getBookableAcademiesData();

  return new AcademyList({
    items: docData
      .map((data) => new Academy({ ...data, queryClient }))
      .filter((academy) => academy.isAcademyBookable)
      .filter((academy) => !academy.isTestAcademy),
    queryClient,
    queryKey,
  });
};
