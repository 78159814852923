import { db } from 'firebase-config';
import { doc, setDoc } from 'firebase/firestore';

export const addVerification = async (
  merchant_uid: string,
  userId: string | undefined,
  userPhoneNum: string,
) => {
  await setDoc(doc(db, 'Verification', merchant_uid), {
    isVerified: false,
    userId,
    userPhoneNum,
  });
};
