import { BaseModel } from '@db/base-models';

export class AcademyReview extends BaseModel {
  totalParticipants: number;
  data: {
    text: string;
    count: number;
    ids: string[];
  }[];

  constructor({ totalParticipants, data }: AcademyReviewData) {
    super('AcademyReview');
    this.totalParticipants = totalParticipants;
    this.data = data.sort((a, b) => b.count - a.count);
  }
}
