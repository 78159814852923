import axios from 'axios';
import { distanceBetween } from 'geofire-common';

import { Academy } from '../models';

interface SearchAcademyData extends AcademyData {
  objectID: string;
}

export const getAcademiesByTerm = async ({ queryKey, queryClient }: QueryFn) => {
  const [, value, myLocation, isFromTeamEvent] = queryKey as [
    string,
    string,
    LocationType | null | undefined,
    boolean,
  ];
  if (value.length === 0) return [];

  const {
    data: { hits },
  } = await axios.get(
    `https://9JJD8JVT6P-dsn.algolia.net/1/indexes/Academy?query=${value}&hitsPerPage=1000`,
    {
      headers: {
        'X-Algolia-API-Key': '82809516c33cd48030406b2ced5c1227',
        'X-Algolia-Application-Id': '9JJD8JVT6P',
      },
    },
  );

  const filteredAcademyList: Academy[] = [];

  if (hits) {
    hits.map((item: SearchAcademyData) => {
      const id = item.objectID;

      const academyData = {
        ...item,
        id,
      };

      const academy = new Academy({ ...academyData, queryClient, queryKey });

      if (academy.isTestAcademy) return;

      if (isFromTeamEvent && academy.isSimulation) return;

      if (!academy.isVisible) return;

      if (!myLocation) {
        filteredAcademyList.push(academy);
        return;
      }

      const center: [number, number] = [myLocation.latitude, myLocation.longitude];

      const lat = Number(item.location.latitude);
      const lng = Number(item.location.longitude);

      const distanceInKm = distanceBetween([lat, lng], center);

      academy.setDisatnce(Number(distanceInKm.toFixed(2)));

      filteredAcademyList.push(academy);
    });
  }

  return filteredAcademyList;
};
