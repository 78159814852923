import React from 'react';

import { HTMLMotionProps } from 'framer-motion';
import { Colors } from 'tailwind-config';

import { IconType } from '../Icon';

export enum BOXBUTTON_COLOR {
  WHITE = 'white',
  BLACK = 'black',
  DTYellow = 'DTYellow-400',
  DTGray = 'gray-200',
  DTRed = 'DTRed-400',
}

export interface BoxButtonProps extends HTMLMotionProps<'button'> {
  color: BOXBUTTON_COLOR;
  icon?: {
    type: IconType;
    size?: number;
    color?: keyof typeof Colors;
  };
  text: string | React.ReactNode;
}
