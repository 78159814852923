'use client';

import { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { useOverlayContext } from './context';

let elementId = 1;

type CreateOverlayElement = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => JSX.Element;

interface OverlayProps {
  open: (overlayElement: CreateOverlayElement) => void;
  exit: () => void;
}

export const useOverlay = (
  {
    offOnRouteChange = true,
  }: {
    offOnRouteChange?: boolean;
  } = {
    offOnRouteChange: true,
  },
): OverlayProps => {
  const { mount, unmount } = useOverlayContext();

  const [id] = useState(() => String(elementId++));

  return useMemo(
    () => ({
      open: (overlayElement: CreateOverlayElement) => {
        mount(
          id,
          <OverlayController
            key={String(new Date())}
            overlayElement={overlayElement}
            offOnRouteChange={offOnRouteChange}
          />,
        );
      },

      exit: () => {
        unmount(id);
      },
    }),
    [id, mount, unmount],
  );
};

const OverlayController = ({
  overlayElement: OverlayElement,
  offOnRouteChange,
}: {
  overlayElement: ({ isOpen, close }: { isOpen: boolean; close: () => void }) => JSX.Element;
  offOnRouteChange: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const router = useRouter();

  useEffect(() => {
    if (!offOnRouteChange) return;
    const handleRouteChange = (url: string) => {
      if (url !== router.pathname) {
        setIsOpen(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, offOnRouteChange]);

  return (
    <OverlayElement
      isOpen={isOpen}
      close={() => {
        setIsOpen(false);
      }}
    />
  );
};
