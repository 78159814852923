import { Divider } from 'design-system';

import { companyInfo } from '@constants';

export const LocationTerm = () => {
  return (
    <>
      <h1 className="text-new-Title2 p-16">위치기반서비스 이용약관</h1>
      <Divider className="bg-new-gray-200 h-8" />
      <div className="text-Body2 bg-white px-20 py-16">
        <div className="text-H3 font-extraBold">제 1장 총칙</div>
        <div className="text-T3 mt-16 font-bold">제 1조 (목적)</div>
        <div className="mt-16">
          본 약관은 회원(서비스 이용약관에 동의한 자를 말합니다. 이하 “회원”이라고 합니다.)이
          운전선생 서비스 제공자 (주)티지소사이어티(이하 “운전선생”이라고 합니다.)이 제공하는
          위치기반서비스(이하 “서비스”라고 합니다)를 이용함에 있어 운전선생과 회원의 권리·의무 및
          책임사항을 규정함을 목적으로 합니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 2 조 (이용약관의 효력 및 변경)</div>
        <div className="mt-16">
          1. 본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 본 약관에 동의하고 운전선생이
          정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생합니다.
        </div>
        <div className="mt-16">
          2. 회원이 온라인에서 본 약관의 "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두
          읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다.
        </div>
        <div className="mt-16">
          3. 운전선생은 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래
          등에서의 소비자보호에 관한 법률, 소비자기본법 약관의 규제에 관한 법률 등 관련법령을
          위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
        </div>
        <div className="mt-16">
          4. 운전선생이 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와
          개정사유를 명시하여 기존 약관과 함께 그 적용일자 7일 전부터 적용일 이후 상당한 기간 동안
          공지만을 하고, 개정 내용이 회원에게 불리한 경우에는 그 적용일자 30일 전부터 적용일 이후
          상당한 기간 동안 각각 이를 서비스 홈페이지에 게시하거나 회원에게 전자적 형태(전자우편, SMS
          등)로 약관 개정 사실을 발송하여 고지합니다.
        </div>
        <div className="mt-16">
          5. 운전선생이 전항에 따라 회원에게 통지하면서 공지 또는 공지∙고지일로부터 개정약관 시행일
          7일 후까지 거부 의사를 표시하지 아니하면 이용약관에 승인한 것으로 봅니다. 회원이
          개정약관에 동의하지 않을 경우 회원은 이용계약을 해지할 수 있습니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 3 조 (관계법령의 적용)</div>
        <div className="mt-16">
          1. 본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에
          대하여는 관계법령 또는 상관례에 따릅니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 4 조 (서비스의 내용)</div>
        <div className="mt-16">
          운전선생은 직접 위치정보를 수집하거나 위치정보사업자로부터 위치정보를 전달받아 아래와 같은
          위치기반서비스를 제공합니다.
        </div>
        <div className="mt-16">1. 위치정보수집 대상인 이용자의 실시간 위치를 확인합니다.</div>
        <div className="mt-16">
          2. 정보 검색 요청 시 이용장의 현 위치를 이용한 검색결과 및 이용자의 위치에서 근접한
          업소정보를 제시합니다.
        </div>
        <div className="mt-16">
          3. 인구통계학적 특성에 따른 서비스 제공, 접속빈도 분석, 기능개선, 서비스 이용에 대한 통계,
          서비스 분석 및 통계에 따른 이용자 관심 등에 기반한 신규 서비스(개인 맞춤형 상품 추천
          서비스 등 포함)를 제공하기 위하여 이용자의 개인위치정보를 이용합니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 5 조 (서비스내용변경 통지 등)</div>
        <div className="mt-16">
          1. 운전선생이 서비스 내용을 변경하거나 종료하는 경우 운전선생은 회원의 등록된 전자우편
          주소 또는 통신 단말장치 등을 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수
          있습니다.
        </div>
        <div className="mt-16">
          제 1항의 경우 불특정 다수인을 상대로 통지를 함에 있어서는 웹사이트 등 기타 운전선생의
          공지사항을 통하여 회원들에게 통지할 수 있습니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 6 조 (서비스이용의 제한 및 중지)</div>
        <div className="mt-16">
          1. 운전선생은 아래 각 호의 1에 해당하는 사유가 발생한 경우에는 회원의 서비스 이용을
          제한하거나 중지시킬 수 있습니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.1 회원이 운전선생 서비스의 운영을 고의 또는 중과실로 방해하는
          경우
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.2 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.3 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
          중지했을 경우
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.4 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주
          등으로 서비스 이용에 지장이 있는 때
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.5 기타 중대한 사유로 인하여 운전선생이 서비스 제공을 지속하는
          것이 부적당하다고 인정하는 경우
        </div>
        <div className="mt-16">
          2. 운전선생은 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및
          제한기간 등을 회원에게 알려야 합니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 7 조 (개인위치정보의 이용 또는 제공)</div>
        <div className="mt-16">
          1. 운전선생은 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에
          명시한 후 개인위치정보주체의 동의를 얻어야 합니다.
        </div>
        <div className="mt-16">
          2. 회원 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의하며, 주소가
          없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소
          또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
        </div>
        <div className="mt-16">
          3. 운전선생은 타사업자 또는 이용 고객과의 요금정산 및 민원처리를 위해 위치정보
          이용·제공·사실 확인자료를 자동 기록·보존하며, 해당 자료는 6개월 이상 보관합니다.
        </div>
        <div className="mt-16">
          4. 운전선생은 개인위치정보주체의 동의 없이 개인위치정보를 제3자에게 제공하지 아니하며,
          제3자에게 개인위치정보를 제공하는 경우에는 제공 받는자 및 제공목적을 사전에
          개인위치정보주체에게 고지하고 동의를 받습니다.
        </div>
        <div className="mt-16">
          5. 운전선생은 개인위치정보를 회원이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를
          수집한 당해 통신 단말장치로 매회 회원에게 제공받는 자, 제공일시 및 제공목적을 즉시
          통보합니다. 단, 아래 각 호의 1에 해당하는 경우에는 회원이 미리 특정하여 지정한 통신
          단말장치 또는 전자우편주소로 통보합니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;5.1 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는
          영상의 수신기능을 갖추지 아니한 경우
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;5.2 개인위치정보주체가 개인위치정보를 수집한 해당 통신단말장치
          외의 통신단말장치 또는 전자우편주소 등으로 통보할 것을 미리 요청한 경우
        </div>
        <div className="text-T3 mt-16 font-bold">제 8 조 (개인위치정보주체의 권리)</div>
        <div className="mt-16">
          1. 회원은 운전선생에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및
          개인위치정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우
          운전선생은 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.
        </div>
        <div className="mt-16">
          2. 회원은 운전선생에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를
          요구할 수 있으며, 운전선생은 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.
        </div>
        <div className="mt-16">
          3. 회원은 운전선생에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해
          자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 운전선생은 정당한 사유
          없이 회원의 요구를 거절할 수 없습니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;3.1 본인에 대한 위치정보 수집, 이용, 제공사실 확인자료
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;3.2 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률
          또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용
        </div>
        <div className="mt-16">
          4. 회원은 제1항 내지 제3항의 권리행사를 위해 운전선생의 소정의 절차를 통해 요구할 수
          있습니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 9 조 (법정대리인의 권리)</div>
        <div className="mt-16">
          1. 운전선생은 14세 미만의 회원에 대해서는 개인위치정보를 이용한 위치기반서비스 제공 및
          개인위치정보의 제3자 제공에 대한 동의를 당해 회원과 당해 회원의 법정대리인으로부터 동의를
          받아야 합니다. 이 경우 법정대리인은 제9조에 의한 회원의 권리를 모두 가집니다.
        </div>
        <div className="mt-16">
          2. 운전선생은 14세 미만의 아동의 개인위치정보 또는 위치정보 이용․제공사실 확인자료를
          이용약관에 명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 14세
          미만의 아동과 그 법정대리인의 동의를 받아야 합니다. 단, 아래의 경우는 제외합니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;2.1 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여
          위치정보 이용, 제공사실 확인자료가 필요한 경우
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;2.2 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼
          수 없는 형태로 가공하여 제공하는 경우
        </div>
        <div className="text-T3 mt-16 font-bold">
          제 10조 (8세 이하의 아동 등의 보호의무자의 권리)
        </div>
        <div className="mt-16">
          1. 운전선생은 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라 한다)의 보호의무자가
          8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는
          경우에는 본인의 동의가 있는 것으로 봅니다.
        </div>
        <div className="text-D2 mt-12">&nbsp;&nbsp;&nbsp;&nbsp;1.1 8세 이하의 아동</div>
        <div className="text-D2 mt-12">&nbsp;&nbsp;&nbsp;&nbsp;1.2 피성년후견인</div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.3 장애인복지법제2조제2항제2호의 규정에 의한 정신적 장애를 가진
          자로서 장애인고용촉진및직업재활법 제2조 제2호의 규정에 의한 중증장애인에 해당하는
          자(장애인복지법 제32조의 규정에 의하여 장애인등록을 한 자에 한한다)
        </div>
        <div className="mt-16">
          2. 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에
          동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여
          운전선생에 제출하여야 합니다.
        </div>
        <div className="mt-16">
          3. 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우
          개인위치정보주체 권리의 전부를 행사할 수 있습니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 11조 (위치정보관리책임자의 지정)</div>
        <div className="mt-16">
          1. 운전선생은 위치정보를 적절히 관리·보호하고 개인위치정보주체의 불만을 원활히 처리할 수
          있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다.
        </div>
        <div className="mt-16">
          2. 위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본
          약관의 부칙에 따릅니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 12조 (개인위치정보의 보유목적 및 이용기간)</div>
        <div className="mt-16">
          1. 운전선생은 제4조에 명시된 위치기반서비스를 제공하기 위한 목적으로 개인위치정보를 보유
          및 이용합니다.
        </div>
        <div className="mt-16">
          2. 운전선생은 위치정보 이용·제공사실 확인자료 외의 개인위치정보는 이용·제공 목적 달성 시
          까지 보유합니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 13조 (손해배상)</div>
        <div className="mt-16">
          1. 운전선생이 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한
          행위로 회원에게 손해가 발생한 경우 회원은 운전선생에 대하여 손해배상 청구를 할 수
          있습니다. 이 경우 운전선생은 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수
          없습니다.
        </div>
        <div className="mt-16">
          2. 회원이 본 약관의 규정을 위반하여 운전선생에 손해가 발생한 경우 운전선생은 회원에 대하여
          손해배상을 청구할 수 있습니다. 이 경우 회원은 고의, 과실이 없음을 입증하지 못하는 경우
          책임을 면할 수 없습니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 14조 (면책)</div>
        <div className="mt-16">
          1. 운전선생은 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한
          손해에 대해서는 책임을 부담하지 않습니다.
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.1 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.2 서비스 제공을 위하여 운전선생과 서비스 제휴계약을 체결한
          제3자의 고의적인 서비스 방해가 있는 경우
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.3 회원의 귀책사유로 서비스 이용에 장애가 있는 경우
        </div>
        <div className="text-D2 mt-12">
          &nbsp;&nbsp;&nbsp;&nbsp;1.4 제1호 내지 제3호를 제외한 기타 운전선생의 고의∙과실이 없는
          사유로 인한 경우
        </div>
        <div className="mt-16">
          2. 운전선생은 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는
          보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 책임을 부담하지 아니합니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 15조 (규정의 준용)</div>
        <div className="mt-16">1. 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.</div>
        <div className="mt-16">
          2. 본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에 의합니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 16조 (분쟁의 조정 및 기타)</div>
        <div className="mt-16">
          1. 운전선생은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를
          할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조 규정에 의한
          방송통신위원회에 재정을 신청할 수 있습니다.
        </div>
        <div className="mt-16">
          2. 운전선생 또는 고객은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지
          아니하거나 협의를 할 수 없는 경우에는 개인정보보호법 제40조의 규정에 의한
          개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.
        </div>
        <div className="text-T3 mt-16 font-bold">제 17조 (운전선생의 연락처)</div>
        <div className="mt-16">운전선생의 연락처 등은 다음과 같습니다.</div>
        <div className="mt-16">- 대 표 자 : 오 석 준</div>
        <div className="mt-16">- 대표전화 : {companyInfo.phoneNumber}</div>
        <div className="text-T3 mt-16 font-bold">부칙</div>
        <div className="mt-16">제1조 (시행일) 이 약관은 2022년 11월 14일부터 시행합니다.</div>
        <div className="mt-16">
          제2조 위치정보관리책임자는 2022년 11월 14일을 기준으로 다음과 같이 지정합니다.
        </div>
        <div className="mt-16">- 성 명 : (주)티지소사이어티</div>
        <div className="mt-16">- 연락처 : {companyInfo.phoneNumber}</div>
      </div>
    </>
  );
};
