import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getTimetables } from '../calls';

export const useGetTimetables = (enabled: boolean) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.TIMETABLES],
    queryFn: getTimetables,
    enabled: enabled,
  });

  return result;
};
