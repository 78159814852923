export const mockDiscount: DiscountData = {
  discountPrice: 0,
  discountPercent: 0.5,
  discountType: 'coupon',
  discountSource: 'dt',
  promotionName: '이렇게 텍스트가 완전 길어지는 경우에는 어떻게 될까요 정망 궁금합니다.',
  academyId: 'academyId',
  typeId: 'asdf',
  lessonConditions: [],
};

export const mockDiscounts: DiscountData[] = [
  {
    ...mockDiscount,
    discountPercent: 0.15,
    discountType: 'coupon',
    discountSource: 'dt',
    promotionName: '테스트 dt 쿠폰 percent',
  },
  {
    ...mockDiscount,
    discountPrice: 1000,
    discountType: 'coupon',
    discountSource: 'simulation',
    promotionName: '테스트 simulation 쿠폰 price',
  },
  {
    ...mockDiscount,
    discountPercent: 0.1,
    discountType: 'together',
    discountSource: 'dt',
    promotionName: '테스트 dt 같이취득 percent 길어겁나길어짐',
  },
  {
    ...mockDiscount,
    discountPrice: 2000,
    discountType: 'together',
    discountSource: 'simulation',
    promotionName: '테스트 simulation 같이취득 price',
  },
];
