import { formatDate, subtractHHMM } from 'shared-values';

import { StartAndEndTime } from './start-and-end-time';

export class TimeWithDate extends StartAndEndTime {
  date: Date;

  constructor({ date, start, end }: TimeWithDateData) {
    super({ start, end });
    this.date = new Date(date);
  }

  get dateString() {
    return formatDate(this.date, 'YY.MM.DD');
  }

  get startEndString() {
    return subtractHHMM(this.start, 10) + ' ~ ' + this.end;
  }

  get fullString() {
    return this.dateString + '   ' + this.startEndString;
  }
}
