import { QUERY_KEY } from '@db/constants';

import { useQueryWithClient } from '../../../hooks';
import { getSalesAcademy } from '../calls/get-sales-academy';

export const useGetSalesAcademy = (id?: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.SALES_ACADEMY, id ?? ''],
    queryFn: getSalesAcademy,
    enabled: !!id && id !== '',
  });

  return { ...result };
};
