'use client';

import { useCallback, useEffect, useState } from 'react';

import { QUERY_KEY } from '@db/constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { db } from 'firebase-config';
import { doc, onSnapshot } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { getEventRoom } from '../calls';
import { EventRoom } from '../models';

export const useGetEventRoom = (id?: string) => {
  const queryKey = [QUERY_KEY.EVENT_ROOM, id];
  const result = useQuery<EventRoom | undefined>({
    queryKey,
    queryFn: () => undefined,
    enabled: false,
  });
  const queryClient = useQueryClient();

  const [isRoomNotExist, setIsRoomNotExist] = useState(false);

  useEffect(() => {
    if (!id || id.length === 0) return;
    getData(id);
  }, [id]);

  const getData = async (id: string) => {
    const docRef = doc(db, 'EventRoom', id);

    const unsub = onSnapshot(docRef, (doc) => {
      if (!doc.exists()) {
        setIsRoomNotExist(true);
        return;
      }

      const docData = { ...doc.data(), id: doc.id } as EventRoomData;
      findMethodAndReplace(docData, 'toDate');
      const eventRoom = new EventRoom({ ...docData, queryClient, queryKey });

      queryClient.setQueryData(queryKey, eventRoom);
    });
    return () => {
      unsub();
    };
  };

  const getManually = useCallback(
    async (id: string) => {
      return await getEventRoom({
        queryKey: [QUERY_KEY.EVENT_ROOM, id],
        queryClient,
      });
    },
    [queryClient],
  );

  return { ...result, isRoomNotExist, getManually };
};
