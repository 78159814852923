import classNames from 'classnames';

import { Icon } from '../Icon';
import { TagProps } from './Tag.type';

export const SkeletonTag = () => <div className='h-[23px] w-[180px] rounded-[3px] bg-gray-200' />;

export const Tag = ({ text, icon, className, ...props }: TagProps) => {
  return (
    <span
      className={classNames(
        `inline-block whitespace-nowrap rounded-[3px] py-[1.6px] ${className}`,
        icon ? 'text-Body7' : 'text-new-Caption2-bold',
      )}
      {...props}>
      {text}
      {icon && <Icon icon={icon.type} size={icon.size ?? 24} color={icon.color ?? 'gray-900'} />}
    </span>
  );
};
