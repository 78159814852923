import { db } from 'firebase-config';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';

import { Blog } from '../models';

export const getBlogsTrending = async ({ queryKey, queryClient }: QueryFn) => {
  const q = query(
    collection(db, 'Blog'),
    orderBy('createdAt', 'desc'),
    where('isBest', '==', true),
    limit(3),
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => {
    const docData = { ...doc.data(), id: doc.id } as BlogData;
    return new Blog({ ...docData, queryClient, queryKey });
  });
};
