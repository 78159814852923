import { getPrevPageParam } from '@db/utils';
import { db } from 'firebase-config';
import {
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { Blog } from '../models';

export const getBlogsInfinite = async ({
  queryKey,
  pageParam,
}: QueryFn<[string, string], QueryDocumentSnapshot<Blog> | null>) => {
  let q = null;
  const result: Blog[] = [];
  const [, hashtag] = queryKey;

  if (pageParam) {
    const prevPageParam = await getPrevPageParam<Blog>('Blog', pageParam);

    q = query(
      collection(db, 'Blog'),
      orderBy('createdAt', 'desc'),
      limit(10),
      startAfter(prevPageParam),
      ...(hashtag.length > 0 ? [where('hashTags', 'array-contains', '')] : []),
    );
  } else {
    q = query(
      collection(db, 'Blog'),
      orderBy('createdAt', 'desc'),
      limit(10),
      ...(hashtag.length > 0 ? [where('hashTags', 'array-contains', hashtag)] : []),
    );
  }

  const querySnapshot = await getDocs(q);

  for await (const doc of querySnapshot.docs) {
    const docData = { ...doc.data(), id: doc.id } as BlogData;
    findMethodAndReplace(docData, 'toDate');
    result.push(new Blog(docData));
  }

  let nextPageParam = null;
  if (querySnapshot.size >= 10) {
    nextPageParam = querySnapshot.docs[querySnapshot.docs.length - 1];
  }

  // todo : blogList 제작
  return { data: result, nextPageParam };
};
