'use client';

import { useEffect } from 'react';

import { Portal } from '@ds/components';
import { AnimatePresence, motion } from 'framer-motion';

export const SnackBar = ({
  isOpen,
  close,
  text,
  yPosition = 0,
}: {
  isOpen: boolean;
  close: () => void;
  text: string;
  yPosition?: number;
}) => {
  useEffect(() => {
    const tick = setTimeout(() => {
      close();
    }, 2500);

    return () => clearTimeout(tick);
  }, [isOpen]);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <Portal onClickBackground={close} isBackgroundBlack={false}>
            <motion.div
              className='absolute bottom-0 left-1/2 w-full max-w-[500px]'
              initial={{ y: 200, x: '-50%' }}
              animate={{ y: -yPosition, x: '-50%' }}
              exit={{ y: 200, x: '-50%' }}
              transition={{ type: 'spring', bounce: 0.3 }}>
              <div
                className='round text-Body5 mb-30 rounded-xxl px-22 mx-16 whitespace-pre-wrap bg-black py-14 text-white'
                style={{ opacity: 0.8 }}>
                {text}
              </div>
            </motion.div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  );
};
