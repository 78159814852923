import React, { ComponentProps, useState } from 'react';

import {
  NewBottomSheet,
  NewBottomSheetClose,
  NewBottomSheetContent,
  NewBottomSheetTrigger,
  NewBoxButton,
  NewIcon,
  Spacer,
  useOverlay,
} from 'design-system';
import { cn } from 'tailwind-config';

import { Loading } from '@components';

import { isAppApproaching } from '@utils/Common';

import { useKakaoChannelAgree } from './hooks/use-kakao-channel-agree';
import { useMarketingAgree } from './hooks/use-marketing-agree';
import { useNotificationAgree } from './hooks/use-notification-agree';
import { useVerificationAgree } from './hooks/use-verification-agree';

type MarketingAgreementType = 'required' | 'optional' | 'not-needed';

export interface MarketingAgreementOption {
  marketingAgree: MarketingAgreementType;
  kakaoChannelAgree: MarketingAgreementType;
  verificationAgree: MarketingAgreementType;
  notificationAgree: MarketingAgreementType;
}

const DEFAULT_OPTION: MarketingAgreementOption = {
  marketingAgree: 'required',
  kakaoChannelAgree: !isAppApproaching() ? 'optional' : 'not-needed',
  notificationAgree: isAppApproaching() ? 'required' : 'not-needed',
  verificationAgree: 'not-needed',
};

export type Condition = {
  initialState: boolean;
  state: boolean;
  onClick: () => void;
  onSubmit?: () => Promise<void>;
  isLoading: boolean;
};

export const useMarketingStates = (
  option: MarketingAgreementOption = DEFAULT_OPTION,
): (Condition & { option: MarketingAgreementType; text: string })[] => {
  const marketingAgree = useMarketingAgree();
  const kakaoChannelAgree = useKakaoChannelAgree();
  const verificationAgree = useVerificationAgree();
  const notificationAgree = useNotificationAgree();

  return [
    ...(option.marketingAgree !== 'not-needed' && !marketingAgree.initialState
      ? [{ ...marketingAgree, option: option.marketingAgree, text: '마케팅 수신 동의' }]
      : []),
    ...(option.kakaoChannelAgree !== 'not-needed' && !kakaoChannelAgree.initialState
      ? [{ ...kakaoChannelAgree, option: option.kakaoChannelAgree, text: '카카오 채널 친구 추가' }]
      : []),
    ...(option.verificationAgree !== 'not-needed' && !verificationAgree.initialState
      ? [{ ...verificationAgree, option: option.verificationAgree, text: '본인 인증' }]
      : []),
    ...(option.notificationAgree !== 'not-needed' && !notificationAgree.initialState
      ? [{ ...notificationAgree, option: option.notificationAgree, text: '알림 설정' }]
      : []),
  ];
};

const MarketingAgreementUI = ({
  marketingStates,
  isLoading,
  onSubmit,
  close,
  onReject,
}: {
  marketingStates: ReturnType<typeof useMarketingStates>;
  isLoading: boolean;
  onSubmit: () => void;
  close: () => void;
  onReject?: () => void;
}) => (
  <NewBottomSheetContent>
    <div className="relative">
      <p className="text-new-Sub-Title flex flex-col gap-4 py-24">
        쿠폰을 받기 위해 동의가 필요해요.
      </p>
      <NewBottomSheetClose className="absolute right-0 top-[26.5px] cursor-pointer" onClick={close}>
        <NewIcon icon="xincircle-filled" size={24} className="fill-new-gray-400" />
      </NewBottomSheetClose>
    </div>
    <div className="flex flex-col gap-8">
      {marketingStates.map((s) => (
        <button className="flex items-center gap-8" key={s.option} onClick={s.onClick}>
          <NewIcon
            icon="check-outlined"
            size={24}
            className={cn(s.state ? 'fill-new-DTYellow-400' : 'fill-new-gray-400')}
          />
          <p className="text-new-Body2-medium">
            {s.option === 'optional' ? '(선택)' : ''}
            {s.text}
          </p>
        </button>
      ))}
    </div>
    <Spacer className="h-[24px]" />
    <Spacer className="h-[10px]" />
    <NewBoxButton
      fill
      label={'동의하고 쿠폰 받기'}
      onClick={async () => {
        if (isLoading) return;
        await Promise.all(marketingStates.map((state) => state.onSubmit?.()));
        onSubmit();
        close();
      }}
      disabled={marketingStates.some((state) => state.option === 'required' && !state.state)}
      asChild
    >
      {isLoading ? <Loading /> : <p>동의하고 쿠폰 받기</p>}
    </NewBoxButton>
    <Spacer className="h-[8px]" />
    <NewBottomSheetClose onClick={close}>
      <p onClick={onReject} className="text-new-Body2-medium text-new-gray-600 text-center">
        다음에
      </p>
    </NewBottomSheetClose>
  </NewBottomSheetContent>
);

export const MarketingAgreementBottomSheet = ({
  children,
  option = DEFAULT_OPTION,
  onSubmit,
  onReject,
  className,
  disabled = false,
}: {
  children: React.ReactNode;
  option?: MarketingAgreementOption;
  onSubmit: () => void;
  onReject?: () => void;
  className?: string;
  disabled?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const marketingStates = useMarketingStates(option);

  const canSkip = marketingStates.filter((state) => state.option === 'required').length === 0;
  const isLoading = marketingStates.some((state) => state.isLoading);

  if (canSkip || disabled)
    return (
      <button className={cn('w-full', className)} onClick={onSubmit}>
        {children}
      </button>
    );

  return (
    <NewBottomSheet open={isOpen} onOpenChange={setIsOpen} dismissible={false}>
      <NewBottomSheetTrigger
        className={cn('w-full', className)}
        onClick={async (e) => {
          if (isLoading) {
            e.preventDefault();
            return;
          }
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        {children}
      </NewBottomSheetTrigger>
      <MarketingAgreementUI
        marketingStates={marketingStates}
        isLoading={isLoading}
        onSubmit={() => {
          onSubmit();
          setIsOpen(false);
        }}
        close={() => setIsOpen(false)}
        onReject={onReject}
      />
    </NewBottomSheet>
  );
};

/**
 * useOverlay를 사용하면 외부에서 state 변경이 있어도 반영되지 않음
 * 컴포넌트 내부에서 상태를 가지고 있어야 함.
 */
const OverlayedMarketingAgreementUI = ({
  option,
  ...props
}: Omit<ComponentProps<typeof MarketingAgreementUI>, 'marketingStates' | 'isLoading'> & {
  option: MarketingAgreementOption;
}) => {
  const marketingStates = useMarketingStates(option);
  const isLoading = marketingStates.some((state) => state.isLoading);

  return (
    <MarketingAgreementUI {...props} marketingStates={marketingStates} isLoading={isLoading} />
  );
};
export const useOpenMarketingAgreementBottomSheet = (
  option: MarketingAgreementOption = DEFAULT_OPTION,
) => {
  const overlay = useOverlay();
  const marketingStates = useMarketingStates(option);
  const isLoading = marketingStates.some((state) => state.isLoading);

  return async () => {
    await new Promise<void>((resolve) => {
      if (marketingStates.length === 0 || isLoading) {
        resolve();
        return;
      }

      overlay.open(({ isOpen, close }) => (
        <NewBottomSheet open={isOpen} onOpenChange={close} dismissible={false}>
          <OverlayedMarketingAgreementUI
            option={option}
            onSubmit={() => {
              resolve();
              close();
            }}
            close={close}
          />
        </NewBottomSheet>
      ));
    });
  };
};
