import { CouponCode } from '../../CouponCode';

export class IssuedCoupon extends CouponCode {
  issuedDate: Date;
  isUsed: boolean;
  isRequested: boolean;

  constructor({
    code,
    source,
    name,
    discount,
    schoolName,
    issuedDate,
    expirationDate,
    isUsed,
    isRequested,
    id,
    ...props
  }: IssuedCouponData) {
    // server data
    super({
      id,
      code,
      source,
      discount,
      name,
      schoolName: schoolName ?? '',
      expirationDate,
      instanceConstructor: IssuedCoupon,
      className: 'IssuedCoupon',
      ...props,
    });
    // 발급할 때 결정되어야 하는 데이터
    this.issuedDate = issuedDate;
    this.isUsed = isUsed;
    this.isRequested = isRequested;
  }

  get isValid() {
    return !this.isExpired && !this.isUsed;
  }

  get isHighschoolStudent() {
    return this.source === '23FW-HIGH' || this.source === 'school';
  }

  public toCouponCode() {
    return new CouponCode(this.get() as CouponCodeData);
  }
}
