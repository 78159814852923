'use client';

/**
 * 라이브러리 선정 고민
 * 1. @smastrom/react-rating
 *   - 이슈를 읽어보면 half까지만 지원하고 중간값 (0.3) 등을 지원할 생각이 없음
 *   - 중간값 지원 X, 효과 (hover, transition 등) 지원 O
 * 2. Mantine Rating
 *   - Mantine 에 딸려있는 Rating 컴포넌트
 *   - 사용하려면 Provider를 설치해야하고 이래저래 사이즈가 커지는 감이 있음
 *   - 중간값 지원 O, 효과 (hover, transition 등) 지원 O
 *  3. react-rating
 *   - 메인테인이 2년전 (react 18부터는 타입도 깨짐)
 *   - 중간값 지원 O, 효과 (hover, transition 등) 지원 X
 *
 *  => 일단 3번으로 가보겠습니다.
 */
import { ComponentPropsWithoutRef, useState } from 'react';
import ReactRatingOrigin, { RatingComponentProps } from 'react-rating';

import { VariantProps, cva } from 'class-variance-authority';
import { cn } from 'tailwind-config';

import { NewIcon } from '../NewIcon';

/**
 * 라이브러리에서 타입 에러가 나서 다음과 같이 정의해야 함.
 * https://github.com/dreyescat/react-rating/issues/161
 */
const ReactRating = ReactRatingOrigin as unknown as (props: RatingComponentProps) => JSX.Element;

type RatingProps = ComponentPropsWithoutRef<typeof ReactRating> &
  VariantProps<typeof ratingVariants>;

// TODO: variant 이름 애매함
const ratingVariants = cva('flex items-center', {
  variants: {
    variant: {
      explore: 'text-new-Caption1-bold',
      academy: 'text-new-Body2-bold',
      reviewTab: 'text-new-Body1-bold',
      reviewList: 'text-new-Body1-bold h-20 leading-0 gap-4',
      reviewTitle: 'text-new-Sub-Title',
    },
  },
});

const getStarSize = (variant: VariantProps<typeof ratingVariants>['variant']) => {
  switch (variant) {
    case 'explore':
      return 16;
    case 'academy':
    case 'reviewList':
    case 'reviewTab':
      return 20;
    case 'reviewTitle':
      return 28;
    default:
      return 16;
  }
};

// TODO: 별점만 표시하면 되는 컴포넌트는 Rating인가 아닌가...
export function Rating({ initialRating, variant, className }: RatingProps) {
  const starSize = getStarSize(variant);
  return (
    <div className={cn(ratingVariants({ className, variant }))}>
      {variant === 'reviewList' ? (
        <ReactRating
          initialRating={initialRating}
          readonly
          emptySymbol={<NewIcon icon='outline-star-20' width={starSize} height={starSize} />}
          fullSymbol={<NewIcon icon='filled-star-20' width={starSize} height={starSize} />}
        />
      ) : null}
      {variant !== 'reviewList' ? (
        <NewIcon icon='filled-star-20' width={starSize} height={starSize} />
      ) : null}
      <div className='flex leading-none'>
        <div className='flex leading-none'>{initialRating?.toFixed(1)}</div>
      </div>
    </div>
  );
}

// TODO: 쓰기용 컴포넌트를 요렇게 분리하는 게 낫나 아님 Rating에서 prop으로 가르는 게 낫나...
export function RatingInput({ initialRating: valueAsProp, className, onChange }: RatingProps) {
  const [value, setValue] = useState<number>();
  const handleRatingChange = (e: number) => {
    setValue(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <ReactRating
      className={cn('flex', className)}
      initialRating={value ?? valueAsProp}
      onChange={handleRatingChange}
      emptySymbol={<NewIcon icon='outline-star-20' width={32} height={32} />}
      fullSymbol={<NewIcon icon='filled-star-20' width={32} height={32} />}
    />
  );
}
