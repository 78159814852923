import { getAcademyData, useGetUser } from '@db/collections';
import { useMutation, useQuery } from '@tanstack/react-query';
import { hc as honoClient } from 'hono/client';
import { useRouter } from 'next/router';

import { AppType } from '@pages/api/v2/[[...route]]';
import {
  RecommendEventLessonCodeUnion,
  RecommendEventLocation,
  RecommendEventPurchaseUnion,
  RecommendEventServiceUnion,
  UserRecommendData,
} from '@pages/api/v2/route/user/schema';

const api = honoClient<AppType>('/').api.v2;

export const useGetRecommendedAcademyId = (shoulFetch = true) => {
  const router = useRouter();
  const { lat = '', lng = '', preference = '' } = router.query;

  return useQuery({
    queryKey: ['event', 'recommend', lat, lng, preference],
    queryFn: () =>
      api.event['recommend-2024-ss']
        .$get({
          query: {
            lat,
            lng,
            preference,
          },
        })
        .then((res) => res.json())
        .then((data) => data.academy_id)
        .catch((error) => {
          console.error('Error occurred while fetching recommended academy id: ', error);
          throw error;
        }),
    enabled: !!lat && !!lng && !!preference && /* login갔다가 돌아올 경우 fetch 안함 */ shoulFetch,
  });
};

export const useGetAcademyForRecommendEvent = (id?: string, shouldFetch = true) => {
  return useQuery({
    queryKey: ['event', 'recommend', 'Academy', id ?? ''],
    queryFn: () => getAcademyData(id ?? ''),
    enabled: !!id && /* login갔다가 돌아올 경우 fetch 안함 */ shouldFetch,
  });
};

export const useUserPreferenceMutation = ({ userId }: { userId?: string }) => {
  return useMutation({
    mutationFn: async (recommendData: UserRecommendData) => {
      return api.user.preference[':userId'].$post({
        param: {
          userId: userId ?? '',
        },
        json: recommendData,
      });
    },
  });
};

export const useTempUserPreferenceMutation = () => {
  return useMutation({
    mutationFn: async (recommendData: UserRecommendData) => {
      const tempUserId = localStorage.getItem('temp-user-id') || undefined;
      return api.user.temp.preference.$post({
        json: {
          ...recommendData,
          tempUserId,
        },
      });
    },
  });
};

export const useSaveRecommendData = () => {
  const { user } = useGetUser();
  const userId = user?.uid;
  const { mutateAsync: saveUserRecommendData } = useUserPreferenceMutation({ userId });
  const { mutateAsync: saveTempUserRecommendData } = useTempUserPreferenceMutation();
  return async (recommendData: UserRecommendData) => {
    if (userId) {
      const res = await saveUserRecommendData(recommendData);
      return await res.json();
    }
    const res = await saveTempUserRecommendData(recommendData);
    const result = await res.json();
    if (result.tempUserId) {
      localStorage.setItem('temp-user-id', result.tempUserId);
    }
  };
};

export const useUserPreferenceMergeMutation = () => {
  return useMutation({
    mutationFn: async (userId: string) => {
      const tempUserId = localStorage.getItem('temp-user-id');

      if (!userId || !tempUserId) return;

      api.user.temp.preference.merge
        .$post({
          json: {
            userId,
            tempUserId,
          },
        })
        .then((result) => {
          localStorage.removeItem('temp-user-id');
          return result;
        });
    },
  });
};

export const useRecommendEventResultPageRouterParams = () => {
  const router = useRouter();
  if (router.pathname !== '/event/recommend/result') {
    throw new Error('/event/recommend/result 에서만 사용 가능한 hook입니다.');
  }
  const { lat, lng, lesson, addressText, purchase, service } = router.query as unknown as {
    lat: RecommendEventLocation['lat'];
    lng: RecommendEventLocation['lng'];
    addressText: RecommendEventLocation['addressText'];
    lesson: RecommendEventLessonCodeUnion;
    purchase: RecommendEventPurchaseUnion;
    service: RecommendEventServiceUnion;
  };
  return { lat, lng, lesson, addressText, purchase, service };
};
