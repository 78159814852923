import { BaseModel } from '@db/base-models';

export class PaymentStatus extends BaseModel {
  is차액정산: boolean;
  is지급대행: boolean;
  is차액정산requested: boolean;
  차액정산금: number;

  차액정산신청result?: any;
  할인차액정산신청result?: any;
  할인차액정산금: number;
  지급대행일: string;
  display지급대행일: string;
  지급대행금: number;
  display지급대행금: number;
  할인지급대행금: number;
  is할인지급대행: boolean;
  is할인차액정산: boolean;
  지급대행입금일: string;
  차액정산result?: any;
  할인차액정산result?: any;

  constructor(
    {
      is차액정산,
      is지급대행,
      is차액정산requested,
      차액정산금,
      차액정산신청result,
      할인차액정산신청result,
      할인차액정산금,
      지급대행일,
      display지급대행일,
      지급대행금,
      display지급대행금,
      할인지급대행금,
      is할인지급대행,
      is할인차액정산,
      지급대행입금일,
      차액정산result,
      할인차액정산result,
    }: PaymentStatusData = {
      is차액정산: true,
      is지급대행: true,
      is차액정산requested: true,
      차액정산금: 0,
      차액정산신청result: true,
      할인차액정산신청result: true,
      할인차액정산금: 0,
      지급대행일: '',
      display지급대행일: '',
      지급대행금: 0,
      display지급대행금: 0,
      할인지급대행금: 0,
      is할인지급대행: true,
      is할인차액정산: true,
      지급대행입금일: '',
      차액정산result: true,
      할인차액정산result: true,
    },
  ) {
    super('PaymentStatus');
    this.is차액정산 = is차액정산 ?? true;
    this.is지급대행 = is지급대행 ?? true;
    this.is차액정산requested = is차액정산requested ?? true;
    this.차액정산금 = 차액정산금 ?? 0;
    this.차액정산신청result = 차액정산신청result;
    this.할인차액정산신청result = 할인차액정산신청result;
    this.할인차액정산금 = 할인차액정산금 ?? 0;
    this.지급대행일 = 지급대행일 ?? '';
    this.display지급대행일 = display지급대행일 ?? '';
    this.지급대행금 = 지급대행금 ?? 0;
    this.display지급대행금 = display지급대행금 ?? 0;
    this.할인지급대행금 = 할인지급대행금 ?? 0;
    this.is할인지급대행 = is할인지급대행 ?? true;
    this.is할인차액정산 = is할인차액정산 ?? true;
    this.지급대행입금일 = 지급대행입금일 ?? '';
    this.차액정산result = 차액정산result;
    this.할인차액정산result = 할인차액정산result;
  }
}
