import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getTimetable } from '../calls';

export const useGetTimetable = (id?: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.TIMETABLE, id as string],
    queryFn: getTimetable,
    enabled: !!id,
  });

  return result;
};
