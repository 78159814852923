import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getAcademyGeoJsons } from '../calls';

export const useGetAcademyGeoJsons = (academyId?: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.ACADEMY_GEOJSONS, academyId as string],
    queryFn: getAcademyGeoJsons,
    enabled: !!academyId && academyId !== '',
  });

  return result;
};
