'use client';

import { type FC, type HTMLAttributes, useCallback, useEffect, useState } from 'react';

import { UseEmblaCarouselType } from 'embla-carousel-react';
import { cn } from 'tailwind-config';

import { useCarousel } from './Carousel';

type EmblaCarouselType = UseEmblaCarouselType[1];

export const CarouselNumbers: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  const { api: emblaApi } = useCarousel();
  const [maxIndex, setMaxIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    if (!emblaApi) return;
    setMaxIndex(emblaApi.scrollSnapList().length);
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    if (!emblaApi) return;
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  if (!emblaApi || maxIndex === 1) return null;

  return (
    <div
      className={cn(
        'text-new-Caption2-medium absolute bottom-16 right-16 z-20 rounded-[50px] bg-black px-6 py-1 opacity-50',
        className,
      )}
      {...props}
    >
      <span className="text-white">{currentIndex + 1}</span>
      <span className="text-new-white-50">/{maxIndex}</span>
    </div>
  );
};
