'use client';

import { ElementRef, forwardRef, useEffect } from 'react';

import * as RadixToast from '@radix-ui/react-toast';
import { motion } from 'framer-motion';
import { isAppApproaching } from 'shared-values';
import { cn } from 'tailwind-config';

import { NewIcon, NewIconType } from '../NewIcon';

export interface ToastProps {
  title: React.ReactNode;
  description?: string;
  icon?: NewIconType;
  yPosition?: number;
  type?: 'single' | 'multiple';
  duration?: number;
  component?: React.ReactNode;
  onClose?: () => void;
}

const ToastTitle = forwardRef<
  React.ElementRef<typeof RadixToast.Title>,
  React.ComponentPropsWithoutRef<typeof RadixToast.Title>
>(({ className, ...props }, ref) => (
  <RadixToast.Title
    ref={ref}
    className={cn('text-new-Body2-bold w-max whitespace-pre-wrap', className)}
    {...props}
  />
));

const ToastDescription = forwardRef<
  React.ElementRef<typeof RadixToast.Description>,
  React.ComponentPropsWithoutRef<typeof RadixToast.Description>
>(({ className, ...props }, ref) => (
  <RadixToast.Description
    ref={ref}
    className={cn('w-max whitespace-pre-wrap', className)}
    {...props}
  />
));

const DEFAULT_Y_POSITION = 88 + (isAppApproaching({ isIOSApproaching: true }) ? 22 : 0);

export const Toast = forwardRef<
  ElementRef<typeof RadixToast.Root>,
  {
    onClose: () => void;
  } & ToastProps
>(function Toast(
  { onClose, title, description, icon, yPosition, duration = 2500, component },
  forwardedRef,
) {
  /**
   * Radix Toast 의 Duration을 설정하면 Focus 될 때만 동작함. 근데 가끔 Unmount가 안되는 버그가 있음.
   * setTimeout 으로 따로 관리함.
   * https://github.com/radix-ui/primitives/issues/2233
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose?.();
    }, duration);

    return () => clearTimeout(timeout);
  }, [duration]);

  return (
    <RadixToast.Root
      ref={forwardedRef}
      asChild
      defaultChecked
      onOpenChange={onClose}
      // duration={duration}
    >
      <motion.li
        layout
        initial={{ y: 0, opacity: 0 }}
        animate={{ y: -(yPosition ?? DEFAULT_Y_POSITION), opacity: 1 }}
        exit={{
          opacity: 0,
          zIndex: -1,
        }}
        transition={{
          type: 'spring',
          mass: 1,
          damping: 30,
          stiffness: 200,
        }}
        style={{ WebkitTapHighlightColor: 'transparent', originX: '0px' }}
        className={cn(
          'mx-auto flex justify-center gap-6 rounded-[10px] bg-[#242523BF] px-20 py-12 text-white',
          component && 'w-full',
        )}
      >
        {component ?? (
          <>
            <div className="flex items-center justify-between">
              {icon && <NewIcon icon={icon} />}
              <ToastTitle>{title}</ToastTitle>
            </div>
            {description && <ToastDescription>{description}</ToastDescription>}
          </>
        )}
      </motion.li>
    </RadixToast.Root>
  );
});
