import { db } from 'firebase-config';
import { collection, endAt, getDocs, orderBy, query, startAt } from 'firebase/firestore';
import { distanceBetween, geohashQueryBounds } from 'geofire-common';
import { findMethodAndReplace } from 'shared-values';

import { Academy, TestAcademyList } from '../models';

// 내 주변으로부터 50km 위치들 다 불러오기
const radiusInM = 50 * 1000;

export const getAcademiesByDistance = async ({
  queryKey,
  queryClient,
}: QueryFn<[string, LocationType | null, LocationType | null]>) => {
  const [, targetLocation, myLocation] = queryKey;
  if (!targetLocation || !myLocation) return null;

  const { latitude, longitude } = targetLocation;
  const { latitude: myLatitude, longitude: myLongitude } = myLocation;

  const center: [number, number] = [latitude, longitude];
  const academyRef = collection(db, 'Academy');
  const bounds = geohashQueryBounds(center, radiusInM);
  const promises = [];

  for (const b of bounds) {
    const q = query(academyRef, orderBy('location.hash'), startAt(b[0]), endAt(b[1]));

    const querySnapshot = await getDocs(q);

    promises.push(querySnapshot);
  }

  const promiseResult = await Promise.all(promises);

  const matchingDocs: Academy[] = [];

  for (const snap of promiseResult) {
    for (const doc of snap.docs) {
      if (TestAcademyList.includes(doc.id)) continue;
      const academyData = { ...doc.data(), id: doc.id } as AcademyData;

      const {
        location: { latitude: lat, longitude: lng },
      } = academyData;

      const distanceInKm = distanceBetween([lat, lng], [latitude, longitude]);
      const distanceInKmFromMyLocation = distanceBetween([lat, lng], [myLatitude, myLongitude]);

      const distanceInM = distanceInKm * 1000;
      if (distanceInM <= radiusInM) {
        const academy = new Academy({ ...academyData, queryClient, queryKey });

        academy.setDisatnce(Number(distanceInKmFromMyLocation.toFixed(2)));

        findMethodAndReplace(academy, 'toDate');

        matchingDocs.push(academy);
      }
    }
  }

  return matchingDocs;
};
