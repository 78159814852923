import { BaseList } from './base-list';
import { StartAndEndTime } from './start-and-end-time';
import { TimeWithDate } from './time-with-date';

export class TimeList extends BaseList {
  items: (StartAndEndTime | TimeWithDate)[];

  constructor(items: (StartAndEndTime | TimeWithDate)[]) {
    super({ items, className: 'TimeList' });
    this.items = items;
  }

  get startTime() {
    return this.items.length > 0 ? (this.firstItem.start as string) : '';
  }

  get endTime() {
    return this.items.length > 0 ? (this.lastItem.end as string) : '';
  }

  get date() {
    if (!(this.firstItem instanceof TimeWithDate)) return null;

    return this.firstItem.date as Date;
  }

  public setTimeZone() {
    this.items = this.items.map((time) => {
      if (time instanceof TimeWithDate) {
        return new TimeWithDate({
          ...time,
          date: new Date(time.date.getTime() - time.date.getTimezoneOffset() * 60000),
        });
      }

      return time;
    });
    return this;
  }
}
