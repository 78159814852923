import { QueryModel } from '@db/query-models';

export class TaxInvoice extends QueryModel {
  교육비State: string;
  PG수수료State: string;
  customerCount: number;
  교육비: number;
  PG수수료: number;
  정산완료금액: number;
  교육비역발행result?: any;
  교육비역발행서명result?: any;
  PG수수료정발행result?: any;
  month: string;
  orders?: any;
  NTSSendKey?: string;

  constructor({
    교육비State,
    PG수수료State,
    customerCount,
    교육비,
    PG수수료,
    정산완료금액,
    교육비역발행result,
    교육비역발행서명result,
    PG수수료정발행result,
    month,
    orders,
    NTSSendKey,
    queryClient,
    queryKey,
  }: DataModel<TaxInvoiceData>) {
    super({ queryClient, queryKey, instanceConstructor: TaxInvoice, className: 'TaxInvoice' });
    this.교육비State = 교육비State;
    this.PG수수료State = PG수수료State;
    this.customerCount = customerCount;
    this.교육비 = 교육비;
    this.PG수수료 = PG수수료;
    this.정산완료금액 = 정산완료금액;
    this.교육비역발행result = 교육비역발행result;
    this.교육비역발행서명result = 교육비역발행서명result;
    this.PG수수료정발행result = PG수수료정발행result;
    this.month = month;
    this.orders = orders;
    this.NTSSendKey = NTSSendKey;
  }
}
