import { useQuery } from '@tanstack/react-query';

import { getExerciseCount } from '../calls';

export const useGetExerciseCount = ({
  koreanType,
  choicesNum,
  answerNum,
}: {
  koreanType: string;
  choicesNum: string;
  answerNum: string;
}) => {
  const result = useQuery({
    queryFn: getExerciseCount,
    queryKey: ['count', koreanType, choicesNum as string, answerNum as string],
  });

  return result;
};
