import { useEffect } from 'react';

import { useGetUser } from '@db/collections';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import airbridge from 'airbridge-web-sdk-loader';
import { useRouter } from 'next/router';

import { useGetLocation } from '@hooks';
import { isIOSApproaching } from '@utils/Common';

import { GoogleAnalytics } from './GoogleAnalytics';
import { LogEvent } from './LogEvent/LogEvent';

const AIRBRIDGE_WEBTOKEN =
  process.env.NEXT_PUBLIC_PLATFORM_ENV === 'production'
    ? process.env.NEXT_PUBLIC_AIRBRIDGE_APP_WEBTOKEN_LIVE
    : process.env.NEXT_PUBLIC_AIRBRIDGE_APP_WEBTOKEN_TEST;

const AIRBRIDGE_APP_NAME =
  process.env.NEXT_PUBLIC_PLATFORM_ENV === 'production'
    ? process.env.NEXT_PUBLIC_AIRBRIDGE_APP_NAME_LIVE
    : process.env.NEXT_PUBLIC_AIRBRIDGE_APP_NAME_TEST;

export const getPlatform = () => {
  if (typeof window === 'undefined') return 'NotSet';

  const userAgent = window.navigator.userAgent;

  return userAgent.includes('APP_WISHROOM_ANDROID')
    ? 'AndroidApp'
    : userAgent.includes('APP_WISHROOM_IOS')
    ? 'IOSApp'
    : window.innerWidth > 768
    ? 'PCWeb'
    : isIOSApproaching()
    ? 'IOSWeb'
    : 'AndroidWeb';
};

const useInitLogEvent = () => {
  const { user } = useGetUser();
  const { locationPermission, myLocation } = useGetLocation({ isSilent: true });
  const router = useRouter();

  useEffect(() => {
    if (!user) return;

    LogEvent.setData({ user });
  }, [user]);

  useEffect(() => {
    if (locationPermission !== 'granted' || !myLocation) return;

    LogEvent.setData({ location: myLocation });
  }, [myLocation]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const platform = getPlatform();

    LogEvent.유지.setUserProperty({
      platform,
      isApp: platform.includes('App'),
    });
  }, []);
};

const useInitAirbridge = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    airbridge.init({
      app: AIRBRIDGE_APP_NAME ?? '',
      webToken: AIRBRIDGE_WEBTOKEN ?? '',
      utmParsing: true,
    });
  }, []);
};

export const Analytics = () => {
  useInitLogEvent();
  useInitAirbridge();

  return (
    <>
      <GoogleAnalytics />
      <VercelAnalytics />
    </>
  );
};
