import { useCallback, useEffect, useState } from 'react';

export const useTestLocalStorage = () => {
  const [wrongProblems, setWrongProblems] = useState<{ index: number; count: number }[]>([]);
  const [bookmarkProblems, setBookmarkProblems] = useState<number[]>([]);

  useEffect(() => {
    const wrongProblems = localStorage.getItem('wrongProblems');
    if (wrongProblems) {
      setWrongProblems(JSON.parse(wrongProblems));
    }
  }, []);

  useEffect(() => {
    const bookmarkProblems = localStorage.getItem('bookmarkProblems');
    if (bookmarkProblems) {
      setBookmarkProblems(JSON.parse(bookmarkProblems));
    }
  }, []);

  const addWrongProblem = useCallback(
    (exercise: Exercise) => {
      const newWrongProblems = exercise.addWrongProblem(wrongProblems);
      setWrongProblems(newWrongProblems);
    },
    [wrongProblems],
  );

  const toggleBookmarkProblem = useCallback(
    (exercise: Exercise) => {
      const newBookmarkProblems = exercise.bookmark(bookmarkProblems);
      setBookmarkProblems(newBookmarkProblems);
    },
    [bookmarkProblems],
  );

  const isBookmarkProblem = useCallback(
    (exercise: Exercise) => {
      return exercise.isBookmarked(bookmarkProblems);
    },
    [bookmarkProblems],
  );

  return {
    wrongProblems,
    bookmarkProblems,
    addWrongProblem,
    toggleBookmarkProblem,
    isBookmarkProblem,
  };
};
