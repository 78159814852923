export const 영업담당자 = ['오석준', '임재현', '나상훈', '이재찬'];

export const DT_KAKAO_CHANNEL_ID = '_nxewixj';

export const randomNicknames = [
  '599',
  '612',
  'F355',
  'F430',
  'F40',
  '캘리포니아',
  '엔초',
  'FF',
  'F12베를리네타',
  '250GTO',
  '프리스타일',
  '브롱코',
  '스포츠트랙',
  '익스페디션',
  '레인져',
  'E시리즈',
  'F시리즈',
  'S시리즈',
  '썬더버드',
  'GT',
  '박스터',
  '마칸',
  '카이맨',
  '911',
  '카이엔',
  '파나메라',
  '카레라GT',
  '356',
  '댓츠',
  'CRX델솔',
  '프렐류드',
  '인테그라',
  'S2000',
  'S660',
  'NONE',
  'NBOX',
  '팬텀',
  '아벤타도르',
  '베를리네타',
  '뮬산',
  '고스트',
  'FF',
  '스페치알레',
  '레이스',
  '우라칸',
  '미니쿠퍼',
  '프리우스',
  'GLC',
  '아테온',
  '아스트라',
  '악트로스',
  '그랜드랜드',
  '허슬러',
  '머스탱',
  '익스프롤러',
  '코세어',
  '카마로',
  '서버번',
  '콜벳',
  '모델 S',
  '레인지로버',
  '플라잉스퍼',
  '벤테이가',
  '컬리넌',
  '765LT',
  '그리피스',
  '시론',
];
