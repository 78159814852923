import { useEffect, useState } from 'react';

/**
 * ssr에 따라 상태를 구분하고 싶을 때 사용
 * typeof window !== 'undefined'와 같은 의미
 */
export const useMounted = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted;
};
