import axios from 'axios';
import { BACKEND_URL } from 'shared-values';

const convertBackendProvider = (provider: Provider) => {
  if (provider === 'kakao-rest') {
    return 'kakao';
  } else if (provider === 'kakao-native') {
    return 'kakao';
  } else if (provider === 'apple-native') {
    return 'apple';
  }
  return provider;
};

export const getFirebaseCustomToken = async (
  idToken: string,
  provider: Provider,
  userName?: string,
) => {
  const result = await axios.post(`${BACKEND_URL}/verifyToken`, {
    token: idToken,
    provider: convertBackendProvider(provider),
    userName,
  });

  const { email, nickname, firebase_token } = result.data;

  return {
    email,
    nickname,
    firebaseIdToken: firebase_token,
  };
};
