import { WEB_COLLECTION_MAP } from '@models';
import {
  DehydratedState,
  InfiniteData,
  QueryKey,
  QueryState,
  dehydrate,
} from '@tanstack/react-query';
import { COLLECTION_MAP, QueryListModel, QueryModel } from 'database';
import { produce } from 'immer';
import SuperJSON from 'superjson';

interface DehydratedQuery {
  queryHash: string;
  queryKey: QueryKey;
  state: QueryState;
}

type SingleModelKeys = keyof typeof COLLECTION_MAP;
type WebModelKeys = keyof typeof WEB_COLLECTION_MAP;

type ModelKeys = SingleModelKeys | WebModelKeys;

interface DataWithModel {
  _DT_model: ModelKeys;
}

interface DataWithModelList extends DataWithModel {
  items: any[];
}

function isInfiniteQuery<T extends object>(data: object): data is InfiniteData<{ data: T }> {
  if (!data) return false;
  if ('pageParams' in data && 'pages' in data) {
    return true;
  }
  return false;
}
const attatchModel = (model: QueryModel) => {
  return SuperJSON.stringify({
    ...model.get(),
    _DT_model: model.getInstanceName(),
  } as DataWithModel);
};

function convertToObject(data: any) {
  // 1. 단일 모델
  if (data instanceof QueryModel) {
    return attatchModel(data);
  }
  // 2. 리스트 모델
  else if (
    data instanceof QueryListModel &&
    data.get().length > 0 &&
    data.get()[0] instanceof QueryModel
  ) {
    return {
      items: data.get().map((item: QueryModel) => attatchModel(item)),
      _DT_model: data.getInstanceName(),
    } as DataWithModelList;
  }
  // 3. 배열
  else if (Array.isArray(data) && data.length > 0 && data[0] instanceof QueryModel) {
    return data.map((item: QueryModel) => attatchModel(item));
  }
  // 4. 모델 아님
  else {
    return data;
  }
}

function convertModelToObject(data: any) {
  if (isInfiniteQuery(data)) {
    return {
      ...data,
      pages: data.pages.map((page) => ({
        ...page,
        data: convertToObject(page.data),
      })),
    };
  } else {
    return convertToObject(data);
  }
}

function convertToModel({
  data,
  queryClient,
  queryKey,
}: {
  data: DataWithModel;
  queryClient: QueryClient;
  queryKey: QueryKey;
}) {
  // SuperJSON이 사용된 경우 SuperJSON.parse를 통해 객체로 변환
  if (typeof data === 'string') data = SuperJSON.parse(data);
  const model = data._DT_model;

  if (!model || typeof model !== 'string') return data;

  // db collection map match
  if (Object.keys(COLLECTION_MAP).includes(model)) {
    return new COLLECTION_MAP[model as SingleModelKeys]({
      ...data,
      queryKey,
      queryClient,
    } as any);
  }

  // web collection map match
  else if (Object.keys(WEB_COLLECTION_MAP).includes(model)) {
    return new WEB_COLLECTION_MAP[model as WebModelKeys]({
      ...data,
      queryKey,
      queryClient,
    } as any);
  }

  return data;
}

export const convertObjectToModel = (query: DehydratedQuery, queryClient: QueryClient) => {
  const data = query.state.data as DataWithModel;
  const queryKey = query.queryKey;

  if (Array.isArray(data) && data.length > 0) {
    return data.map((item: DataWithModel) => convertToModel({ data: item, queryClient, queryKey }));
  } else if (typeof data === 'object' && isInfiniteQuery<Array<DataWithModel>>(data)) {
    return produce(data, (draft) => {
      draft.pages = draft.pages.map((page) => ({
        ...page,
        data: page.data.map((item) =>
          convertToModel({ data: item, queryClient, queryKey }),
        ) as any[],
      }));
    });
  } else if (typeof data === 'string') {
    return convertToModel({ data, queryClient, queryKey });
  }

  return data;
};

export const deserialize = (data: DehydratedState, queryClient: QueryClient) => {
  let parsedJson = data;
  if (typeof data === 'string') parsedJson = JSON.parse(data);
  if (!parsedJson || !parsedJson.queries) return parsedJson;

  return produce(parsedJson, (draft) => {
    draft.queries.forEach((query) => {
      query.state.data = convertObjectToModel(query, queryClient);
    });
  });
};

export const serialize = (data: DehydratedState) => {
  return produce(data, (draft) => {
    draft.queries.forEach((query) => {
      query.state.data = convertModelToObject(query.state.data as any);
    });
  });
};

export const customDehydrate = (queryClient: QueryClient) =>
  serialize(dehydrate(queryClient, { shouldDehydrateQuery: () => true }));
