import { createContext, useContext } from 'react';

import EventEmitter from 'events';

export const EmitContext = createContext<EventEmitter>(new EventEmitter());

EmitContext.displayName = 'EmitContext';

export const useEmitContext = () => {
  const context = useContext(EmitContext);
  if (!context) {
    throw new Error('EmitContext must be used within a <EmitContext.Provider>');
  }

  return context;
};
