import { updateEmail, updateProfile } from 'firebase/auth';

import { signInByIdToken } from '@utils/Auth';

interface SignUpProps {
  email: string;
  name: string;
  firebaseIdToken: string;
}

export const signUp = async ({ email, name, firebaseIdToken }: SignUpProps) => {
  const userCredentialData = await signInByIdToken(firebaseIdToken);

  const { user } = userCredentialData;

  // firebase auth 업데이트
  await updateProfile(user, {
    displayName: name,
  });
  await updateEmail(user, email);

  return userCredentialData.user.uid;
};
