import { Spacer } from 'design-system';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from 'tailwind-config';

export const PC제휴문의 = ({ className }: { className?: string }) => {
  return (
    <div className={cn('w-[100px] max-[1024px]:hidden', className)}>
      <Image src="/pc-web-to-app/text-logo.png" width={100} height={27.5} alt="운전선생 로고" />
      <Spacer className="h-8" />
      <Link href={'https://info.drivingteacher.co.kr'} target="_blank">
        <span className="text-new-Body1-bold text-new-gray-500 whitespace-nowrap underline">
          입점 및 제휴 문의
        </span>
      </Link>
    </div>
  );
};
