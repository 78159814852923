import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getAcademiesByTerm } from '../calls';

export const useGetAcademiesByTerm = ({
  searchTerm,
  myLocation,
  isFromTeamEvent = false,
}: {
  searchTerm: string;
  myLocation?: LocationType | null;
  isFromTeamEvent?: boolean;
}) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.SEARCH_ACADEMIES, searchTerm, myLocation, isFromTeamEvent],
    queryFn: getAcademiesByTerm,
  });

  return result;
};
