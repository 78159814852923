import { pad } from './pad';

export const subtractHHMM = (HHMMtime: string, subtractMinute: number) => {
  const [HH, MM] = HHMMtime.split(':');

  if (Number(MM) >= subtractMinute) {
    return `${HH}:${pad(Number(MM) - subtractMinute)}`;
  } else {
    return `${pad(Number(HH) - 1)}:${pad(Number(MM) + 60 - subtractMinute)}`;
  }
};
