import { db } from 'firebase-config';
import { QueryDocumentSnapshot, doc, getDoc } from 'firebase/firestore';

/**
 * firebase에서 nextPageParams은 docuemntSnapshot 형태로 담김
 * serverside에서 해당 값을 client로 보내주려면 변환이 까다롭기 때문에
 * nextPageParam을 string 의 id 값으로 변환시켜주는 함수
 * prefetchInfiniteQuery를 사용하는 곳에서 사용
 */
export const convertNextPageParamsOnServerSide = (queryClient: QueryClient, queryKey: string[]) => {
  queryClient.setQueryData(queryKey, (data: any) => ({
    ...data,
    pages: [{ ...data.pages[0], nextPageParam: data.pages[0].nextPageParam?.id }],
    pageParams: [],
  }));
};

/**
 * infiniteQuery 함수 내부에서는 다음 함수를 사용해서 pageParam을 변환함
 */
export const getPrevPageParam = async <T>(
  collectionName: string,
  pageParam?: QueryDocumentSnapshot<T> | string,
) => {
  if (typeof pageParam === 'string')
    return (await getDoc(doc(db, collectionName, pageParam))) as QueryDocumentSnapshot<T>;

  return pageParam;
};
