import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getBlog } from '../calls';

export const useGetBlog = (id: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.BLOG, id as string],
    queryFn: getBlog,
  });

  return result;
};
