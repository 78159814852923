import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';

import { ProviderData } from '../use-process-login-callback';

const loadLoginState = async (id: string) => {
  const docSnap = await getDoc(doc(db, 'LoginState', id));

  if (!docSnap.exists()) return {} as LoginState;
  return docSnap.data() as LoginState;
};

export const getLoginState = async ({
  queryData,
  providerData,
}: {
  queryData: Record<string, string>;
  providerData: ProviderData;
}) => {
  if (providerData.provider === 'kakao-rest') {
    const { state } = providerData;
    return await loadLoginState(state);
  } else {
    return queryData as LoginState;
  }
};
