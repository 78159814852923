import { BaseModel } from './base-model';

export class StartAndEndTime extends BaseModel {
  start: string;
  end: string;

  constructor({ start, end }: StartAndEndTimeData) {
    super('StartAndEndTime');
    this.start = start;
    this.end = end;
  }

  get startHour() {
    return Number(this.start.split(':')[0]);
  }

  get startMinute() {
    return Number(this.start.split(':')[1]);
  }

  get endHour() {
    return Number(this.end.split(':')[0]);
  }

  get endMinute() {
    return Number(this.end.split(':')[1]);
  }
}
