import type { PickFromBottomSheetProps } from '../BottomSheet';

export const Header = ({ title, subTitle }: PickFromBottomSheetProps<'header'>) => {
  return (
    <>
      <div className="text-new-Sub-Title">
        {title.split('\n').map((txt, idx) => (
          <div key={idx} className="text-gray-900">
            {txt}
            <br />
          </div>
        ))}
      </div>
      {typeof subTitle === 'string' ? (
        <div className="text-new-Body2-medium mt-3">
          {subTitle.split('\n').map((txt, idx) => (
            <div key={idx} className="text-gray-400">
              {txt}
              <br />
            </div>
          ))}
        </div>
      ) : (
        subTitle
      )}
    </>
  );
};
