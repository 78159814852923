import { useEffect } from 'react';

import { useRouter } from 'next/router';
import { HOME_URI } from 'shared-values';

import { scrollContainerRef } from '@components/Layout';

interface UseScrollHistoryProps {
  setStorageName: string;
  excludePathnames?: string[];
}

export const useScrollHistory = ({ setStorageName, excludePathnames }: UseScrollHistoryProps) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const pathname = new URL(HOME_URI + url).pathname;
      if (excludePathnames && excludePathnames.includes(pathname)) {
        sessionStorage.removeItem(setStorageName);
        return;
      }

      sessionStorage.setItem(
        setStorageName,
        scrollContainerRef.current?.scrollTop.toString() || '0',
      );
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (setStorageName in sessionStorage) {
      /**
       * Layout.tsx에 있는 ScrollContainer와 타이밍 충돌하는 것이 있어
       * useScrollHistory가 항상 늦게 처리하도록 setTimeout을 사용
       */
      setTimeout(() => {
        scrollContainerRef.current?.scrollTo(0, Number(sessionStorage.getItem(setStorageName)));
        sessionStorage.removeItem(setStorageName);
      }, 0);
    }
  }, []);
};
