import { type ComponentProps, useEffect, useRef } from 'react';

import { Dialog, DialogButton, useOverlay } from 'design-system';
import { useRouter } from 'next/router';

export const usePreventBackGestureOnIOS = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && !('ontouchstart' in window)) return;

    function touchStart(e: TouchEvent) {
      if (e.touches.length === 0) return;
      const touch = e.touches[0];

      // https://w1660995238-jit339723.slack.com/archives/C04AA2XK43Y/p1716515028674759?thread_ts=1716481004.678819&cid=C04AA2XK43Y 같이 겹치는 경우가 있어 10px로 수정함. 너무 좁을 경우 추후 수정이 필요.
      if (touch.pageX > 10 && touch.pageX < window.innerWidth - 10) return;

      e.preventDefault();
    }

    // Safari defaults to passive: true for the touchstart event, so we need  to explicitly specify false
    // See https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
    const options = { passive: false };

    window.addEventListener('touchstart', touchStart, options);

    return () => window.removeEventListener('touchstart', touchStart);
  }, []);
};

export const usePreventBack = ({
  title,
  body,
  exitButtonText,
  stayButtonText,
}: {
  title: ComponentProps<typeof Dialog>['title'];
  body: ComponentProps<typeof Dialog>['body'];
  exitButtonText?: string;
  stayButtonText?: string;
}) => {
  const router = useRouter();

  usePreventBackGestureOnIOS();

  const popFlag = useRef<boolean>(true);
  const overlay = useOverlay({
    offOnRouteChange: false,
  });

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath && popFlag.current) {
        history.go(1);

        overlay.open(({ isOpen, close }) => (
          <>
            {isOpen && (
              <Dialog
                title={title}
                body={body}
                image={{ src: '/go-back.png', alt: '신청 종료' }}
                close={close}
                buttons={[
                  <DialogButton
                    buttonType="text"
                    text={stayButtonText ?? '돌아가기'}
                    color="gray-900"
                    key="돌아가기"
                    onClick={() => {
                      close();
                    }}
                  />,
                  <DialogButton
                    buttonType="text"
                    text={exitButtonText ?? '그만두기'}
                    color="DTRed-400"
                    key="그만두기"
                    onClick={() => {
                      popFlag.current = false;
                      history.back();
                      close();
                    }}
                  />,
                ]}
              />
            )}
          </>
        ));

        return false;
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [title, body, exitButtonText, stayButtonText, overlay, router]);

  return <></>;
};
