// 금액이 있을 때 가장 높은 금액의 쿠폰을 찾는 함수
export const getHighestCouponWithPrice = (coupons: CouponCode[], price: number) => {
  if (coupons.length === 0) throw new Error('쿠폰이 존재하지 않습니다.');

  const result = coupons.reduce((acc, coupon) => {
    const convertToPrice = coupon.discount.convertToPrice(price);
    if (convertToPrice > acc.discount.convertToPrice(price)) {
      return coupon;
    }
    return acc;
  }, coupons[0]);

  return result;
};
