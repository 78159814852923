import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { AcademyDetail } from '../models';

export const getAcademyDetailData = async (id: string) => {
  const docRef = doc(db, 'AcademyDetail', id);
  const docData = (await getDoc(docRef)).data();

  findMethodAndReplace(docData, 'toDate');

  return { ...docData, id: docRef.id } as AcademyDetailData;
};

export const getAcademyDetail = async ({ queryKey, queryClient }: QueryFn) => {
  const [, id] = queryKey as [string, string];

  const docData = await getAcademyDetailData(id);

  return new AcademyDetail({ ...docData, queryClient, queryKey });
};
